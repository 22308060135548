import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const PngGameLauncher = ({ gameUrl, eventTypes }) => {
  const iframeRef = useRef(null);

  console.log('getGameUrl',gameUrl)
  console.log('eventTypes',eventTypes)

  useEffect(() => {

    if (!gameUrl) return;

    const handleMessage = (event) => {
      // Ensure the message is from a trusted origin
      if (event.origin !== new URL(gameUrl).origin) {
        console.warn('Untrusted origin:', event.origin);
        return;
      }

      const data = event.data;

      // Process the received message
      const eventTypeHandler = eventTypes.find((e) => e.eventType === data.type);
      if (eventTypeHandler && typeof eventTypeHandler.call === 'function') {
        eventTypeHandler.call(data);
      } else {
        console.log('Unhandled message type:', data);
      }
    };

    window.addEventListener('message', handleMessage);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [eventTypes, gameUrl]);

  const sendMessageToIframe = (message) => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.postMessage(message, new URL(gameUrl).origin);
    }
  };

  const handleIframeLoad = () => {
    // Send initialization messages to the iframe based on provided eventTypes
    eventTypes.forEach(({ eventType }) => {
      sendMessageToIframe({ messageType: 'addEventListener', eventType });
    });
  };

  return (
    <iframe
      ref={iframeRef}
      src={gameUrl}
      style={{ height: '100%', width: '100%', border: 'none' }}
      onLoad={handleIframeLoad}
      scrolling="yes"
      noresize="noresize"
      title="Game Frame"
    />
  );
};

PngGameLauncher.propTypes = {
  gameUrl: PropTypes.string.isRequired,
  eventTypes: PropTypes.arrayOf(
    PropTypes.shape({
      eventType: PropTypes.string.isRequired,
      call: PropTypes.func.isRequired,
    })
  ).isRequired,
};

export default PngGameLauncher;