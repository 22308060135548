import React, { useEffect, useState } from "react";
import { Card, ListGroup } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
// import { Link, useLocation } from "react-router-dom";
import { TENANT_BUCKET } from '../../common/constants'
import { useMiddletier } from "../../common/middletier";
import { SOCIAL_MEDIA_DATA } from '../../common/constants';
import "./template_01.css";

const ContactUsDesktop = (props) => {
  const { contactHeader,contactHeaderIcon } = props
  const { t } = useTranslation();
  const [contactUs, setContactUs] = useState([])
  const { query } = useMiddletier();

  useEffect(() => {
    const method = 'getSocialMedia'

    query({
      method,
      params: [],
      attributes: []

    }).then(({ data }) => {

      // const getSocialMediaData = data[method].map((item) => {
      //   const _item = SOCIAL_MEDIA_DATA[`SIDEBAR_${item.code}`];
      //   return { ...item, value: JSON.parse(item.value), category: _item ? _item.category : null, icon: _item ? _item.icon : null };
      // }).filter((item) => item.category === 'contactUs' && (item.name === "Livechat" ? item.value?.license_id !== "" : item.value?.url !== "" || item.value?.account !== ""));

      const defaultLiveChat = data['getSocialMedia'].find(e => e.code === 'DEFAULT_LIVE_CHAT');
      const defaultLiveChatValue = defaultLiveChat ? JSON.parse(defaultLiveChat.value) : {};
      const defaultLiveChatType = defaultLiveChatValue?.data;
      const getSocialMediaData = data[method].map((item) => {
        const _item = SOCIAL_MEDIA_DATA[`SIDEBAR_${item.code}`];
        return { ...item, name: (item.code === "LIVECHAT" || item.code === "TAWK_TO") ? "Livechat" : (_item ? _item.name : null), value: JSON.parse(item.value), category: _item ? _item.category : null, icon: _item ? _item.icon : null };
      }).filter((item) => {
       
        if (item.category === 'contactUs' && (item.value?.url || item.value?.account)) {
          return true;
        }
        
        if (defaultLiveChatType === 'LIVECHAT' && item.code === 'LIVECHAT') {
          return item.value?.license_id !== "";
        } else if (defaultLiveChatType === 'TAWK_TO' && item.code === 'TAWK_TO') {
          return item.value?.propertyId !== "" && item.value?.widgetId !== "";
        }
      
        return false;
      });

      setContactUs(getSocialMediaData)
      // console.log(getSocialMediaData)

    }).catch((error) => {
      console.log('error: ', error)
    })

  }, [query]);

  const openChat = () => {
    const event = new Event('openChat');
    window.dispatchEvent(event);
  };

  return (<>{contactUs.length>0 && (
    <section id="sidebar_contact_us_01">
      <div className="d-none d-lg-block">
        <div className="contact_wrap_d">
          <Card className="contact_front_d">
            <Card.Body>
              <div className="contact_front_d_header">
                <img src={`${TENANT_BUCKET}/icon/contactUs_bar_icon.png`} alt="contact us icon" />
                {t("contact_us")}
              </div>
            </Card.Body>
          </Card>
          <Card className="contact_back_d">
            {contactHeader && (<div className={`color_primary font_h3 contact_back_d_header ${!contactHeaderIcon?'justify-content-center':''}`}>
             {contactHeaderIcon&&(<img src={`${TENANT_BUCKET}/icon/contactUs_header_icon.png`} alt="contact header icon" />)} 
              {t("contact_us")}
            </div>)}
            <ListGroup variant="flush">
              {contactUs.map(function (item, index) {
                return (
                  <ListGroup.Item className="contact_back_d_item" key={item.code}>
                    <a href={item.value?.url === "" ? "#" : item.value['url']} onClick={(e) => {
                      if (item.value?.url === "") {
                        e.preventDefault();
                      }
                    }} target="_blank" rel="noopener noreferrer">
                      <img src={item.icon} alt={item.name} />
                      <div className="contact_back_content">
                        <div>{item.name}</div>
                        <span>{item.value?.account}</span>
                        {item.value?.qr && (
                          <img src={item.value?.qr.url} alt={item.name} />
                        )}
                        {
                          item.name === 'Livechat' && (
                            <button className="color_button font_button livechatButton" onClick={openChat}>{t('chat_with_us')}</button>
                          )
                        }
                      </div>
                    </a>
                  </ListGroup.Item>
                )
              })}

              {/* <ListGroup.Item className="contact_back_d_item">
                <img src={`${TENANT_BUCKET}/icon/contactUs_whatsapp.png`} alt="whatsapp" />
                <div>
                  <div>Whatsapp</div>
                  <div>01234567</div>
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="contact_back_d_item">
                <img src={`${TENANT_BUCKET}/icon/contactUs_wechat.png`} alt="wechat" />
                <div>
                  <div>WeChat</div>
                  <div>01234567</div>
                </div>
              </ListGroup.Item>
              <ListGroup.Item className="contact_back_d_item">
                <img src={`${TENANT_BUCKET}/icon/contactUs_chatwithus.png`} alt="chat with us" />
                <div>
                  <div>Chat With Us</div>
                  <div>01234567</div>
                </div>
              </ListGroup.Item> */}
            </ListGroup>
          </Card>
        </div>
      </div>
    </section>
  )}</>

  );
};

export default ContactUsDesktop;