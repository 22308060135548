import React, { useState, useEffect } from "react";
import { Container, OverlayTrigger, Popover, FormCheck } from "react-bootstrap";
import { FaMobileAlt, FaAddressCard, FaEnvelope } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import { useTranslation } from 'react-i18next';
import { TENANT_BUCKET, USER_VERIFICATION_DATA, MY_PROFILE_MENU_DATA, MY_PROFILE_MENU_DATA_OLD, TENANT_NAME, CHECK_NEW_TENANT } from '../../common/constants'
import PageTitle from '../pageTitle/default.js';
import { useMiddletier } from "../../common/middletier";
import { useStoreDispatch, useStoreState, APP_STORE_ACTION } from '../../common/storeContext';
import QRCode from 'qrcode.react';
import ShareQRDialog from '../../components/ShareQRDialog';
import { BiArrowBack } from "react-icons/bi";

import "./template_01.css";




const MyProfile = (props) => {
    const appDispatch = useStoreDispatch()
    const appState = useStoreState()
    const navigate = useNavigate();
    const { mobileTitleToggle } = props;
    const { mutation, queries } = useMiddletier();
    const [memberGroupImage, setMemberGroupImage] = useState('');
    const { t } = useTranslation();
    const [showShareQR, setShowShareQR] = useState(false);
    // AUTO TRANSFER
    const onChangeAutoTransfer = () => {

        const currentAutoTransfer = !appState.user?.auto_restore
        // console.log('currentAutoTransfer: ', currentAutoTransfer)
        const method = 'changeAutoRestoreByMember'

        mutation([{
            method,
            params: [
                { code: 'autoRestore', graphqlType: 'Boolean', required: true, value: currentAutoTransfer },
            ],
            attributes: []

        }]).then(({ data }) => {
            appDispatch({ type: APP_STORE_ACTION.AUTO_TRANSFER, payload: currentAutoTransfer })

            if (currentAutoTransfer) {
                appDispatch({
                    type: APP_STORE_ACTION.SHOW_ALERT,
                    payload: { description: t('auto_transfer_on'), typeAlert: 'info', title: t('main_wallet_auto_transfer') }
                });
            } else {
                appDispatch({
                    type: APP_STORE_ACTION.SHOW_ALERT,
                    payload: { description: t('auto_transfer_off'), typeAlert: 'info', title: t('main_wallet_auto_transfer') }
                });
            }


        }).catch((error) => {
            console.log('error: ', error)
        })
    }

    // const formatAmount = (amount, decimalPoint) => {
    //     const factor = Math.pow(10, decimalPoint);
    //     const truncatedAmount = Math.floor(amount * factor) / factor;
    //     const formattedAmount = truncatedAmount.toLocaleString(appState.currencyFormat?.locale, {
    //         minimumFractionDigits: decimalPoint,
    //         maximumFractionDigits: decimalPoint,
    //     });
    //     return formattedAmount;
    // }
    const formatAmount = (amount, decimalPoint) => {
        let strAmount = Number(amount).toString();
        let dotIndex = strAmount.indexOf('.');
      
        if (dotIndex === -1) {
          strAmount += '.' + '0'.repeat(decimalPoint);
        } else {
          const actualDecimals = strAmount.length - dotIndex - 1;
          if (actualDecimals > decimalPoint) {
            strAmount = strAmount.substring(0, dotIndex + decimalPoint + 1);
          } else if (actualDecimals < decimalPoint) {
            strAmount += '0'.repeat(decimalPoint - actualDecimals);
          }
        }
      
        const formattedAmount = parseFloat(strAmount).toLocaleString(appState.currencyFormat?.locale, {
          minimumFractionDigits: decimalPoint,
          maximumFractionDigits: decimalPoint,
        });
      
        return formattedAmount;
      };


    useEffect(() => {
        // const method = 'member_bank_accounts'
        // console.log('appState.user.member_group', appState.user.member_group)

        queries([{
            method: 'member_group',
            params: [
                { code: 'id', graphqlType: 'String', required: true, value: appState.user?.member_group ?? '' },
            ],
            attributes: [['_logo', ['url']]]

        }]).then(({ data }) => {

            setMemberGroupImage(data['member_group']?._logo ? data['member_group']?._logo.url : `${TENANT_BUCKET}/icon/wallet_user.png`)

        }).catch((error) => {
            console.log('error: ', error)
            if (error?.networkError?.statusCode === 401) {
                appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
            } else {
                appDispatch({
                    type: APP_STORE_ACTION.SHOW_ALERT,
                    payload: { description: error.message.toString(), typeAlert: 'error' }
                });
            }
        })

    }, [appState.user, queries, navigate, appDispatch]);

    return (
        <>
            <section id="profileMobile_01">
                {
                    !showShareQR ? (
                        <div className="myProfile_body">
                            <PageTitle mobileToggle={mobileTitleToggle} title={"My Profile"} onlyBackButton={true} id={"myProfile_title"} />
                            <Container className="myProfile_container">
                                <section className="myProfile_info">
                                    <div className="myProfile_info_user">
                                        {memberGroupImage !== '' && (<img src={`${memberGroupImage}`} alt={'wallet user'} />)}
                                        <div className="myProfile_userInfo">
                                            <label>{appState.user?.username}</label>
                                           {CHECK_NEW_TENANT&&(<span onClick={() => setShowShareQR(true)}><QRCode value={`https://${appState?.domain}/`} renderAs="svg" height="20" width="20" /></span>)} </div>
                                    </div>
                                    {/* <div className="myProfile_info_reward">
                                <div className="color_primary myProfile_info_token">
                                    <img src={`${TENANT_BUCKET}/icon/token.png`} alt={'token'} />
                                    <label>2568 Tokens</label>
                                </div>
                                <div className="color_primary myProfile_info_point">
                                    <img src={`${TENANT_BUCKET}/icon/point.png`} alt={'point'} />
                                    <label>2568 Points</label>
                                </div>
                            </div> */}
                                </section>
                                {/* {appState.enableLoyalty&&(<div className="color_primary myProfile_mySetting_row">
                                    <label>{TENANT_NAME.toUpperCase()} {(t("point"))}</label>
                                    <div className="d-flex align-items-center">
                                        <span className="me-1">{formatAmount(appState.user?.reward_points ?? 0, appState.currencyFormat.decimal_point)}</span>
                                        {t('points')}
                                    </div>
                                </div>)} */}
                                <section className="myProfile_mySetting">
                                    <div className="subTitle myProfile_subTitle">{t('my_setting')}</div>
                                    <div className="color_primary myProfile_mySetting_row">
                                        <label>{t('user_verification')}</label>
                                        <div className="d-flex align-items-center">
                                            {USER_VERIFICATION_DATA.map(function (item, index) {
                                                const _item = appState?.user?.[item.id]
                                                return (
                                                    <div key={item.id}>
                                                        <OverlayTrigger
                                                            trigger="click"
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Popover>
                                                                    <Popover.Body>
                                                                        {_item ? (t(`${item.name}_verifed_content`)) : (t(`${item.name}_unverifed_content`))}
                                                                    </Popover.Body>
                                                                </Popover>
                                                            }
                                                        >
                                                            <div className={`verify_icon ${_item ? 'validated' : ''}`}>
                                                                {item.name === 'phone_number' ? <FaMobileAlt /> : (item.name === 'email' ? <FaEnvelope /> : (item.name === 'dob' ? <FaAddressCard /> : <></>))}
                                                            </div>
                                                        </OverlayTrigger>

                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="color_primary myProfile_mySetting_row">
                                        <label>{t('auto_transfer')}</label>
                                        <div>
                                            <FormCheck custom type="switch">
                                                <FormCheck.Input isInvalid checked={appState.user?.auto_restore} onClick={() => onChangeAutoTransfer()} />
                                            </FormCheck>
                                        </div>
                                    </div>
                                </section>
                                <section className="myProfile_myAccount">
                                    <div className="subTitle myProfile_subTitle">{t('my_account')}</div>
                                    {(appState?.enableFriend ? MY_PROFILE_MENU_DATA : MY_PROFILE_MENU_DATA_OLD).map(function (item, index) {
                                        return (
                                            <Link to={item?.url} className="color_primary myProfile_myAccount_row" key={`${item?.name}_${index}`}>
                                                <div className="d-flex align-items-center">
                                                    <img src={item?.icon} alt={item?.name} />
                                                    <label>{t(item?.name)}</label>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <AiOutlineRight />
                                                </div>
                                            </Link>
                                        )
                                    })}
                                    {/* <Link to="/wallet/user_info" className="color_primary myProfile_myAccount_row">
                                <div className="d-flex align-items-center">
                                    <img src={`${TENANT_BUCKET}/icon/myProfile_userInfo.png`} alt={'user info'} />
                                    <label>{t('user_info')}</label>
                                </div>
                                <div className="d-flex align-items-center">
                                    <AiOutlineRight />
                                </div>
                            </Link>
                            <Link to="/wallet/my_friend" className="color_primary myProfile_myAccount_row">
                                <div className="d-flex align-items-center">
                                    <img src={`${TENANT_BUCKET}/icon/myProfile_myFriend.png`} alt={'user info'} />
                                    <label>{t('my_friend')}</label>
                                </div>
                                <div className="d-flex align-items-center">
                                    <AiOutlineRight />
                                </div>
                            </Link>
                            <Link to="/wallet/change_password" className="color_primary myProfile_myAccount_row">
                                <div className="d-flex align-items-center">
                                    <img src={`${TENANT_BUCKET}/icon/myProfile_changePassword.png`} alt={'change password'} />
                                    <label>{t('change_password')}</label>
                                </div>
                                <div className="d-flex align-items-center">
                                    <AiOutlineRight />
                                </div>
                            </Link> */}
                                    {/* <Link to="/wallet/change_game_password" className="color_primary myProfile_myAccount_row">
                                <div className="d-flex align-items-center">
                                    <img src={`${TENANT_BUCKET}/icon/myProfile_changeGamePassword.png`} alt={'change game password'} />
                                    <label>{t('change_game_password')}</label>
                                </div>
                                <div className="d-flex align-items-center">
                                    <AiOutlineRight />
                                </div>
                            </Link> */}
                                    {/* <Link to="/wallet/banking_details" className="color_primary myProfile_myAccount_row">
                                <div className="d-flex align-items-center">
                                    <img src={`${TENANT_BUCKET}/icon/myProfile_bankingDetails.png`} alt={'banking details'} />
                                    <label>{t('banking_details')}</label>
                                </div>
                                <div className="d-flex align-items-center">
                                    <AiOutlineRight />
                                </div>
                            </Link> */}
                                </section>
                            </Container>
                        </div>
                    ) : (<div className="myQRCode_body">
                        <section className={`section pageTitle font_h2 color_primary mobile d-lg-none`} id="myQRCode_title">
                            <Container className={`title_container`}>
                                <Link onClick={() => setShowShareQR(false)}><BiArrowBack /></Link>
                                <div>  {t('my_QRcode')}
                                </div>
                            </Container>
                        </section>
                        <Container className={`myQRCode_container`}>
                            <ShareQRDialog show={showShareQR} onHide={() => setShowShareQR(false)} shareURL={`https://${appState?.domain}/`} avatarLogo={memberGroupImage} />
                        </Container>
                    </div>)
                }

            </section>
            {/* {showShareQR && (<ShareQRDialog show={showShareQR} onHide={() => setShowShareQR(false)} shareURL={`https://${appState?.domain}/`} avatarLogo={memberGroupImage} />)} */}
        </>

    );




};






export default MyProfile;
