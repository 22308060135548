import React, { useState, useEffect, useReducer, useRef } from "react";
import { Container, Nav } from "react-bootstrap";
import { AiOutlineRight, AiFillCopy } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useStoreState, useStoreDispatch, APP_STORE_ACTION } from '../../common/storeContext'
import { TENANT_BUCKET, GLOBAL_BUCKET, GAME_MENU_DATA, IMAGE_SIZE_DYNAMIC } from '../../common/constants'
import TransferBalanceDialog from '../../components/TransferBalanceDialog'
import PromoDetailsDialog from '../../components/PromoDetailsDialog'
import PngGameLauncher from '../../components/PngGameLauncher'
import Loading from '../../components/Loading';
import Alert from '../../components/Alert'
import ImageWithPlaceholder from '../../components/ImageWithPlaceholder'
import { useMiddletier } from "../../common/middletier";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from 'dayjs';

import "swiper/css";
import "swiper/css/pagination";
import "./template_01.css";

const DYNAMIC_ACTION = {
  SET_DEFAULT_DATA: 'SET_DEFAULT_DATA',
  SET_SHOW_DATA: 'SET_SHOW_DATA',
  SET_FILTER_TYPES: 'SET_FILTER_TYPES',
  SET_SELECTED_FILTER_TYPE: 'SET_SELECTED_FILTER_TYPE',
  SET_ALL_TYPES: 'SET_ALL_TYPES',
  UPDATE_COUNTDOWN: 'UPDATE_COUNTDOWN',
}

const initialDynamicData = {
  defaultData: [],
  showData: [],
  // filterTypes: [{ code: 'all', name: 'show_all' }],
  // selectedFilterType: { code: 'all', name: 'show_all' },
  allType: [],
  filterTypes: [{ code: 'ALL', name: 'show_all' }],
  selectedFilterType: { code: 'ALL', name: 'show_all' },
}

const dynamicReducer = (state, action) => {
  switch (action.type) {

    case DYNAMIC_ACTION.SET_DEFAULT_DATA: {
      const defaultData = action.payload
      return { ...state, defaultData };
    }

    case DYNAMIC_ACTION.SET_SHOW_DATA: {
      const showData = action.payload
      return { ...state, showData };
    }

    case DYNAMIC_ACTION.UPDATE_COUNTDOWN: {
      const updateCountdown = state.showData.map(promotion => ({
        ...promotion, remainingTime: action.payload(promotion)
      }));

      return { ...state, showData: updateCountdown };
    }


    case DYNAMIC_ACTION.SET_ALL_TYPES: {
      const allType = [...action.payload]
      return { ...state, allType };
    }

    case DYNAMIC_ACTION.SET_FILTER_TYPES: {
      const filterTypes = [...action.payload]
      return { ...state, filterTypes };
    }

    case DYNAMIC_ACTION.SET_SELECTED_FILTER_TYPE: {
      const selectedFilterType = state.filterTypes.find((filterTypes) => filterTypes.code === action.payload)
      return { ...state, selectedFilterType };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const Template_01 = (props) => {

  const appState = useStoreState()
  // const [dynamicData, setDynamicData] = useState();
  const [dynamicState, dynamicDispatch] = useReducer(dynamicReducer, initialDynamicData)
  const { query, queries, mutation } = useMiddletier();
  const appDispatch = useStoreDispatch()
  // const [remainingTime, setRemainingTime] = useState(null);
  const location = useLocation();
  const [promoDetailsDialogShow, setPromoDetailsDialogShow] = useState(false);
  const [transferDialogShow, setTransferDialogShow] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const navigate = useNavigate();
  const cardContainerProps = props.properties ? props.properties.find((itm) => itm.name === 'Card Container') : false
  const { desktopToggle, mobileToggle, label, id, dataType, titleToggle, titleIconToggle, dynamicQuery, data, filter, noPadding, filterTabToggle, cardPosition, noLink } = props;
  const isSafari = () => /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);

  const checkProps = cardContainerProps ? cardContainerProps : props;
  const { contentPosition, actionPosition, rowDesktop, rowMobile, numberOfRow, folderImage } = checkProps
  const imageSize = cardContainerProps.image ? cardContainerProps.image : props.imageSize
  let imageSizeDynamic = ''
  const { t, i18n } = useTranslation();
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })

  const [deviceType, setDeviceType] = useState('');
  const [isInAppBrowser, setIsInAppBrowser] = useState(false);
  const [isLoadingTransfer, setLoadingTransfer] = useState(false);

  const gameWindowRef = useRef(null);
  const [iframeShow, setIframeShow] = useState(false);

  const [pngGameSetup, setPngGameSetup] = useState({ gameUrl: "", eventTypes: [{}] });


  const onClickGame = (game) => {
    if (appState.loggedIn) {
      setLoading(true);
      // console.log('get Game Type:',game)
      // --------------------------------------------------------------
      let currentGame = {
        getCurrentGameType: '',
        getCurrentGameProvider: '',
        getCurrentGameCode: '',
        getCurrentGameUrl: '',
        getCurrentGameAfterGameLink: false,
      }

      if (dataType === 'game provider') {
        // console.log(game)
        // console.log('game_provider_game_types',game._game_provider_game_types)
        currentGame.getCurrentGameType = game._game_provider_game_types[0].type
        currentGame.getCurrentGameProvider = game.game_provider
        currentGame.getCurrentGameCode = game.code
        currentGame.getCurrentGameAfterGameLink = game.auto_transfer_after_game_link
      }
      else if (dataType === 'game') {
        currentGame.getCurrentGameType = game.type
        currentGame.getCurrentGameProvider = game.game_provider
        currentGame.getCurrentGameCode = game.code
      } else {
        currentGame.getCurrentGameType = game.sub_type
        currentGame.getCurrentGameProvider = game.game_provider
        currentGame.getCurrentGameCode = game.code
      }

      // --------------------------------------------------------
      let mytab

      if (!isInAppBrowser && currentGame.getCurrentGameProvider !== 'PLAYNGO') {
        // OPEN NEW TAB FOR GAME
        console.log(`open new tab ${currentGame.getCurrentGameProvider}`)

        mytab = window.open("/loading.html", "_blank");
      }


      // IF GAME PROVIDER AUTO TRANSFER AFTER GAME LINK TRUE (RESTORE FIRST THEN CALL GET GAME LINK)
      if ((dataType === 'game provider' && currentGame.getCurrentGameAfterGameLink)) {

        mutation([{
          method: 'restoreGameWalletByMember',
          params: [
            { code: 'member', graphqlType: 'String', required: true, value: appState.user?.username },
            { code: 'gameProvider', graphqlType: 'String', required: true, value: currentGame.getCurrentGameProvider },
            { code: 'checkAutoTransfer', graphqlType: 'Boolean', required: false, value: true },
          ],
          attributes: []

        }])
          .then(({ data }) => {
            if (!isInAppBrowser) {
              mytab.location = '/loading_transfer.html'
            } else {
              setLoading(false);
              setLoadingTransfer(true);
            }
            // mytab.location = '/loading_transfer.html'

            query({
              method: 'getGameLinkByMember',
              params: [
                { code: 'gameProvider', graphqlType: 'String', required: true, value: currentGame.getCurrentGameProvider },
                { code: 'device', graphqlType: 'String', required: false, value: deviceType === 'desktop' ? '0' : '1' },
                { code: 'gameType', graphqlType: 'String', required: true, value: currentGame.getCurrentGameType },
                { code: 'game', graphqlType: 'String', required: false, value: currentGame.getCurrentGameCode },
                { code: 'language', graphqlType: 'String', required: false, value: i18n.resolvedLanguage },
                { code: 'skipTransfer', graphqlType: 'Boolean', required: false, value: true },
              ],
              attributes: []

            }).then(({ data }) => {

              const gameBalance = data['getGameLinkByMember'].balance
              const gameUrl = data['getGameLinkByMember'].gameLink
              const gameWalletAmount = data['getGameLinkByMember'].wallet_amount

              currentGame.getCurrentGameUrl = gameUrl

              if (gameUrl === "") {
                setLoading(false);

                if (!isInAppBrowser) {
                  mytab.close()
                } else {
                  setLoadingTransfer(false);
                }
                // mytab.close()
                const getResponse = JSON.parse(data['getGameLinkByMember'].response)
                const getResponseErrorMsg = getResponse?.errMsg

                appDispatch({
                  type: APP_STORE_ACTION.SHOW_ALERT,
                  payload: { description: getResponseErrorMsg, typeAlert: 'error' }
                });

                return
              } else {

                if (!isInAppBrowser) {
                  mytab.location = gameUrl
                } else {
                  setLoadingTransfer(false);
                }
                // mytab.location = gameUrl

                if (gameBalance === 0 && data['restoreGameWalletByMember'] === 0) {
                  setLoading(false);
                  setLoadingTransfer(false);
                  setTransferDialogShow({ show: true, balance: gameBalance, gameSelect: { ...currentGame }, walletAmount: gameWalletAmount });
                } else {
                  if (isInAppBrowser) {
                    alert('hihi5')
                    // window.open(gameUrl);
                    // window.location = gameUrl;
                    window.location.replace(gameUrl);
                    // window.location.assign(gameUrl);
                    // let link = document.createElement('a');
                    // link.href = gameUrl;
                    // link.click();

                  }
                  queries([{
                    index: 'getAllGamePrivoderBalanceByMember',
                    method: 'getAllGamePrivoderBalanceByMember',
                    params: [],
                    attributes: []
                  },
                  {
                    index: 'member',
                    method: 'member',
                    params: [
                      { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
                    ],
                    attributes: ['wallet_amount']
                  },])
                    .then(({ data }) => {

                      setLoading(false);
                      setLoadingTransfer(false);
                      appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
                      appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data['member'].wallet_amount })


                    }).catch((error) => {
                      // console.log('error: ', error)
                      setLoading(false)
                      setLoadingTransfer(false);
                      if (error?.networkError?.statusCode === 401) {
                        appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                      } else {
                        appDispatch({
                          type: APP_STORE_ACTION.SHOW_ALERT,
                          payload: { description: error.message.toString(), typeAlert: 'error' }
                        });
                      }
                    })
                }




              }

            }).catch((error) => {
              // mytab.close()
              if (!isInAppBrowser) {
                mytab.close()
              }
              setLoading(false);
              // console.log('error: ', error)
              if (error?.networkError?.statusCode === 401) {
                appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
              } else {
                appDispatch({
                  type: APP_STORE_ACTION.SHOW_ALERT,
                  payload: { description: error.message.toString(), typeAlert: 'error' }
                });
              }
            })

          })
          .catch((error) => {
            // console.log('error: ', error)
            // mytab.close()
            if (!isInAppBrowser) {
              mytab.close()
            }
            setLoading(false)
            if (error?.networkError?.statusCode === 401) {
              appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
            } else {
              appDispatch({
                type: APP_STORE_ACTION.SHOW_ALERT,
                payload: { description: error.message.toString(), typeAlert: 'error' }
              });
            }
          })




      } else {

        let method = 'getGameLinkByMember';
        let params = [
          { code: 'gameProvider', graphqlType: 'String', required: true, value: currentGame.getCurrentGameProvider },
          { code: 'device', graphqlType: 'String', required: false, value: deviceType === 'desktop' ? '0' : '1' },
          { code: 'gameType', graphqlType: 'String', required: true, value: currentGame.getCurrentGameType },
          { code: 'game', graphqlType: 'String', required: false, value: currentGame.getCurrentGameCode },
          { code: 'language', graphqlType: 'String', required: false, value: i18n.resolvedLanguage },
          { code: 'skipTransfer', graphqlType: 'Boolean', required: false, value: true },
        ];

        query({
          method,
          params,
          attributes: []

        }).then(({ data }) => {
          // setLoading(false)
          // console.log('getGameLinkByMember', data[method])

          const gameBalance = data[method].balance
          const gameUrl = data[method].gameLink
          const gameWalletAmount = data[method].wallet_amount
          currentGame.getCurrentGameUrl = gameUrl

          if (gameUrl === "") {
            setLoading(false);
            mytab.close()
            const getResponse = JSON.parse(data[method].response)
            const getResponseErrorMsg = getResponse?.errMsg

            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: getResponseErrorMsg, typeAlert: 'error' }
            });

            return
          } else {

            if (!isInAppBrowser && currentGame.getCurrentGameProvider !== 'PLAYNGO') {
              mytab.location = '/loading_transfer.html'
            } else {
              setLoading(false);
              setLoadingTransfer(true);
            }

            mutation([{
              method: 'restoreGameWalletByMember',
              params: [
                { code: 'member', graphqlType: 'String', required: true, value: appState.user?.username },
                { code: 'gameProvider', graphqlType: 'String', required: true, value: currentGame.getCurrentGameProvider },
                { code: 'checkAutoTransfer', graphqlType: 'Boolean', required: false, value: true },
              ],
              attributes: []

            }])
              .then(({ data }) => {
                // console.log('data[restoreGameWalletByMember]', data['restoreGameWalletByMember'])

                if (!isInAppBrowser && currentGame.getCurrentGameProvider !== 'PLAYNGO') {
                  mytab.location = gameUrl
                } else {
                  setLoadingTransfer(false);

                }

                if (gameBalance === 0 && data['restoreGameWalletByMember'] === 0) {
                  setLoading(false);
                  setLoadingTransfer(false);
                  setTransferDialogShow({ show: true, balance: gameBalance, gameSelect: { ...currentGame }, walletAmount: gameWalletAmount });
                }

                else {
                  if (isInAppBrowser && currentGame.getCurrentGameProvider !== 'PLAYNGO') {
                    alert('hihi3')
                    // window.open(gameUrl);
                    // window.location = gameUrl;
                    window.location.replace(gameUrl);
                    // window.location.assign(gameUrl);
                    // let link = document.createElement('a');
                    // link.href = gameUrl;
                    // link.click();
                  }

                  if (currentGame.getCurrentGameProvider === 'PLAYNGO') {
                    console.log('click png')
                    setIframeShow(true)
                    window.history.pushState({ iframe: true }, "iframe");
                    setPngGameSetup({
                      gameUrl: gameUrl,
                      eventTypes: [
                        { eventType: "gameReady", call: () => console.log("Game is ready") },
                        { eventType: "reloadGame", call: () => window.location.reload() },
                        { eventType: "backToLobby", call: () => window.location.reload() },
                      ]
                    });
                  }

                  queries([{
                    index: 'getAllGamePrivoderBalanceByMember',
                    method: 'getAllGamePrivoderBalanceByMember',
                    params: [],
                    attributes: []
                  },
                  {
                    index: 'member',
                    method: 'member',
                    params: [
                      { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
                    ],
                    attributes: ['wallet_amount']
                  },])
                    .then(({ data }) => {

                      setLoading(false);
                      setLoadingTransfer(false);
                      appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
                      appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data['member'].wallet_amount })


                    }).catch((error) => {
                      // console.log('error: ', error)
                      setLoading(false)
                      setLoadingTransfer(false);
                      if (error?.networkError?.statusCode === 401) {
                        appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                      } else {
                        appDispatch({
                          type: APP_STORE_ACTION.SHOW_ALERT,
                          payload: { description: error.message.toString(), typeAlert: 'error' }
                        });
                      }
                    })

                }

              })
              .catch((error) => {
                // console.log('error: ', error)
                alert('restore error: ' + error)
                setLoading(false)
                if (error?.networkError?.statusCode === 401) {
                  appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                } else {
                  appDispatch({
                    type: APP_STORE_ACTION.SHOW_ALERT,
                    payload: { description: 'restore error:' + error.message.toString(), typeAlert: 'error' }
                  });
                  // alert('restore error: '+ error)
                }
              })
          }



        }).catch((error) => {
          // mytab.close()
          if (!isInAppBrowser && currentGame.getCurrentGameProvider !== 'PLAYNGO') {
            mytab.close()
          }

          alert('getGameLink By Member error: ' + error)

          setLoading(false);
          // console.log('error: ', error)
          if (error?.networkError?.statusCode === 401) {
            appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
          } else {
            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: 'getGameLink By Member error:' + error.message.toString(), typeAlert: 'error' }
            });
          }
        })
      }



    }
    else {
      appDispatch({
        type: APP_STORE_ACTION.SHOW_ALERT,
        payload: { description: t('error_login_continue'), typeAlert: 'error' }
      });
    }

  }

  const openGamePNG = () => {
    setPngGameSetup({
      // gameUrl: "https://agastage.playngonetwork.com/casino/ContainerLauncher?pid=1868&gid=5xmagic&channel=mobile&lang=en-US&practice=0&ticket=63-62F799Y428U693D&origin=http%3A%2F%2Flocalhost%3A3001",
      gameUrl: "https://gameplay7.com/",
      eventTypes: [
        { eventType: "gameReady", call: () => console.log("Game is ready") },
        { eventType: "reloadGame", call: () => window.location.reload() },
        { eventType: "backToLobby", call: () => window.location.replace("https://demo.imbaweb.com/slots#PLAYNGO") },
      ]
    });
  };


  // const openGameInNewTab = () => {
  //   const gameWindow = window.open(
  //     "https://asistage.playngonetwork.com/casino/ContainerLauncher?pid=1687&gid=alicecooper&channel=mobile&lang=en-US&practice=0&ticket=17-644Y667R82K488C&origin=https://sandbox.imbaweb.com/slots#PLAYNGO",
  //     "_blank"
  //   );
  //   if (gameWindow) {
  //     gameWindowRef.current = gameWindow;
  //     GameCommunicator.init(gameWindow);
  //     gameWindow.onload = () => {
  //       GameCommunicator.postMessage({ messageType: "addEventListener", eventType: "gameReady" });
  //       GameCommunicator.postMessage({ messageType: "addEventListener", eventType: "gameError" });
  //       GameCommunicator.postMessage({ messageType: "addEventListener", eventType: "running" });
  //       GameCommunicator.postMessage({ messageType: "addEventListener", eventType: "roundStarted" });
  //       GameCommunicator.postMessage({ messageType: "addEventListener", eventType: "roundEnded" });
  //       GameCommunicator.postMessage({ messageType: "addEventListener", eventType: "gameEnabled" });
  //       GameCommunicator.postMessage({ messageType: "addEventListener", eventType: "gameDisabled" });
  //       GameCommunicator.postMessage({ messageType: "addEventListener", eventType: "gameIdle" });
  //       GameCommunicator.postMessage({ messageType: "addEventListener", eventType: "logout" });
  //       GameCommunicator.postMessage({ messageType: "addEventListener", eventType: "balanceUpdate" });
  //       GameCommunicator.postMessage({ messageType: "addEventListener", eventType: "roundWin" });
  //       GameCommunicator.postMessage({ messageType: "addEventListener", eventType: "backToLobby" });
  //       GameCommunicator.postMessage({ messageType: "addEventListener", eventType: "reloadGame" });
  //       GameCommunicator.postMessage({ messageType: "addEventListener", eventType: "playForReal" });
  //     };
  //   } else {
  //     alert("Popup blocked. Please allow popups for this website.");
  //   }
  // };

  // const GameCommunicator = {
  //   source: undefined,
  //   origin: "https://comstage.playngonetwork.com/",

  //   init: function (window) {
  //     this.source = window;
  //     window.addEventListener("message", this.processGameMessage.bind(this));
  //   },

  //   postMessage: function (data) {
  //     console.log("GameCommunicator sent the following message:", data);
  //     this.source.postMessage(data, this.origin);
  //   },

  //   processGameMessage: function (e) {
  //     console.log("GameCommunicator received: ", e.data);
  //     switch (e.data.type) {
  //       case "reloadGame":
  //         window.location.reload();
  //         break;
  //       case "backToLobby":
  //         console.log("Back to lobby");
  //         window.location.replace("https://sandbox.imbaweb.com/slots#PLAYNGO");
  //         break;
  //       default:
  //         break;
  //     }
  //   },
  // };


  // useEffect(() => {
  //   return () => {
  //     if (gameWindowRef.current) {
  //       gameWindowRef.current.close();
  //     }
  //   };
  // }, []);

  const onChangeType = (newValue) => {
    dynamicDispatch({ type: DYNAMIC_ACTION.SET_SELECTED_FILTER_TYPE, payload: newValue })
  }

  const copyText = (newValue) => {
    navigator.clipboard.writeText(newValue);
    appDispatch({
      type: APP_STORE_ACTION.SHOW_ALERT,
      payload: { description: `Copied ${newValue ?? '-'}`, typeAlert: 'success' }
    });
  };


  const onChangePromoDetails = (item) => {
    setCurrentItem(item);
    setPromoDetailsDialogShow(true);
  };

  // GET DATA
  useEffect(() => {
    let filterValue, getDynamicQuery

    if (dataType === 'game provider') {
      // console.log('gameprovider',filter)
      // console.log('dynamicQuery',dynamicQuery)
      if (dynamicQuery !== '') {
        getDynamicQuery = appState.dataTypes?.gameProviders[dynamicQuery]?.where ?? false
      }

      filterValue = {
        where: { ...(filter !== '' ? { '$_game_provider_game_types.type$': `${filter}` } : {}), '$_game_provider_game_types.status$': `ACTIVE`, status: 'ACTIVE', ...(getDynamicQuery ? getDynamicQuery : {}) },
        order: [['index', 'ASC'], ['name', 'ASC']]
      };

      query({
        method: 'getGameProviders',
        params: [
          { code: 'filter', graphqlType: 'JSON', required: true, value: filterValue },
        ],
        attributes: [['code', 'game_provider'], ['_game_provider_game_types', ['type']], 'name', 'live_link', 'auto_transfer_after_game_link']

      }).then(({ data }) => {

        dynamicDispatch({ type: DYNAMIC_ACTION.SET_SHOW_DATA, payload: data['getGameProviders'] })

      }).catch((error) => {
        // console.log('error: ', error)
      })

    }
    else if (dataType === 'game') {

      if (dynamicQuery !== '') {
        getDynamicQuery = appState.dataTypes?.games[dynamicQuery].where
      }

      filterValue = { where: { type: `${filter}`, '$_game_provider.status$': `ACTIVE`, status: 'ACTIVE', ...(getDynamicQuery ? getDynamicQuery : {}) } };


      query({
        method: 'getGames',
        params: [
          { code: 'filter', graphqlType: 'JSON', required: true, value: filterValue },
        ],
        attributes: ['id', 'name', 'sub_type', 'type', 'code', 'game_provider', ['_game_provider', ['status']]]
      }).then(({ data }) => {

        //GAME_${code}
        dynamicDispatch({ type: DYNAMIC_ACTION.SET_SHOW_DATA, payload: data['getGames'] })

      }).catch((error) => {
        // console.log('error: ', error)
      })

    }
    else if (dataType === 'promotion') {

      queries([{
        index: 'getPromotions',
        method: 'getPromotions',
        params: [
          { code: 'language', graphqlType: 'String', required: true, value: i18n.resolvedLanguage },
          { code: 'appearInWebsite', graphqlType: 'Boolean', required: false, value: true },
          { code: 'haveImage', graphqlType: 'Boolean', required: false, value: true },
        ],
        attributes: []
      }, {
        index: 'getPromotionCategories',
        method: 'getPromotionCategories',
        params: [
          { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { status: 'ACTIVE' } } },
        ],
        attributes: ['code', 'name']
      }]).then(({ data }) => {

        const getPromotionsData = data['getPromotions']?.map((item) => {
          return { ...item, category: JSON.parse(item.category) };
        })

        // console.log('getPromotionsData: ', getPromotionsData)
        // const getPromoTypes = getPromotionsData.reduce((result, promo) => {
        //   promo.category.forEach((t) => {
        //     if (result.indexOf(t) === -1) {
        //       result.push(t);
        //     }
        //   });
        //   return result
        // }, ["ALL"])



        //  const getPromoTypes = ['ALL', ...data['getPromotionCategories'].map(item => item)];
        const getPromoTypes = [{ code: 'ALL', name: 'show_all' }, ...data['getPromotionCategories'].map(item => item)];

        // console.log('getPromoTypes', getPromoTypes)

        dynamicDispatch({ type: DYNAMIC_ACTION.SET_ALL_TYPES, payload: data['getPromotionCategories'] })
        dynamicDispatch({ type: DYNAMIC_ACTION.SET_FILTER_TYPES, payload: getPromoTypes })
        dynamicDispatch({ type: DYNAMIC_ACTION.SET_DEFAULT_DATA, payload: getPromotionsData })
        dynamicDispatch({ type: DYNAMIC_ACTION.SET_SHOW_DATA, payload: getPromotionsData })

        // console.log('promo', data['getPromotions'])
      }).catch((error) => {
        // console.log('error: ', error)

      })

    }
    else if (dataType === 'gameTypes') {
      query({
        method: 'getGameTypes',
        params: [
          { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { status: 'ACTIVE', game_provider: `GS` }, order: [['updated_at', 'DESC']] } }
        ],
        attributes: [['type', 'code']]

      }).then(({ data }) => {

        dynamicDispatch({ type: DYNAMIC_ACTION.SET_SHOW_DATA, payload: data['getGameTypes'] })

      }).catch((error) => {
        // console.log('error: ', error)
      })
    }
    else {
      dynamicDispatch({ type: DYNAMIC_ACTION.SET_SHOW_DATA, payload: data })
    }

  }, [query, queries, data, dataType, filter, i18n.resolvedLanguage, appState.dataTypes?.gameProviders, appState.dataTypes?.games, dynamicQuery])

  // FILTER DATA BY TYPE
  useEffect(() => {

    if (dynamicState.selectedFilterType?.code === 'ALL') {
      dynamicDispatch({ type: DYNAMIC_ACTION.SET_SHOW_DATA, payload: dynamicState.defaultData })
    }
    else {
      const getFilterData = dynamicState.defaultData.filter(item => {
        // const category = JSON.parse(item.category);
        return item.category?.some(category => category === dynamicState.selectedFilterType?.code);
      });

      dynamicDispatch({ type: DYNAMIC_ACTION.SET_SHOW_DATA, payload: getFilterData })
    }


  }, [dynamicState.selectedFilterType?.code, dynamicState.defaultData])

  // useEffect(() => {
  //   const updateCountdown = (promotion) => {
  //     const promotionEndTime = dayjs(`${promotion?.last_effective_date}T${promotion?.display_end_time}`, 'YYYY-MM-DDTHH:mm:ss');
  //     const now = dayjs();
  //     const timeRemaining = promotionEndTime.diff(now);

  //     console.log('promotion?.lastEffectiveDate', promotion?.lastEffectiveDate)
  //     console.log('promotion?.displayEndTime', promotion?.displayEndTime)

  //     console.log('promotionEndTime:', promotionEndTime);
  //     console.log('now:', now);
  //     console.log('timeRemaining:', timeRemaining);

  //     const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
  //     const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  //     const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  //     const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);


  //     return { days, hours, minutes, seconds };
  //   };

  //   const intervalId = setInterval(() => {
  //     dynamicDispatch({
  //       type: DYNAMIC_ACTION.UPDATE_COUNTDOWN,
  //       payload: updateCountdown
  //     });
  //   }, 1000);

  //   return () => clearInterval(intervalId);
  // }, []);


  // useEffect(() => {
  //   if (location.pathname === '/promotions') {
  //     window.scrollTo(0, 0)
  //   }

  // }, [location])



  useEffect(() => {
    const userAgent = navigator.userAgent;
    let getDevice = ''

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
      getDevice = 'mobile'
      setDeviceType("mobile");
    } else {
      getDevice = 'desktop'
      setDeviceType("desktop");
    }
    // console.log('getDevice:', getDevice)

    const isInAppBrowser = /FBAV|FBAN|Instagram|Twitter|Line/i.test(userAgent);

    if (isInAppBrowser) {
      setIsInAppBrowser(true)
    } else {
      setIsInAppBrowser(false)
    }

  }, [])

  const closeIframe = () => {
    setIframeShow(false);
    if (window.history.state?.iframe) {
      window.history.back();
    }
  };

  useEffect(() => {
    const handlePopState = (event) => {
      if (event.state?.iframe) {
        setIframeShow(false);
      }
    };

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);



  const RemainingTime = ({ lastEffectiveDate }) => {
    const [remainingTime, setRemainingTime] = useState(null);
    const year = dayjs(lastEffectiveDate).year()

    useEffect(() => {
      // const promotionEndTime = dayjs(`${lastEffectiveDate}T${displayEndTime}`);
      const promotionEndTime = dayjs(`${lastEffectiveDate}`);

      const intervalId = setInterval(() => {
        const now = dayjs();
        const timeRemaining = promotionEndTime.diff(now);

        const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

        setRemainingTime({ days, hours, minutes, seconds });
      }, 1000);

      return () => clearInterval(intervalId);
    }, [lastEffectiveDate]);



    return (
      <div className="w-50">
        {remainingTime && (
          <div className="promoDetails_timeShow promotionTime_part">
            <div className="promoDetails_timeShow_container">
              {year >= 2999 ? (<>{t('no_expire_date')}</>) : (<><div className="count_container day_count">
                <div className="count_box">
                  <p>{remainingTime?.days}</p>
                  <p>{t('days')}</p>
                </div>
              </div>
                <div className="count_container time_count">
                  <div className="count_box">
                    <p>{remainingTime?.hours}</p>
                    <p>{t('hour')}</p>
                  </div>
                  <span>:</span>
                  <div className="count_box">
                    <p>{remainingTime?.minutes}</p>
                    <p>{t('min')}</p>
                  </div>
                  <span>:</span>
                  <div className="count_box">
                    <p>{remainingTime?.seconds}</p>
                    <p>{t('sec')}</p>
                  </div>
                </div></>)}

            </div>
          </div>
        )}
      </div>
    );
  };

  const getImageSize = (device, size, content, items) => {

    const value = IMAGE_SIZE_DYNAMIC[size] &&
      IMAGE_SIZE_DYNAMIC[size][device] &&
      IMAGE_SIZE_DYNAMIC[size][device][content] &&
      IMAGE_SIZE_DYNAMIC[size][device][content][items];
    return value || null;
  }

  const determineLinkTo = (_item, item) => {

    if (dataType === 'gameTypes') {
      return _item.url;
    }
    if (dataType === 'game provider' && filter === 'SL') {
      return `slots#${item.game_provider}`;
    }

    if (dataType === 'games' || dataType === 'game') {
      return `#${item.game_provider}`;
    }

    if (dataType === 'promotion') {
      return `/promotions`;
    }


  };

  const handleLinkClick = (event, item) => {
    if (dataType === 'game' || dataType === 'games' || (dataType === 'game provider' && filter !== 'SL')) {
      onClickGame(item);
    }


    if (noLink) {
      event.preventDefault();
    }

  };

  if (dataType === 'game' || dataType === 'games') {
    imageSizeDynamic = getImageSize(isDesktopOrLaptop ? 'desktop' : 'mobile', imageSize, contentPosition || 'bottom', isDesktopOrLaptop ? rowDesktop : rowMobile);
  }

  const widthColumn = isDesktopOrLaptop ? (rowDesktop ? 100 / rowDesktop : 0) : (rowMobile ? 100 / rowMobile : 0)
  const showDataLength = !numberOfRow ? dynamicState.showData?.length : (numberOfRow !== -1 ? (isDesktopOrLaptop ? rowDesktop : rowMobile) * numberOfRow : dynamicState.showData?.length)

  if ((desktopToggle || mobileToggle) === true) {
    return (
      <>
        <section className={`section ${desktopToggle ? "" : "d-lg-none"} ${mobileToggle ? "" : "d-none d-lg-block"} dynamic_template_1 ${noPadding && 'pb-0'}`} id={`layout_01`}>
          <div id={`${id}`}>
            <div className={`${id}_body ${isDesktopOrLaptop ? '' : `${id}_body_m`} layout_body ${noPadding && 'pb-0'}`}>
              <Container>
                <div className={`${id}_wrap`}>
                  {/* SECTION HEADER */}
                  {titleToggle ?
                    <div className={`section_header`}>
                      <div className="font_h2 section_title">
                        {titleIconToggle && <img src={`${TENANT_BUCKET}/icon/${id}_title_icon.png`} alt="title icon" />}
                        {label && t(label)}</div>
                      <Link to={`../${dataType === 'game' ? 'slots' : (dataType === 'game provider' ? `${GAME_MENU_DATA[filter]?.url}` : (dataType === 'promotion' ? 'promotions' : ''))}`} className="section_details">
                        {t('view_more')} &nbsp;
                        <AiOutlineRight />
                      </Link>
                    </div>
                    : <></>}
                  {
                    filterTabToggle && (
                      <Nav variant="pills" className={`${id}_filterTab filterTab tab_pills_color font_tab_pills`} onSelect={(key) => { onChangeType(key) }}>
                        {dynamicState.filterTypes?.map(function (item, index) {
                          // let _item = dynamicState.allType.find(e => e.code === item);
                          // console.log('filter type: ', dynamicState.filterTypes)
                          return (
                            <Nav.Item key={item.code} >
                              <Nav.Link eventKey={item.code} className={`filterTab_selection ${item.code === dynamicState.selectedFilterType.code ? 'color_active active' : ''}`}>
                                {/* {_item ? _item.name : t('all')} */}
                                {item.code === 'ALL' ? t('show_all') : (dataType === 'promotion' ? t(`PROMOTION_CATEGORY_${item.code}`) : item.name)}
                                {/* {item.name} */}
                              </Nav.Link>
                            </Nav.Item>
                          )
                        })}
                      </Nav>
                    )
                  }

                  {/* --- SECTION BODY START --- */}
                  <div className={`row`} style={{ justifyContent: `${cardPosition ?? 'center'}` }}>
                    {Object.values(dynamicState.showData ?? "").slice(0, showDataLength).map((item, index) => {
                      let _item = GAME_MENU_DATA[item.code] ?? ''
                      // console.log('getLinkTo:',determineLinkTo(_item, item))
                      return (
                        <div style={{ width: widthColumn + '%' }} key={`${dataType}_${filter ?? item.id}_${item.id}_${item.name}_${index} `} className={`card_container`}>
                          <Link to={determineLinkTo(_item, item)} className={`card_wrap card_image_${imageSize} card_position_${contentPosition} card_action_${actionPosition} type_${dataType} card_${noLink ? 'noLink' : 'link'}`} onClick={(event) => handleLinkClick(event, item)}>
                            {/* <Link to={`${dataType === 'gameTypes' ? _item.url : ((dataType === 'game provider' && filter === 'SL') ? `slots#${item.game_provider}` : '#')}`}> */}
                            {/* --- IMAGE PART START --- */}
                            <figure className="card_image_wrap">
                              {(dataType === 'game' || dataType === 'games') ?
                                <ImageWithPlaceholder src={`${GLOBAL_BUCKET}/game/${item.game_provider}/${imageSize}/img_jpg/${item.code}_${imageSize}_w${imageSizeDynamic}.jpg`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} /> :
                                (dataType === 'game provider' ?
                                  <ImageWithPlaceholder src={`${TENANT_BUCKET}/game-provider/${folderImage !== '' ? `${folderImage}/` : `${imageSize ?? '1x1'}/`}${filter}/${item.game_provider}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} /> :
                                  (dataType === 'gameTypes' ?
                                    <ImageWithPlaceholder src={`${TENANT_BUCKET}/game-type/${imageSize}/${item.code}_${i18n.resolvedLanguage}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} /> :
                                    ((dataType === 'promotion' || dataType === 'promotions') ?
                                      <ImageWithPlaceholder src={`${isDesktopOrLaptop ? (item._desktop_image?.url) : (item._mobile_image?.url)}`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} /> :
                                      <ImageWithPlaceholder src={`${GLOBAL_BUCKET}/game/${item.game_provider}/${imageSize}/${item.code}.png`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/${imageSize}.png`} alt={item.title} />)
                                  ))
                              }
                              {/* IMAGE PART CAPTION */}
                              {contentPosition === 'insideImage' && (<figcaption> <div className="card_content_title">   {
                                (dataType === 'game' || dataType === 'games') ?
                                  <>{(t(`GAME_${item?.id}`))}</> :
                                  ((dataType === 'promotion' || dataType === 'promotions') ?
                                    <>{item.title}</> :
                                    <>{item.name}</>
                                  )

                              }</div></figcaption>)}

                              {/* --- ACTIVE HOVER (INSIDE IMAGE PART) START --- */}
                              {actionPosition === 'hover' && <div className="card_action_wrap">
                                {
                                  (dataType === 'game' || dataType === 'games' || dataType === 'game provider') ?
                                    <button className="color_button font_button button_action button_action1" >{item.action_1 ?? t('play_now')}</button> :
                                    ((dataType === 'promotion' || dataType === 'promotions') ?
                                      <>
                                        <button className="color_button font_button button_action button_action1" onClick={() => onChangePromoDetails(item)} >{item.action_1 ?? t('more_info')}</button>
                                        {!appState.loggedIn ?
                                          <button className="color_button font_button button_action button_action2">{item.action_2 ?? t('join_now')}</button> :
                                          <button className="color_button font_button button_action button_action2">{item.action_2 ?? t('apply_now')}</button>}
                                      </> :
                                      <></>
                                    )

                                }


                              </div>}
                              {/* --- ACTIVE HOVER (INSIDE IMAGE PART) END --- */}

                            </figure>
                            {/* --- IMAGE PART END --- */}


                            {(contentPosition !== 'none' && contentPosition !== 'insideImage') && <div className="color_primary content_container">
                              <article className="card_content_wrap">
                                {
                                  ((dataType === 'promotion' || dataType === 'promotions') && Array.isArray(item.category)) &&
                                  <div className="promotionTag_part">
                                    {item.category?.map(function (categoryItem, categoryIndex) {
                                      let _categoryItem = dynamicState.allType.find(e => e.code === categoryItem);
                                      return _categoryItem && (
                                        <label key={categoryIndex}>{_categoryItem.name}</label>
                                      )
                                    })}

                                    {/* <label>New Member</label>
                                  <label>Slot</label>
                                  <label>Live Casino</label> */}
                                  </div>
                                }
                                <div className="card_content_title">   {
                                  (dataType === 'game' || dataType === 'games') ?
                                    <>{(t(`GAME_${item?.id}`))}</> :
                                    ((dataType === 'promotion' || dataType === 'promotions') ?
                                      <>{item.title}</> :
                                      <>{item.name}</>
                                    )

                                }</div>
                                <div className="card_content_desc_container">
                                  {item.description && (
                                    <p className="card_content_desc">{item.description}</p>
                                  )}
                                  {
                                    ((dataType === 'promotion' || dataType === 'promotions') && isDesktopOrLaptop) && (
                                      <RemainingTime lastEffectiveDate={item.last_effective_datetime} />
                                    )
                                  }
                                </div>
                              </article>

                              {/* --- ACTIVE BOTTOM START --- */}
                              {actionPosition === 'bottom' && <div className="card_action_wrap">
                                <div className="button_action_part">
                                  {
                                    (dataType === 'game' || dataType === 'games') ?
                                      <button className="color_button font_button button_action button_action1">{item.action_1 ?? t('play_now')}</button> :
                                      ((dataType === 'promotion' || dataType === 'promotions') ?
                                        <>
                                          <button className="color_button font_button button_action button_action1" onClick={() => onChangePromoDetails(item)} >{item.action_1 ?? t('more_info')}</button>
                                          {!appState.loggedIn ?
                                            <Link to="/register" className="color_button font_button button_action button_action2">{item.action_2 ?? t('join_now')}</Link> :
                                            <Link to="/wallet/transfer" className="color_button font_button button_action button_action2">{item.action_2 ?? t('apply_now')}</Link>}
                                        </> :
                                        <></>
                                      )

                                  } </div>
                                {
                                  (dataType === 'promotion' || dataType === 'promotions') &&
                                  <div className="promotionCode_part">
                                    <label>Use Code</label>
                                    <div className="promotionCode_content"><p>{item?.code}</p> <AiFillCopy onClick={() => copyText(item?.code)} /></div>
                                  </div>
                                }


                              </div>}
                              {/* --- ACTIVE BOTTOM END --- */}
                            </div>}
                            {/* </Link> */}
                          </Link>

                        </div>
                      );
                    })}

                  </div>
                  {/* --- SECTION BODY END --- */}

                  {/* {imageSize === '1x1' ?
              <>
                {contentPosition === 'bottom' ?
                  <>
                    <div className="row">
                      {Object.values(DynamicData ?? "").slice(0, showDataLength).map((item, index) => {
                        console.log('game', item)
                        return (
                          <div style={{ width: widthColumn + '%' }} className="py-3" key={index}>
                            <Card>
                              <Card.Img variant="top" src={`https://images2.${window.DOMAIN_NAME}/demo-image/1x1.png`} className="img_rounded" />
                              <Card.Body className="color_primary">
                                <Card.Title>Card hihi</Card.Title>
                              </Card.Body>
                              {(dataType === 'game') &&
                                <div className="hover-box">
                                  <button className="color_button game_btnPlayNow">play now</button>
                                </div>}
                            </Card>
                          </div>
                        );
                      })}
                    </div>
                  </> :
                  (contentPosition === 'right' ?
                    <>
                      <div className="row">
                        {DynamicData.slice(0, showDataLength).map((item, index) => {
                          return (
                            <div style={{ width: widthColumn + '%' }} className="py-3" key={index}>
                              <Card className="d-flex flex-row me-md-2">
                                <Card.Img variant="top" src={`https://images2.${window.DOMAIN_NAME}/demo-image/1x1.png`} className="img_rounded" />
                                <Card.Body className="color_primary">
                                  <Card.Title>Card title</Card.Title>
                                  <Card.Text>
                                    This card has supporting text below as a natural lead-in to
                                    additional content.
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                            </div>
                          );
                        })}
                      </div>
                    </> :
                    <>
                      <div className="row">
                        {DynamicData.slice(0, showDataLength).map((item, index) => {
                          return (
                            <div style={{ width: widthColumn + '%' }} className="py-3" key={index}>
                              <Card>
                                <Card.Img variant="top" src={`https://images2.${window.DOMAIN_NAME}/demo-image/1x1.png`} className="img_rounded" />
                              </Card>
                            </div>
                          );
                        })}
                      </div>
                    </>)}

              </> :
              (imageSize === '1x2' ?
                <>
                  {contentPosition === 'bottom' ?
                    <>
                      <div className="row">

                        {Object.values(DynamicData ?? "").slice(0, showDataLength).map((item, index) => {
                          return (
                            <div style={{ width: widthColumn + '%' }} className="py-3" key={index}>
                              <Card>
                                <Card.Img variant="top" src={`https://images2.${window.DOMAIN_NAME}/demo-image/1x2.png`} className="img_rounded" />
                                <Card.Body className="color_primary">
                                  <Card.Title>{item.name}</Card.Title>
                                </Card.Body>
                                {(dataType === 'game') &&
                                  <div className="hover-box">
                                    <button className="color_button game_btnPlayNow">play now</button>
                                  </div>}
                              </Card>
                            </div>
                          );
                        })}
                      </div>
                    </> :
                    (contentPosition === 'right' ?
                      <>
                        <div className="row">
                          {DynamicData.slice(0, showDataLength).map((item, index) => {
                            return (
                              <div style={{ width: widthColumn + '%' }} className="py-3" key={index}>
                                <Card className="d-flex flex-row me-md-2">
                                  <Card.Img variant="top" src={`https://images2.${window.DOMAIN_NAME}/demo-image/1x2.png`} className="img_rounded" />
                                  <Card.Body className="color_primary">
                                    <Card.Title>Card title</Card.Title>
                                    <Card.Text>
                                      This card has supporting text below as a natural lead-in to
                                      additional content.
                                    </Card.Text>
                                  </Card.Body>
                                </Card>
                              </div>
                            );
                          })}
                        </div>
                      </> :
                      <>
                        <div className="row">
                          {DynamicData.slice(0, showDataLength).map((item, index) => {
                            return (
                              <div style={{ width: widthColumn + '%' }} className="py-3" key={index}>
                                <Card>
                                  <Card.Img variant="top" src={`https://images2.${window.DOMAIN_NAME}/demo-image/1x2.png`} className="img_rounded" />
                                </Card>
                              </div>
                            );
                          })}
                        </div>
                      </>)}

                </> :
                (imageSize === '1x3' ?
                  <>
                    {contentPosition === 'bottom' ?
                      <>
                        <div className="row">
                          {DynamicData.slice(0, showDataLength).map((item, index) => {
                            return (
                              <div style={{ width: widthColumn + '%' }} className="py-3" key={index}>
                                <Card>
                                  <Card.Img variant="top" src={`https://images2.${window.DOMAIN_NAME}/demo-image/1x3.jpg`} className="img_rounded" />
                                  <Card.Body className="color_primary">
                                    <div className="template_content_wrap">
                                      <Card.Title>Card Title</Card.Title>
                                      <Card.Text>
                                        Some quick example text to build on the card title and make up
                                        the bulk of the card's content.
                                      </Card.Text>
                                    </div>
                                    {dataType === 'promotion' &&
                                      <div className="promotion_btn">
                                        <button className="promotion_btnInfo">{t('more_info')}</button>
                                        <button className="promotion_btnJoin">{t('join_now')}</button>
                                      </div>}
                                  </Card.Body>
                                </Card>
                              </div>
                            );
                          })}
                        </div>
                      </> :
                      (contentPosition === 'right' ?
                        <>
                          <div className="row">
                            {DynamicData.slice(0, showDataLength).map((item, index) => {
                              return (
                                <div style={{ width: widthColumn + '%' }} className="py-3" key={index}>
                                  <Card className="d-flex flex-row me-md-2">
                                    <Card.Img variant="top" src={`https://images2.${window.DOMAIN_NAME}/demo-image/1x3.jpg`} className="img_rounded" />
                                    <Card.Body className="color_primary template_cardbody">
                                      <div className="template_content_wrap">
                                        <Card.Title>Card title</Card.Title>
                                        <Card.Text>
                                          This card has supporting text below as a natural lead-in to
                                          additional content.
                                        </Card.Text>
                                      </div>
                                      {dataType === 'promotion' &&
                                        <div className="promotion_btn">
                                          <button className="promotion_btnInfo">{t('more_info')}</button>
                                          <button className="promotion_btnJoin">{t('join_now')}</button>
                                        </div>}
                                    </Card.Body>
                                  </Card>
                                </div>
                              );
                            })}
                          </div>
                        </> :
                        <>
                          <div className="row">
                            {DynamicData.slice(0, showDataLength).map((item, index) => {
                              return (
                                <div style={{ width: widthColumn + '%' }} className="py-3" key={index}>
                                  <Card>
                                    <Card.Img variant="top" src={`https://images2.${window.DOMAIN_NAME}/demo-image/1x3.jpg`} className="img_rounded" />
                                  </Card>
                                </div>
                              );
                            })}
                          </div>
                        </>)}

                  </> :
                  (imageSize === '2x1' ?
                    <>
                      {contentPosition === 'bottom' ?
                        <>
                        </> :
                        (contentPosition === 'right' ?
                          <>
                          </> :
                          <>
                            <div className="row">
                              {DynamicData.slice(0, showDataLength).map((item, index) => {
                                item = appState.gameProviders[item] ?? ""
                                return (
                                  <div style={{ width: widthColumn + '%' }} className="py-3" key={index}>
                                    <Card>
                                      <Card.Img variant="top" src={`https://images2.${window.DOMAIN_NAME}/${dataType}/2x1/${filter}/${item.id}.png`} className="img_rounded" onError={({ currentTarget }) => {
                                        currentTarget.src = `https://images2.${window.DOMAIN_NAME}/demo-image/2x1.jpg`;
                                      }} />
                                    </Card>
                                    {dataType === 'game-provider' &&
                                      <div className="game_name">{item.name}</div>}
                                  </div>
                                );
                              })}
                            </div>
                          </>)}

                    </> :
                    <></>)))} */}
                </div>
              </Container>
            </div>
          </div>
        </section>
        {currentItem && (
          <PromoDetailsDialog
            show={promoDetailsDialogShow}
            onHide={() => setPromoDetailsDialogShow(false)}
            getdata={currentItem}
          />
        )}
        <TransferBalanceDialog show={transferDialogShow.show} onHide={() => setTransferDialogShow({ ...transferDialogShow, show: false })} balance={transferDialogShow.balance} gameSelect={transferDialogShow.gameSelect} walletAmount={transferDialogShow.walletAmount} />
        {isLoading && <Loading />}
        {isLoadingTransfer && <Loading message={'Transferring funds to the game ...'} />}
        {(iframeShow) && (<div className="iframeContainer">
          <div className="closeIframe" onClick={closeIframe}><button>X Close</button></div>
          <PngGameLauncher gameUrl={pngGameSetup?.gameUrl} eventTypes={pngGameSetup?.eventTypes} />
        </div>)}
      </>
    );
  }

}



export default Template_01;
