export const DOMAIN_NAME = "imbaweb.com"

export const FORM_MODE = {
  NEW: 'NEW',
  EDIT: 'EDIT',
  READ_ONLY: 'READ_ONLY'
}

export const DATA_TYPE = {
  'STRING': 'STRING',
  'UUID': 'UUID',
  'TEXT': 'TEXT',
  'BOOLEAN': 'BOOLEAN',
  'INTEGER': 'INTEGER',
  'SEQUENCE': 'SEQUENCE',
  'DECIMAL': 'DECIMAL',
  'MONEY': 'MONEY',
  'DATETIME': 'DATETIME',
  'DATE': 'DATE',
  'LIST_GROUP': 'LIST_GROUP',
  'MODEL': 'MODEL',
  'JSON': 'JSON',
  'COMPOSITE': 'COMPOSITE'
}

export const DATA_TYPE_TO_GRAPHQL = {
  'STRING': 'String',
  'UUID': 'String',
  'TEXT': 'String',
  'BOOLEAN': 'Boolean',
  'INTEGER': 'Int',
  'SEQUENCE': 'Int',
  'DECIMAL': 'Float',
  'MONEY': 'Float',
  'DATETIME': 'DateTime',
  'DATE': 'Date',
  'LIST_GROUP': 'String',
  'MODEL': 'String',
  'JSON': 'String',
  'COMPOSITE': 'String'
}

export const TENANT_NAME = window.TENANT_NAME

export const CHECK_NEW_TENANT = ['sandbox', 'lewin'].includes(TENANT_NAME)

// ---------- IMAGE BUCKET  ---------- 

export const GLOBAL_BUCKET = `https://storage.googleapis.com/images.imbaweb.com`

export const TENANT_BUCKET = `https://storage.googleapis.com/${TENANT_NAME}-images.imbaweb.com`

// ---------- DESIGNER MODE  ---------- 

export const DESIGNER_MODE = false

// ---------- LANGUAGE ---------- 

// export const LANGUAGE = {
//   en: { nativeName: 'English' },
//   bm: { nativeName: 'Malay' },
//   zh: { nativeName: '中文' }
// }
// ------------Page ----------------
export const PAGE_DATA = {

  "HOME": {
    name: "home",
    url: "/",
    value: 'HOME'
  },
  "LOGIN": {
    name: "login",
    url: "/login",
    value: 'LOGIN'
  },

  "REGISTER": {
    name: "register",
    url: "/register",
    value: 'REGISTER'
  },

  "FORGOT_PASSWORD": {
    name: "forgot_password",
    url: "/forgotPassword",
    value: 'FORGOT_PASSWORD'
  },

  "FORGOT_USERNAME": {
    name: "forgot_username",
    url: "/forgotUsername",
    value: 'FORGOT_USERNAME'
  },

  "MOBILE_SLOTS": {
    name: "mobile_slot",
    url: "/mobile-slots",
    value: 'MOBILE_SLOTS'
  },

  "SLOTS": {
    name: "slots",
    url: "/slots",
    value: 'SLOTS'
  },

  "LIVE_CASINO": {
    name: "live_casino",
    url: "/live-casino",
    value: 'LIVE_CASINO'
  },

  "ESPORT": {
    name: "e-sport",
    url: "/esport",
    value: 'ESPORT'
  },

  "SPORT": {
    name: "sport",
    url: "/sports",
    value: 'SPORTS'
  },

  "LOTTERY": {
    name: "lottery",
    url: "/lottery",
    value: 'LOTTERY'
  },

  "BOARDGAME": {
    name: "boardgame",
    url: "/board-game",
    value: 'BOARD_GAME'
  },

  "OTHERS": {
    name: "others",
    url: "/others",
    value: 'OTHERS'
  },

  "FISHING": {
    name: "fishing",
    url: "/fishing",
    value: 'FISHING'
  },

  "PROMOTION": {
    name: "promotion",
    url: "/promotions",
    value: 'PROMOTION'
  },

  "HELP": {
    name: "help",
    url: "/help",
    value: 'HELP'
  },

  "CONTACT_US": {
    name: "contact_us",
    url: "/contact-us",
    value: 'CONTACT_US'
  },

  "AFFILIATE": {
    name: "affiliate",
    url: "/affiliate",
    value: 'AFFILIATE'
  },

  "VIP": {
    name: "vip",
    url: "/vip",
    value: 'VIP'
  },
  "MALL": {
    name: "mall",
    url: "/mall",
    value: 'MALL'
  },
  "MY_PROFILE": {
    name: "my_profile",
    url: "/my-profile",
    value: 'MY_PROFILE'
  },
  "DEPOSIT": {
    name: "deposit",
    url: "/wallet/deposit",
    value: 'DEPOSIT'
  },
  "TRANSFER": {
    name: "transfer",
    url: "/wallet/transfer",
    value: 'TRANSFER'
  },
  "WITHDRAW": {
    name: "withdraw",
    url: "/wallet/withdraw",
    value: 'WITHDRAW'
  },
  "HISTORY": {
    name: "history",
    url: "/wallet/history",
    value: 'HISTORY'
  },
  "BANKING_DETAILS": {
    name: "banking_details",
    url: "/wallet/banking_details",
    value: 'BANKING_DETAILS'
  },
  "USER_INFO": {
    name: "user_info",
    url: "/wallet/user_info",
    value: 'USER_INFO'
  },
  "MY_FRIEND": {
    name: "my_friend",
    url: "/wallet/my_friend",
    value: 'MY_FRIEND'
  },
  "NOTIFICATION": {
    name: "notification",
    url: "/wallet/notification",
    value: 'NOTIFICATION'

  },
  "CHANGE_PASSWORD": {
    name: "change_password",
    url: "/wallet/change_password",
    value: 'CHANGE_PASSWORD'

  },
  "CHANGE_FRIEND_TRANSFER_PASSWORD": {
    name: "change_friend_transfer_password",
    url: "/wallet/change_friend_transfer_password",
    value: 'CHANGE_FRIEND_TRANSFER_PASSWORD'
  }
}
// ---------- HEADER MENU ---------- 

export const HEADER_MENU_DATA = {

    "HOME": {
      name: "home",
      icon: `${TENANT_BUCKET}/icon/header_menu_home.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_home_active.png`,
      icon_m: `${TENANT_BUCKET}/icon/sideBar_menu_home.png`,
      selectedIcon_m: `${TENANT_BUCKET}/icon/sideBar_menu_home_active.png`,
      mobileShow: true,
      url: "/",
      value: 'HOME'
    },
    "LOGIN": {
      name: "login",
      icon: `${TENANT_BUCKET}/icon/header_menu_login.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_login_active.png`,
      icon_m: `${TENANT_BUCKET}/icon/sideBar_menu_login.png`,
      selectedIcon_m: `${TENANT_BUCKET}/icon/sideBar_menu_login_active.png`,
      mobileShow: true,
      url: "/login",
      value: 'LOGIN'
    },

    "REGISTER": {
      name: "register",
      icon: `${TENANT_BUCKET}/icon/header_menu_register.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_register_active.png`,
      icon_m: `${TENANT_BUCKET}/icon/sideBar_menu_register.png`,
      selectedIcon_m: `${TENANT_BUCKET}/icon/sideBar_menu_register_active.png`,
      mobileShow: true,
      url: "/register",
      value: 'REGISTER'
    },

    "FORGOT_PASSWORD": {
      name: "forgot_password",
      icon: `${TENANT_BUCKET}/icon/header_menu_forgot_password.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_forgot_password.png`,
      icon_m: `${TENANT_BUCKET}/icon/sideBar_menu_forgot_password.png`,
      selectedIcon_m: `${TENANT_BUCKET}/icon/sideBar_menu_forgot_password_active.png`,
      mobileShow: true,
      url: "/forgotPassword",
      value: 'FORGOT_PASSWORD'
    },

    "FORGOT_USERNAME": {
      name: "forgot_username",
      icon: `${TENANT_BUCKET}/icon/header_menu_forgot_username.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_forgot_username_active.png`,
      icon_m: `${TENANT_BUCKET}/icon/sideBar_menu_forgot_username.png`,
      selectedIcon_m: `${TENANT_BUCKET}/icon/sideBar_menu_forgot_username_active.png`,
      mobileShow: true,
      url: "/forgotUsername",
      value: 'FORGOT_USERNAME'
    },

    "MOBILE_SLOTS": {
      name: "mobile_slot",
      icon: `${TENANT_BUCKET}/icon/header_menu_casino.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_casino_active.png`,
      gameType: "APP",
      mobileShow: false,
      url: "/mobile-slots",
      value: 'MOBILE_SLOTS'
    },

    "SLOTS": {
      name: "slots",
      icon: `${TENANT_BUCKET}/icon/header_menu_slot.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_slot_active.png`,
      gameType: "SL",
      mobileShow: false,
      url: "/slots",
      value: 'SLOTS'
    },

    "LIVE_CASINO": {
      name: "live_casino",
      icon: `${TENANT_BUCKET}/icon/header_menu_casino.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_casino_active.png`,
      gameType: "LC",
      mobileShow: false,
      url: "/live-casino",
      value: 'LIVE_CASINO'
    },

    "ESPORT": {
      name: "e-sport",
      icon: `${TENANT_BUCKET}/icon/header_menu_esport.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_esport_active.png`,
      gameType: "ES",
      mobileShow: false,
      url: "/esport",
      value: 'ESPORT'
    },

    "SPORT": {
      name: "sport",
      icon: `${TENANT_BUCKET}/icon/header_menu_sport.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_sport_active.png`,
      gameType: "SB",
      mobileShow: false,
      url: "/sports",
      value: 'SPORTS'
    },

    "LOTTERY": {
      name: "lottery",
      icon: `${TENANT_BUCKET}/icon/header_menu_lottery.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_lottery_active.png`,
      gameType: "LK",
      mobileShow: false,
      url: "/lottery",
      value: 'LOTTERY'
    },

    "BOARDGAME": {
      name: "boardgame",
      icon: `${TENANT_BUCKET}/icon/header_menu_boardgame.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_boardgame_active.png`,
      gameType: "CB",
      mobileShow: false,
      url: "/board-game",
      value: 'BOARD_GAME'
    },

    "OTHERS": {
      name: "others",
      icon: `${TENANT_BUCKET}/icon/header_menu_others.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_others_active.png`,
      gameType: "OT",
      mobileShow: false,
      url: "/others",
      value: 'OTHERS'
    },

    "FISHING": {
      name: "fishing",
      icon: `${TENANT_BUCKET}/icon/header_menu_fishing.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_fishing_active.png`,
      gameType: "FH",
      mobileShow: false,
      url: "/fishing",
      value: 'FISHING'
    },

    "PROMOTION": {
      name: "promotion",
      icon: `${TENANT_BUCKET}/icon/header_menu_promotion.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_promotion_active.png`,
      icon_m: `${TENANT_BUCKET}/icon/sideBar_menu_promotion.png`,
      selectedIcon_m: `${TENANT_BUCKET}/icon/sideBar_menu_promotion_active.png`,
      mobileShow: true,
      url: "/promotions",
      value: 'PROMOTION'
    },

    "HELP": {
      name: "help",
      icon: `${TENANT_BUCKET}/icon/header_menu_help.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_help_active.png`,
      icon_m: `${TENANT_BUCKET}/icon/sideBar_menu_help.png`,
      selectedIcon_m: `${TENANT_BUCKET}/icon/sideBar_menu_help_active.png`,
      mobileShow: true,
      url: "/help",
      value: 'HELP'
    },

    "CONTACT_US": {
      name: "contact_us",
      icon: `${TENANT_BUCKET}/icon/header_menu_contactUs.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_contactUs_active.png`,
      icon_m: `${TENANT_BUCKET}/icon/sideBar_menu_contactUs.png`,
      selectedIcon_m: `${TENANT_BUCKET}/icon/sideBar_menu_contactUs_active.png`,
      mobileShow: true,
      url: "/contact-us",
      value: 'CONTACT_US'
    },

    "AFFILIATE": {
      name: "affiliate",
      icon: `${TENANT_BUCKET}/icon/header_menu_affiliate.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_affiliate_active.png`,
      icon_m: `${TENANT_BUCKET}/icon/sideBar_menu_affiliate.png`,
      selectedIcon_m: `${TENANT_BUCKET}/icon/sideBar_menu_affiliate_active.png`,
      mobileShow: true,
      url: "/affiliate",
      value: 'AFFILIATE'
    },

    "VIP": {
      name: "vip",
      icon: `${TENANT_BUCKET}/icon/header_menu_vip.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_vip_active.png`,
      icon_m: `${TENANT_BUCKET}/icon/sideBar_menu_vip.png`,
      selectedIcon_m: `${TENANT_BUCKET}/icon/sideBar_menu_vip_active.png`,
      mobileShow: true,
      url: "/vip",
      value: 'VIP'
    },

    "MALL": {
      name: "mall",
      icon: `${TENANT_BUCKET}/icon/header_menu_mall.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_mall_active.png`,
      icon_m: `${TENANT_BUCKET}/icon/sideBar_menu_mall.png`,
      selectedIcon_m: `${TENANT_BUCKET}/icon/sideBar_menu_mall_active.png`,
      mobileShow: true,
      url: "/mall",
      value: 'MALL'
    },
    "HOT_GAMES": {
      name: "hotGames",
      icon: `${TENANT_BUCKET}/icon/header_menu_hotGames.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_hotGames_active.png`,
      mobileShow: false,
      url: "/",
      value: 'HOT_GAMES'
    },

    "DEPOSIT": {
      name: "deposit",
      icon: `${TENANT_BUCKET}/icon/header_menu_deposit.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_deposit_active.png`,
      mobileShow: false,
      url: "/wallet/deposit",
      value: 'DEPOSIT'
    },
    "TRANSFER": {
      name: "transfer",
      icon: `${TENANT_BUCKET}/icon/header_menu_transfer.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_transfer_active.png`,
      mobileShow: false,
      url: "/wallet/transfer",
      value: 'TRANSFER'
    },

    "WITHDRAW": {
      name: "withdraw",
      icon: `${TENANT_BUCKET}/icon/header_menu_withdraw.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_withdraw_active.png`,
      mobileShow: false,
      url: "/wallet/withdraw",
      value: 'WITHDRAW'
    },

    "BANKING_DETAILS": {
      name: "banking_details",
      icon: `${TENANT_BUCKET}/icon/header_menu_banking_details.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/header_menu_banking_details_active.png`,
      mobileShow: false,
      url: "/wallet/banking_details",
      value: 'BANKING_DETAILS'
    }
  }

// ---------- FOOTER MENU ---------- 

export const FOOTER_MENU_DATA = {

    "ABOUT_US": {
      name: "about_us",
      mobileShow: true,
      url: "/help#about-us",
    },

    "TNC": {
      name: "tnc",
      mobileShow: true,
      url: "/help#term-and-conditions",
    },

    "FAQ": {
      name: "faq",
      mobileShow: true,
      url: "/help#faq",
    },

    "HOW_TO_DEPOSIT": {
      name: "how_to_deposit",
      mobileShow: true,
      url: "/help#how-to-deposit",
    },

    "HOW_TO_TRANSFER": {
      name: "how_to_transfer",
      mobileShow: true,
      url: "/help#how-to-transfer",
    },

    "HOW_TO_WITHDRAW": {
      name: "how_to_withdrawal",
      mobileShow: true,
      url: "/help#how-to-withdraw",
    },

    "CONTACT_US": {
      name: "contact_us",
      mobileShow: true,
      url: "/contact-us",
    },

    "VIP": {
      name: "vip",
      mobileShow: true,
      url: "/vip",
    }
  }

// ---------- BOTTOM MENU ----------
export const BOTTOM_MENU_DATA = [

    {
      name: "home",
      icon: `${TENANT_BUCKET}/icon/bottom_menu_home.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/bottom_menu_home_active.png`,
      url: "/",
    },
    {
      name: "my_friend",
      icon: `${TENANT_BUCKET}/icon/bottom_menu_myFriend.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/bottom_menu_myFriend_active.png`,
      url: "/wallet/my_friend",
    },
    {
      name: "rebate_history",
      icon: `${TENANT_BUCKET}/icon/bottom_menu_rebateHistory.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/bottom_menu_rebateHistory_active.png`,
      mobileShow: true,
      url: "/wallet/history",
    },
    {
      name: "account",
      icon: `${TENANT_BUCKET}/icon/bottom_menu_account.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/bottom_menu_account_active.png`,
      mobileShow: true,
      url: "/my-profile",
    },
    {
      name: "live_chat",
      icon: `${TENANT_BUCKET}/icon/bottom_menu_liveChat.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/bottom_menu_liveChat_active.png`,
      mobileShow: true,
      url: "/",
    }


  ]

// ---------- GAME MENU ---------- 

export const GAME_MENU_DATA = {

    "SL": {
      gameType: 'SL',
      name: "slots",
      icon: `${TENANT_BUCKET}/icon/game_menu_slot.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/game_menu_slot_active.png`,
      url: "/slots",
    },

    "LC": {
      gameType: 'LC',
      name: "live_casino",
      icon: `${TENANT_BUCKET}/icon/game_menu_casino.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/game_menu_casino_active.png`,
      url: "/live-casino",
    },

    "APP": {
      gameType: 'APP',
      name: "mobile_slot",
      icon: `${TENANT_BUCKET}/icon/game_menu_mslot.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/game_menu_mslot_active.png`,
      url: "/mobile-slots",
    },

    "ES": {
      gameType: 'ES',
      name: "e-sport",
      icon: `${TENANT_BUCKET}/icon/game_menu_esport.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/game_menu_esport_active.png`,
      url: "/esport",
    },

    "SB": {
      gameType: 'SB',
      name: "sport",
      icon: `${TENANT_BUCKET}/icon/game_menu_sport.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/game_menu_sport_active.png`,
      url: "/sports",
    },

    "LK": {
      gameType: 'LK',
      name: "lottery",
      icon: `${TENANT_BUCKET}/icon/game_menu_lottery.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/game_menu_lottery_active.png`,
      url: "/lottery",
    },

    "FH": {
      gameType: 'FH',
      name: "fishing",
      icon: `${TENANT_BUCKET}/icon/game_menu_fishing.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/game_menu_fishing_active.png`,
      url: "/fishing",
    },

    "CB": {
      gameType: 'CB',
      name: "boardgame",
      icon: `${TENANT_BUCKET}/icon/game_menu_boardgame.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/game_menu_boardgame_active.png`,
      url: "/board-game",
    },

    "PK": {
      gameType: 'PK',
      name: "poker",
      icon: `${TENANT_BUCKET}/icon/game_menu_poker.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/game_menu_poker_active.png`,
      url: "/poker",
    }
    ,
    "MG": {
      gameType: 'MG',
      name: "mini_game",
      icon: `${TENANT_BUCKET}/icon/game_menu_mini_game.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/game_menu_mini_game_active.png`,
      url: "/mini-game",
    },
    "OT": {
      gameType: 'OT',
      name: "others",
      icon: `${TENANT_BUCKET}/icon/game_menu_others.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/game_menu_others_active.png`,
      url: "/others",
    },
    "INFO": {
      gameType: 'INFO',
      name: "info",
      icon: `${TENANT_BUCKET}/icon/game_menu_info.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/game_menu_info_active.png`,
      url: "/",
    },
    "HOT": {
      gameType: 'HOT',
      name: "hot_game",
      icon: `${TENANT_BUCKET}/icon/game_menu_hotgame.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/game_menu_hotgame_active.png`,
      url: "/",
    }


  }

// ---------- SLOT ---------- 

export const SLOT_GAME_TYPE = {

    "ALL": {
      gameType: 'ALL',
      name: "all",
      label: 'gameType_all'
    },

    "SL": {
      gameType: 'SL',
      name: "slots",
      label: 'gameType_slots'
    },

    "AR": {
      gameType: 'AR',
      name: "arcade",
      label: 'gameType_arcade'
    },

    "LC": {
      gameType: 'LC',
      name: "table",
      label: 'gameType_table'
    },

    "FH": {
      gameType: 'FH',
      name: "fishing",
      label: 'gameType_fishing'
    },

    "LK": {
      gameType: 'LK',
      name: "lottery",
      label: 'lottery'
    },

    "OT": {
      gameType: 'OT',
      name: "other",
      label: 'other'
    },

    "BG": {
      gameType: 'BG',
      name: "bingo",
      label: 'bingo'
    },

    "FG": {
      gameType: 'FG',
      name: "featured games",
      label: 'featured games'
    },

    "FK": {
      gameType: 'FK',
      name: "funky",
      label: 'funky'
    },

    "MP": {
      gameType: 'MP',
      name: "multiplayer",
      label: 'multiplayer'
    },

    "SC": {
      gameType: 'SC',
      name: "scratch cards",
      label: 'scratch cards'
    },

    "SP": {
      gameType: 'SP',
      name: "single player",
      label: 'single player'
    },

    "TB": {
      gameType: 'TB',
      name: "table",
      label: 'gameType_table'
    },
  }

// ---------- WALLET MENU ---------- 
export const WALLET_MENU_DATA_OLD =
  {
    funds: [{
      id: "0",
      name: "deposit",
      mobileName: 'deposit',
      icon: `${TENANT_BUCKET}/icon/bankingInfo_menu_deposit.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/bankingInfo_menu_deposit_active.png`,
      url: "/wallet/deposit",

    }, {
      id: "1",
      name: "transfer_promo",
      mobileName: 'transfer',
      icon: `${TENANT_BUCKET}/icon/bankingInfo_menu_transfer.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/bankingInfo_menu_transfer_active.png`,
      url: "/wallet/transfer",

    }, {
      id: "2",
      name: "withdraw",
      mobileName: 'withdraw',
      icon: `${TENANT_BUCKET}/icon/bankingInfo_menu_withdrawal.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/bankingInfo_menu_withdrawal_active.png`,
      url: "/wallet/withdraw",

    }, {
      id: "3",
      name: "history",
      mobileName: 'history',
      icon: `${TENANT_BUCKET}/icon/bankingInfo_menu_history.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/bankingInfo_menu_history_active.png`,
      url: "/wallet/history",

    }],
    my_account: [{
      id: "4",
      name: "user_info",
      mobileName: 'user_info',
      icon: `${TENANT_BUCKET}/icon/myAccount_menu_userInfo.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/myAccount_menu_userInfo_active.png`,
      url: "/wallet/user_info",

    }, {
      id: "5",
      name: "notification",
      mobileName: 'notification',
      icon: `${TENANT_BUCKET}/icon/myAccount_menu_notification.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/myAccount_menu_notification_active.png`,
      url: "/wallet/notification",

    }, {
      id: "6",
      name: "change_password",
      mobileName: 'change_password',
      icon: `${TENANT_BUCKET}/icon/myAccount_menu_changePassword.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/myAccount_menu_changePassword_active.png`,
      url: "/wallet/change_password",

    }, {
      id: "7",
      name: "banking_details",
      mobileName: 'banking_details',
      icon: `${TENANT_BUCKET}/icon/myAccount_menu_bankingDetails.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/myAccount_menu_bankingDetails_active.png`,
      url: "/wallet/banking_details",

    }]
  }
export const WALLET_MENU_DATA = 
    {
      funds: [{
        id: "0",
        name: "deposit",
        mobileName: 'deposit',
        icon: `${TENANT_BUCKET}/icon/bankingInfo_menu_deposit.png`,
        selectedIcon: `${TENANT_BUCKET}/icon/bankingInfo_menu_deposit_active.png`,
        url: "/wallet/deposit",

      }, {
        id: "1",
        name: "transfer_promo",
        mobileName: 'transfer',
        icon: `${TENANT_BUCKET}/icon/bankingInfo_menu_transfer.png`,
        selectedIcon: `${TENANT_BUCKET}/icon/bankingInfo_menu_transfer_active.png`,
        url: "/wallet/transfer",

      }, {
        id: "2",
        name: "withdraw",
        mobileName: 'withdraw',
        icon: `${TENANT_BUCKET}/icon/bankingInfo_menu_withdrawal.png`,
        selectedIcon: `${TENANT_BUCKET}/icon/bankingInfo_menu_withdrawal_active.png`,
        url: "/wallet/withdraw",

      }, {
        id: "3",
        name: "history",
        mobileName: 'history',
        icon: `${TENANT_BUCKET}/icon/bankingInfo_menu_history.png`,
        selectedIcon: `${TENANT_BUCKET}/icon/bankingInfo_menu_history_active.png`,
        url: "/wallet/history",

      }],
      my_account: [{
        id: "4",
        name: "user_info",
        mobileName: 'user_info',
        icon: `${TENANT_BUCKET}/icon/myAccount_menu_userInfo.png`,
        selectedIcon: `${TENANT_BUCKET}/icon/myAccount_menu_userInfo_active.png`,
        url: "/wallet/user_info",

      }, {
        id: "5",
        name: "my_friend",
        mobileName: 'my_friend',
        icon: `${TENANT_BUCKET}/icon/myAccount_menu_myFriend.png`,
        selectedIcon: `${TENANT_BUCKET}/icon/myAccount_menu_myFriend_active.png`,
        url: "/wallet/my_friend",

      }, {
        id: "7",
        name: "notification",
        mobileName: 'notification',
        icon: `${TENANT_BUCKET}/icon/myAccount_menu_notification.png`,
        selectedIcon: `${TENANT_BUCKET}/icon/myAccount_menu_notification_active.png`,
        url: "/wallet/notification",

      }, {
        id: "8",
        name: "change_password",
        mobileName: 'change_password',
        icon: `${TENANT_BUCKET}/icon/myAccount_menu_changePassword.png`,
        selectedIcon: `${TENANT_BUCKET}/icon/myAccount_menu_changePassword_active.png`,
        url: "/wallet/change_password",

      }, {
        id: "9",
        name: "change_friend_transfer_password",
        mobileName: 'change_friend_transfer_password',
        icon: `${TENANT_BUCKET}/icon/myAccount_menu_changeFriendTransPwd.png`,
        selectedIcon: `${TENANT_BUCKET}/icon/myAccount_menu_changeFriendTransPwd_active.png`,
        url: "/wallet/change_friend_transfer_password",

      }, {
        id: "10",
        name: "banking_details",
        mobileName: 'banking_details',
        icon: `${TENANT_BUCKET}/icon/myAccount_menu_bankingDetails.png`,
        selectedIcon: `${TENANT_BUCKET}/icon/myAccount_menu_bankingDetails_active.png`,
        url: "/wallet/banking_details",

      }]
    } 


// ---------- MY PROFILE MENU ---------- 

export const MY_PROFILE_MENU_DATA_OLD =
    [
      {
        name: "user_info",
        mobileName: 'user_info',
        icon: `${TENANT_BUCKET}/icon/myProfile_userInfo.png`,
        url: "/wallet/user_info",

      }, {

        name: "change_password",
        mobileName: 'change_password',
        icon: `${TENANT_BUCKET}/icon/myProfile_changePassword.png`,
        url: "/wallet/change_password",

      }, {

        name: "banking_details",
        mobileName: 'banking_details',
        icon: `${TENANT_BUCKET}/icon/myProfile_bankingDetails.png`,
        url: "/wallet/banking_details",

      }]

export const MY_PROFILE_MENU_DATA =
    [
      {
        name: "user_info",
        mobileName: 'user_info',
        icon: `${TENANT_BUCKET}/icon/myProfile_userInfo.png`,
        url: "/wallet/user_info",

      }, {

        name: "my_friend",
        mobileName: 'my_friend',
        icon: `${TENANT_BUCKET}/icon/myProfile_myFriend.png`,
        url: "/wallet/my_friend",

      }, {

        name: "change_password",
        mobileName: 'change_password',
        icon: `${TENANT_BUCKET}/icon/myProfile_changePassword.png`,
        url: "/wallet/change_password",

      }, {

        name: "change_friend_transfer_password",
        mobileName: 'change_friend_transfer_password',
        icon: `${TENANT_BUCKET}/icon/myProfile_changeFriendTransPwd.png`,
        url: "/wallet/change_friend_transfer_password",

      }, {

        name: "banking_details",
        mobileName: 'banking_details',
        icon: `${TENANT_BUCKET}/icon/myProfile_bankingDetails.png`,
        url: "/wallet/banking_details",

      }] 

// ---------- DEPOSIT MENU ---------- 

export const DEPOSIT_MENU_DATA = {

    "quickpay": {
      name: "quickpay",
      icon: `${TENANT_BUCKET}/icon/deposit_quickPay.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/deposit_quickPay_active.png`,
    },

    "banktransfer": {
      name: "banktransfer",
      icon: `${TENANT_BUCKET}/icon/deposit_bankTransfer.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/deposit_bankTransfer_active.png`,
    },

    "qrpay": {
      name: "qrpay",
      icon: `${TENANT_BUCKET}/icon/deposit_qrPay.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/deposit_qrPay_active.png`,
    },

    "reload": {
      name: "reload",
      icon: `${TENANT_BUCKET}/icon/deposit_reload.png`,
      selectedIcon: `${TENANT_BUCKET}/icon/deposit_reload_active.png`,

    }
  }

// ---------- DEPOSIT METHOD DATA ----------


export const DEPOSIT_METHOD_DATA = {

    "truepay": {
      name: "truepay",
      label: "Truepay",
    },

    "ipay4u": {
      name: "ipay4u",
      label: "Ipay4u",
    },

    "online_transfer": {
      name: "online_transfer",
      label: "Online Transfer",
    },

    "atm_cash_deposit": {
      name: "atm_cash_deposit",
      label: "ATM Cash Deposit",
    },

    "cash_deposit": {
      name: "cash_deposit",
      label: "Cash Deposit",
    }
  }

// ---------- BANK DATA ----------

export const BANK_DATA = {

    "PHBMMYKL": {
      id: "PHBMMYKL",
      name: "Affin Bank",
      country: 'MY',
      icon: `${GLOBAL_BUCKET}/bank/MYR/PHBMMYKL.png`,
      qrIcon: `${TENANT_BUCKET}/bank-qrcode/PHBMMYKL.png`
    },

    "ARBKMYKL": {
      id: "ARBKMYKL",
      name: "Ambank",
      country: 'MY',
      icon: `${GLOBAL_BUCKET}/bank/MYR/ARBKMYKL.png`,
      qrIcon: `${TENANT_BUCKET}/bank-qrcode/ARBKMYKL.png`
    },

    "BIMBMYKL": {
      id: "BIMBMYKL",
      name: "Bank Islam Malaysia",
      country: 'MY',
      icon: `${GLOBAL_BUCKET}/bank/MYR/BIMBMYKL.png`,
      qrIcon: `${TENANT_BUCKET}/bank-qrcode/BIMBMYKL.png`
    },

    "BSNAMYK1": {
      id: "BSNAMYK1",
      name: "Bank Simpanan National",
      country: 'MY',
      icon: `${GLOBAL_BUCKET}/bank/MYR/BSNAMYK1.png`,
      qrIcon: `${TENANT_BUCKET}/bank-qrcode/BSNAMYK1.png`

    },

    "HLBBMYKL": {
      id: "HLBBMYKL",
      name: "Hong Leong Bank Berhad",
      country: 'MY',
      icon: `${GLOBAL_BUCKET}/bank/MYR/HLBBMYKL.png`,
      qrIcon: `${TENANT_BUCKET}/bank-qrcode/HLBBMYKL.png`
    },

    "MBBEMYKL": {
      id: "MBBEMYKL",
      name: "Maybank Berhad",
      country: 'MY',
      icon: `${GLOBAL_BUCKET}/bank/MYR/MBBEMYKL.png`,
      qrIcon: `${TENANT_BUCKET}/bank-qrcode/MBBEMYKL.png`
    },

    "PBBEMYKL": {
      id: "PBBEMYKL",
      name: "Public Bank Berhad",
      country: 'MY',
      icon: `${GLOBAL_BUCKET}/bank/MYR/PBBEMYKL.png`,
      qrIcon: `${TENANT_BUCKET}/bank-qrcode/PBBEMYKL.png`
    },

    "RHBBMYKL": {
      id: "RHBBMYKL",
      name: "RHB Bank Berhad",
      country: 'MY',
      icon: `${GLOBAL_BUCKET}/bank/MYR/RHBBMYKL.png`,
      qrIcon: `${TENANT_BUCKET}/bank-qrcode/RHBBMYKL.png`
    },

    "CIBBMYKL": {
      id: "CIBBMYKL",
      name: "CIMB Bank",
      country: 'MY',
      icon: `${GLOBAL_BUCKET}/bank/MYR/CIBBMYKL.png`,
      qrIcon: `${TENANT_BUCKET}/bank-qrcode/CIBBMYKL.png`
    },
  }

// ---------- QR DATA ----------

export const QR_DATA = {

    "duitnow": {
      id: "duitnow",
      name: "DuitNow QR",
      icon: `${GLOBAL_BUCKET}/qrpay/MYS/duitnow.jpg`,
    },
  }

// ---------- TRANSACTION TYPE DATA ----------

export const TRANSACTION_TYPE_DATA = {

    "transfer": {
      name: "transfer",
      url: "/wallet/history/transfer"
    },

    "betting_summary": {
      name: "betting_summary",
      url: "/wallet/history/betting_summary"
    },

    "deposit_withdrawal": {
      name: "deposit_withdrawal",
      url: "/wallet/history/deposit_withdrawal"
    },

    "promotion_apply": {
      name: "promotion_apply",
      url: "/wallet/history/promotion_apply"
    },

    "rebate": {
      name: "rebate",
      url: "/wallet/history/rebate"
    },

    "bonus": {
      name: "bonus",
      url: "/wallet/history/bonus"
    },
  }

// ---------- USER VERIFICATION DATA ----------

export const USER_VERIFICATION_DATA = [
    {
      id: "phone_validated",
      name: "phone_number",
      // icon:<FaMobileAlt />,
    },
    {
      id: "email_validated",
      name: "email",
    },
    {
      id: "dob_validated",
      name: "dob",
      // icon: <FaAddressCard />,
    }
  ]

// ---------- SOCIAL MEDIA DATA ----------

export const SOCIAL_MEDIA_DATA = {

    "LIVECHAT": {
      name: "LiveChat",
      category: 'contactUs',
      icon: `${TENANT_BUCKET}/icon/contactUs_chatwithus.png`,
    },

    "TAWK_TO": {
      name: "LiveChat",
      category: 'contactUs',
      icon: `${TENANT_BUCKET}/icon/contactUs_chatwithus.png`,
    },

    "TELEGRAM": {
      name: "Telegram",
      category: 'contactUs',
      icon: `${TENANT_BUCKET}/icon/contactUs_telegram.png`,
    },

    "WHATSAPP": {
      name: "Whatsapp",
      category: 'contactUs',
      icon: `${TENANT_BUCKET}/icon/contactUs_whatsapp.png`,
    },

    "WECHAT": {
      name: "Wechat",
      category: 'contactUs',
      icon: `${TENANT_BUCKET}/icon/contactUs_wechat.png`,
    },
    "SIDEBAR_LIVECHAT": {
      name: "LiveChat",
      category: 'contactUs',
      icon: `${TENANT_BUCKET}/icon/sideBar_chatwithus.png`,
    },

    "SIDEBAR_TAWK_TO": {
      name: "LiveChat",
      category: 'contactUs',
      icon: `${TENANT_BUCKET}/icon/sideBar_chatwithus.png`,
    },

    "SIDEBAR_TELEGRAM": {
      name: "Telegram",
      category: 'contactUs',
      icon: `${TENANT_BUCKET}/icon/sideBar_telegram.png`,
    },

    "SIDEBAR_WHATSAPP": {
      name: "Whatsapp",
      category: 'contactUs',
      icon: `${TENANT_BUCKET}/icon/sideBar_whatsapp.png`,
    },

    "SIDEBAR_WECHAT": {
      name: "Wechat",
      category: 'contactUs',
      icon: `${TENANT_BUCKET}/icon/sideBar_wechat.png`,
    },


    "TIKTOK": {
      name: "TikTok",
      category: 'socialMedia',
      icon: `tiktok`,
    },

    "YOUTUBE": {
      name: "YouTube",
      category: 'socialMedia',
      icon: `youtube`,
    },

    "TWITTER": {
      name: "Twiiter",
      category: 'socialMedia',
      icon: `twitter`,
    },

    "INSTAGRAM": {
      name: "Instagram",
      category: 'socialMedia',
      icon: `instagram`,
    },

    "FACEBOOK": {
      name: "Facebook",
      category: 'socialMedia',
      icon: `facebook`,
    },
  }

// ---------- GAME WALLET CATEGORY ----------
export const GAME_WALLET_CATEGORY = [
    ["SL", "LC", "FH", "APP"], ["SB", "ES"], ["CB", "LK", "PK", "MG"]
  ]

// ---------- HISTORY TABLE FIELD ----------
export const HISTORY_TABLE_FIELD = {

    "TRANSFER": {
      id: "TRANSFER",
      allAttr: ["from", "to", "amount", "transaction_date", "transaction_status"],
      selectedTableColumn: ["transaction_date", "to", "amount", "transaction_status"]
    },

    "BETTING_SUMMARY": {
      id: "BETTING_SUMMARY",
      allAttr: ["game_provider", "bet_count", "bet_amount", "valid_bet_amount", "total_bonus", "total_rebate"],
      selectedTableColumn: ["game_provider", "bet_count", "bet_amount"]
    },

    "DEPOSIT_WITHDRAW": {
      id: "DEPOSIT_WITHDRAW",
      allAttr: ["payment_type", "ticket_id", "amount", "transaction_date", "transaction_status", 'remark'],
      selectedTableColumn: ["paymentType_transactionDate", "amount", "transaction_status"]
    },

    "PROMOTION_APPLY": {
      id: "PROMOTION_APPLY",
      allAttr: ["ticket_id", "promotion_name", "to", "transfer_amount", "promo_bonus_amount", "target_amount", "transaction_date", "transaction_status", 'remark'],
      selectedTableColumn: ["promotion", "transfer_amount", "promo_bonus_amount", "transaction_status"]
    },

    "REBATE": {
      id: "REBATE",
      allAttr: ["release_date", "release_amount", "rebate_start_date", "rebate_end_date", "status"],
      selectedTableColumn: ["release_date", "release_amount", "rebate_start_date", "rebate_end_date", "status"]
    },

    "BONUS": {
      id: "BONUS",
      allAttr: ["from", "to", "amount", "transaction_date", "transaction_status"],
      selectedTableColumn: ["to", "amount", "transaction_status"]
    },
  }

export const IMAGE_SIZE_DYNAMIC = {
    '1x1': {
      'desktop': {
        'none': {
          4: 320,
          5: 240,
          6: 240,
          7: 150,
        },
        'right': {
          2: 320,
          3: 240
        },
        'bottom': {
          4: 320,
          5: 240,
          6: 240,
          7: 150,
        },
        'insideImage': {
          4: 320,
          5: 240,
          6: 240,
          7: 150,
        },
      },
      'mobile': {
        'none': {
          2: 150,
          3: 150,
          4: 150,
        },
        'right': {
          1: 150,

        },
        'bottom': {
          2: 150,
          3: 150,
          4: 150,
        }, 'insideImage': {
          2: 150,
          3: 150,
          4: 150,
        }
      }
    },
    '1x2': {
      'desktop': {
        'none': {
          2: 750,
          3: 480,
          4: 320,
          5: 320,
          6: 320,
        },
        'right': {
          1: 750,
          2: 320
        },
        'bottom': {
          2: 750,
          3: 480,
          4: 320,
          5: 320,
          6: 320,
        },
        'insideImage': {
          2: 750,
          3: 480,
          4: 320,
          5: 320,
          6: 320,
        },
      },
      'mobile': {
        'none': {
          1: 320,
          2: 320,
          3: 320,
        },
        'right': {
          1: 320,

        },
        'bottom': {
          2: 320,
          3: 320,
          4: 320,
        },
        'insideImage': {
          2: 750,
          3: 480,
          4: 320,
          5: 320,
          6: 320,
        },
      }
    }
  }