import React, { useEffect, useState, useRef } from "react";
import { Container, Modal, Nav, Navbar, Offcanvas, OverlayTrigger, Popover, Accordion } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { AiOutlineMenu } from "react-icons/ai";
import { FaEye, FaEyeSlash, FaTimes } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";
import { useMediaQuery } from 'react-responsive';
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { DESIGNER_MODE, GLOBAL_BUCKET, HEADER_MENU_DATA, TENANT_BUCKET, TENANT_NAME, WALLET_MENU_DATA, GAME_MENU_DATA } from '../../common/constants';
import { useMiddletier } from "../../common/middletier";
import { APP_STORE_ACTION, useStoreDispatch, useStoreState } from '../../common/storeContext';
import Announcement from '../announcement/template_01';
// import ImageWithPlaceholder from '../../components/ImageWithPlaceholder'
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { LiveChatWidget } from '@livechat/widget-react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import Loading from '../../components/Loading';
import PromoClaimDialog from '../../components/PromoClaimDialog';
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { FaChevronDown } from "react-icons/fa";
import TransferBalanceDialog from '../../components/TransferBalanceDialog'

import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

import "./template_03.css";
// import { BsThreeDotsVertical } from "react-icons/bs";


const HeaderMenu = (props) => {
  const appState = useStoreState()
  const [getHotGames, setGetHotGames] = useState([])
  const { name, icon, gameType, url, navIconToggle, gameProviders, menuIconPosition, selectedIcon, mobileShow, dropdownGameProviderNameToggle, enableDropdownHoverImage, dropdownMore } = props;
  const { query, mutation, queries } = useMiddletier()
  const [deviceType, setDeviceType] = useState('');
  const appDispatch = useStoreDispatch()
  const [isLoading, setLoading] = useState(false);
  const [transferDialogShow, setTransferDialogShow] = useState({});

  const { t, i18n } = useTranslation();

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })


  const onClickGame = (game) => {
    if (appState.loggedIn) {
      setLoading(true);
      // console.log('deviceType:', deviceType)
      let currentGame = {
        getCurrentGameType: '',
        getCurrentGameProvider: '',
        getCurrentGameCode: '',
        getCurrentGameUrl: '',
        getCurrentGameAfterGameLink: false,
      }

      currentGame.getCurrentGameType = game.type
      currentGame.getCurrentGameProvider = game.game_provider
      currentGame.getCurrentGameCode = game.code


      // OPEN NEW TAB FOR GAME
      const mytab = window.open("/loading.html", "_blank");

      // IF GAME PROVIDER AUTO TRANSFER AFTER GAME LINK TRUE (RESTORE FIRST THEN CALL GET GAME LINK)

      let method = 'getGameLinkByMember';
      let params = [
        { code: 'gameProvider', graphqlType: 'String', required: true, value: currentGame.getCurrentGameProvider },
        { code: 'device', graphqlType: 'String', required: false, value: deviceType === 'desktop' ? '0' : '1' },
        { code: 'gameType', graphqlType: 'String', required: true, value: currentGame.getCurrentGameType },
        { code: 'game', graphqlType: 'String', required: false, value: currentGame.getCurrentGameCode },
        { code: 'language', graphqlType: 'String', required: false, value: i18n.resolvedLanguage },
        { code: 'skipTransfer', graphqlType: 'Boolean', required: false, value: true },
      ];

      query({
        method,
        params,
        attributes: []

      }).then(({ data }) => {
        // setLoading(false)
        // console.log('getGameLinkByMember', data[method])

        const gameBalance = data[method].balance
        const gameUrl = data[method].gameLink
        const gameWalletAmount = data[method].wallet_amount
        currentGame.getCurrentGameUrl = gameUrl

        if (gameUrl === "") {
          setLoading(false);
          mytab.close()
          const getResponse = JSON.parse(data[method].response)
          const getResponseErrorMsg = getResponse?.errMsg

          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: getResponseErrorMsg, typeAlert: 'error' }
          });

          return
        } else {

          mytab.location = '/loading_transfer.html'

          mutation([{
            method: 'restoreGameWalletByMember',
            params: [
              { code: 'member', graphqlType: 'String', required: true, value: appState.user?.username },
              { code: 'gameProvider', graphqlType: 'String', required: true, value: currentGame.getCurrentGameProvider },
              { code: 'checkAutoTransfer', graphqlType: 'Boolean', required: false, value: true },
            ],
            attributes: []

          }])
            .then(({ data }) => {
              // console.log('data[restoreGameWalletByMember]', data['restoreGameWalletByMember'])

              mytab.location = gameUrl

              if (gameBalance === 0 && data['restoreGameWalletByMember'] === 0) {
                setLoading(false);
                setTransferDialogShow({ show: true, balance: gameBalance, gameSelect: { ...currentGame }, walletAmount: gameWalletAmount });
              }

              else {

                queries([{
                  index: 'getAllGamePrivoderBalanceByMember',
                  method: 'getAllGamePrivoderBalanceByMember',
                  params: [],
                  attributes: []
                },
                {
                  index: 'member',
                  method: 'member',
                  params: [
                    { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
                  ],
                  attributes: ['wallet_amount']
                },])
                  .then(({ data }) => {

                    setLoading(false);

                    appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
                    appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data['member'].wallet_amount })


                  }).catch((error) => {
                    // console.log('error: ', error)
                    setLoading(false)
                    if (error?.networkError?.statusCode === 401) {
                      appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
                    } else {
                      appDispatch({
                        type: APP_STORE_ACTION.SHOW_ALERT,
                        payload: { description: error.message.toString(), typeAlert: 'error' }
                      });
                    }
                  })

              }

            })
            .catch((error) => {
              // console.log('error: ', error)
              setLoading(false)
              if (error?.networkError?.statusCode === 401) {
                appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
              } else {
                appDispatch({
                  type: APP_STORE_ACTION.SHOW_ALERT,
                  payload: { description: error.message.toString(), typeAlert: 'error' }
                });
              }
            })
        }



      }).catch((error) => {
        mytab.close()
        setLoading(false);
        // console.log('error: ', error)
        if (error?.networkError?.statusCode === 401) {
          appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
        } else {
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: error.message.toString(), typeAlert: 'error' }
          });
        }
      })




    }
    else {
      appDispatch({
        type: APP_STORE_ACTION.SHOW_ALERT,
        payload: { description: t('error_login_continue'), typeAlert: 'error' }
      });
    }

  }

  useEffect(() => {
    if (name === 'hotGames') {
      query({
        method: 'getGames',
        params: [
          { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { type: "SL", "$_game_provider.status$": "ACTIVE", status: "ACTIVE", hot: true } } },
        ],
        attributes: ['id', 'name', 'sub_type', 'code', 'game_provider', ['_game_provider', ['status']]]
      }).then(({ data }) => {

        setGetHotGames(data['getGames'])

      }).catch((error) => {

      })
    } else {
      setGetHotGames([])
    }

  }, [query, name])

  useEffect(() => {
    const userAgent = navigator.userAgent;
    let getDevice = ''

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
      getDevice = 'mobile'
      setDeviceType("mobile");
    } else {
      getDevice = 'desktop'
      setDeviceType("desktop");
    }
    // console.log('getDevice:', getDevice)

  }, [])

  return (
    <>
      {(!mobileShow && !isDesktopOrLaptop) ? <></> :
        (name === 'mall') ?
          (appState.enableMall && <NavLink to={`${appState.mallUrl ?? url}`} target="_blank">

            <div className={`header_menu_content ${dropdownMore ? 'dropdown-item header_menu_dropdownItem' : ''}`} >
              {navIconToggle &&
                <div style={{ '--menu-icon': `url(${`${icon}`})`, '--active-menu-icon': `url(${`${selectedIcon}`})` }} className={`header_menu_icon`} ></div>
              }
              <div className={`  header_text ${!navIconToggle ? 'm-0' : ''}`} style={{ margin: menuIconPosition === 'vertical' ? '0.25rem 0 0 0' : '0 0 0 .35rem' }} >{t('' + name + '')}</div>
            </div>

          </NavLink>) : ((name === 'affiliate') ?
            (appState.enableAffiliate && <NavLink to={`${appState.affiliateUrl ?? url}`} target="_blank">

              <div className={`header_menu_content ${dropdownMore ? 'dropdown-item header_menu_dropdownItem' : ''}`} >
                {navIconToggle &&
                  <div style={{ '--menu-icon': `url(${`${icon}`})`, '--active-menu-icon': `url(${`${selectedIcon}`})` }} className={`header_menu_icon`} ></div>
                }
                <div className={`  header_text ${!navIconToggle ? 'm-0' : ''}`} style={{ margin: menuIconPosition === 'vertical' ? '0.25rem 0 0 0' : '0 0 0 .35rem' }} >{t('' + name + '')}</div>
              </div>

            </NavLink>) : (<NavLink to={`${url}`}>
              {({ isActive }) => (
                <div className={`header_menu_content ${isActive && 'color_active active'} ${dropdownMore ? 'dropdown-item header_menu_dropdownItem' : ''}`} >
                  {navIconToggle &&
                    <div style={{ '--menu-icon': `url(${`${icon}`})`, '--active-menu-icon': `url(${`${selectedIcon}`})` }} className={`header_menu_icon`} ></div>
                  }
                  <div className={`  header_text ${!navIconToggle ? 'm-0' : ''}`} style={{ margin: menuIconPosition === 'vertical' ? '0.25rem 0 0 0' : '0 0 0 .35rem' }} >{t('' + name + '')}</div>
                </div>
              )}
            </NavLink>))
      }

      {isDesktopOrLaptop && gameProviders.length > 0 ?
        <div className="dropdown_content">
          <div className="container dropdown_content_container">
            <div className="dropdown_content_wrap">
              {
                gameProviders.map((item, index) => {
                  // item = appState.gameProviders[item]
                  return (
                    <Link to={`${url}#${item.code}`} key={index}>

                      {enableDropdownHoverImage ? (<div style={{ '--dropdown-image': `url(${TENANT_BUCKET}/game-provider/dropdown/non-active/${gameType}/${item.code}.png)`, '--active-dropdown-image': `url(${TENANT_BUCKET}/game-provider/dropdown/active/${gameType}/${item.code}.png)` }} className={`dropdownHoverImg`}></div>) : (<img src={`${TENANT_BUCKET}/game-provider/dropdown/${gameType}/${item.code}.png`} alt="" className="navgameimg" />)}
                      {dropdownGameProviderNameToggle && (<div>{item.name}</div>)}
                    </Link>
                  );
                })
              }
            </div>
          </div>
        </div>
        : <></>
      }

      {isDesktopOrLaptop && getHotGames.length > 0 ?
        <div className="dropdown_content">
          <div className="container dropdown_content_container">
            <div className="dropdown_content_wrap">
              {
                getHotGames.map((item, index) => {
                  // item = appState.gameProviders[item]
                  return (
                    <Link to={`${url}`} key={index} onClick={onClickGame}>
                      <img src={`${GLOBAL_BUCKET}/game/SL/1x1/img_jpg/${item.code}_1x1_w240.jpg`} placeholder={`https://storage.googleapis.com/tenant-images.imbaweb.com/image-loading/1x1.png`} alt={item.title} />
                      {dropdownGameProviderNameToggle && (<div>{item.name}</div>)}
                    </Link>
                  );
                })
              }
            </div>
          </div>
        </div>
        : <></>
      }

      {isLoading && (<Loading />)}
      <TransferBalanceDialog show={transferDialogShow.show} onHide={() => setTransferDialogShow({ ...transferDialogShow, show: false })} balance={transferDialogShow.balance} gameSelect={transferDialogShow.gameSelect} walletAmount={transferDialogShow.walletAmount} />
    </>
  );
};

const HeaderGameWallet = (props) => {
  const appDispatch = useStoreDispatch()
  const appState = useStoreState()
  // const navigate = useNavigate();
  const { query, mutation, queries } = useMiddletier()
  const [isLoading, setLoading] = useState(false);
  const { t, } = useTranslation();


  // const formatAmount = (amount, decimalPoint) => {
  //   const factor = Math.pow(10, decimalPoint);
  //   const truncatedAmount = Math.floor(amount * factor) / factor;
  //   const formattedAmount = truncatedAmount.toLocaleString(appState.currencyFormat?.locale, {
  //     minimumFractionDigits: decimalPoint,
  //     maximumFractionDigits: decimalPoint,
  //   });
  //   return formattedAmount;
  // }
  const formatAmount = (amount, decimalPoint) => {
    let strAmount = Number(amount).toString();
    let dotIndex = strAmount.indexOf('.');

    if (dotIndex === -1) {
      strAmount += '.' + '0'.repeat(decimalPoint);
    } else {
      const actualDecimals = strAmount.length - dotIndex - 1;
      if (actualDecimals > decimalPoint) {
        strAmount = strAmount.substring(0, dotIndex + decimalPoint + 1);
      } else if (actualDecimals < decimalPoint) {
        strAmount += '0'.repeat(decimalPoint - actualDecimals);
      }
    }

    const formattedAmount = parseFloat(strAmount).toLocaleString(appState.currencyFormat?.locale, {
      minimumFractionDigits: decimalPoint,
      maximumFractionDigits: decimalPoint,
    });

    return formattedAmount;
  };
  const onClickRefreshBalance = () => {
    if (appState.loggedIn) {
      setLoading(true)
      const method = 'member'

      query({
        method,
        params: [
          { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
        ],
        attributes: ['wallet_amount']

      }).then(({ data }) => {
        setLoading(false)
        appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data[method].wallet_amount })


      }).catch((error) => {
        setLoading(false)
        console.log('error: ', error)
        if (error?.networkError?.statusCode === 401) {
          appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
        } else {
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: error.message.toString(), typeAlert: 'error' }
          });
        }

      })
    }
  }
  const allInClick = (game_provider) => {
    setLoading(true)
    const method = 'depositGamePrivoderByMember'


    mutation([{
      method,
      params: [
        { code: 'gameProvider', graphqlType: 'String', required: true, value: game_provider },
        { code: 'currency', graphqlType: 'String', required: false, value: appState.user?.currency ?? appState.currency },
        { code: 'amount', graphqlType: 'Float', required: true, value: parseFloat(-1) }],
      attributes: []

    }]).then(({ data }) => {
      // IF STATUS IS APPROVED
      if (data[method].status === 'APPROVED') {
        queries([{
          index: 'getAllGamePrivoderBalanceByMember',
          method: 'getAllGamePrivoderBalanceByMember',
          params: [],
          attributes: []
        },
        {
          index: 'member',
          method: 'member',
          params: [
            { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
          ],
          attributes: ['wallet_amount']
        },])
          .then(({ data }) => {

            appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
            appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data['member'].wallet_amount })

            setLoading(false)

          }).catch((error) => {
            console.log('error: ', error)
            setLoading(false)
            if (error?.networkError?.statusCode === 401) {
              appDispatch({ type: APP_STORE_ACTION.LOGOUT })
              appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
            } else {
              appDispatch({
                type: APP_STORE_ACTION.SHOW_ALERT,
                payload: { description: error.message.toString(), typeAlert: 'error' }
              });
            }
          })

      } else {
        setLoading(false)
        const getErrorMsg = data[method].rejected_reason ? data[method].rejected_reason : (JSON.parse(data[method].response).errMsg)
        console.log('getErrorMsg: ', getErrorMsg)
        appDispatch({
          type: APP_STORE_ACTION.SHOW_ALERT,
          payload: { description: getErrorMsg, typeAlert: 'error' }
        });
      }
    }).catch((error) => {
      console.log('error: ', error)
      setLoading(false)
      if (error?.networkError?.statusCode === 401) {
        appDispatch({ type: APP_STORE_ACTION.LOGOUT })
        appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
      } else {
        appDispatch({
          type: APP_STORE_ACTION.SHOW_ALERT,
          payload: { description: error.message.toString(), typeAlert: 'error' }
        });
      }
    })

  };


  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        id="gameWalletModal"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="contained-modal-title-vcenter" className="subTitle">
          {props.modaltitle}
        </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="game_wallet_dropdown">
            <div className="color_button main_wallet">
              <div>{t('main_wallet')}</div>
              <div>{formatAmount(appState.user?.wallet_amount ?? 0, appState.currencyFormat.decimal_point)}</div>
              <div><img src={`${TENANT_BUCKET}/icon/refresh_wallet_icon.png`} alt="" onClick={() => onClickRefreshBalance()} /></div>
            </div>
            <Accordion className='gameWallet_accordion' >
              <SimpleBar style={{ maxHeight: '30rem' }}>
                {appState?.gameTypes?.filter(item =>
                  appState?.gameWallet?.some(data => data.type.includes(item.code))
                ).map(item => {
                  const entries = appState?.gameWallet?.filter(data => data.type.includes(item.code));
                  const getTypeName = GAME_MENU_DATA[item?.code] ?? '';
                  const totalBalance = entries.reduce((sum, data) => sum + parseFloat(data.balance), 0);
                  return entries.length > 0 ? (
                    <Accordion.Item eventKey={`#${item?.code}`} id={item?.code} key={item?.code}>
                      <Accordion.Header>
                        <div>{t(getTypeName?.name)}</div>
                        <div>{formatAmount(totalBalance ?? 0, appState.currencyFormat.decimal_point)}</div>
                        <div className="gameWallet_accordion_icon"><FaChevronDown /></div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="game_wallet_dropdown_body">
                          <table>
                            <thead>
                              <tr>
                                <th></th>
                                <th></th>
                                <th>{t('transfer')}</th>
                                <th>{t('all_in')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {entries.map(data => (
                                <tr key={data.id}>
                                  <td>{data.name}</td>
                                  <td>{formatAmount(data.balance ?? 0, appState.currencyFormat.decimal_point)}</td>
                                  <td> <Link to={`/wallet/transfer?from=${data.code}`}><img src={`${TENANT_BUCKET}/icon/transfer_out_icon.png`} alt="" /></Link></td>
                                  <td> <img src={`${TENANT_BUCKET}/icon/game_wallet_icon.png`} alt="" onClick={() => allInClick(data.code)} /></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : null;
                })}
              </SimpleBar>
            </Accordion>
            {/* <table>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>{t('transfer')}</th>
                  <th>{t('all_in')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  appState.gameWallet?.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td>{item.name}</td>
                        <td>{formatAmount(item.balance ?? 0, appState.currencyFormat.decimal_point)}</td>
                        <td> <Link to={`/wallet/transfer?from=${item.code}`}><img src={`${TENANT_BUCKET}/icon/transfer_out_icon.png`} alt="" /></Link></td>
                        <td> <img src={`${TENANT_BUCKET}/icon/game_wallet_icon.png`} alt="" onClick={() => allInClick(item.code)} /></td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table> */}
          </div>

        </Modal.Body>
      </Modal >
      {isLoading && (<Loading />)}

    </>
  );
}




// const Timer = ({ time: currentTime }) => {
//   const [time, setTime] = useState(currentTime);
//   useEffect(() => {
//     const id = setInterval(() => {
//       setTime(new Date(time.getTime() + 1000));
//     }, 1000);
//     return () => clearInterval(id);
//   }, [currentTime, time]);

//   let now = new Date();
//   let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(now);
//   let mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(now);
//   let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(now);
//   let time2 = new Intl.DateTimeFormat("en", {
//     hour: "2-digit",
//     minute: "2-digit",
//     second: "2-digit",
//   }).format(now);

//   return (
//     <div className="font_h5  header_time ">
//       {da}-{mo}-{ye} &nbsp;
//       {time2}
//     </div>
//   );
// };


const Header = (props) => {
  const appDispatch = useStoreDispatch()
  const appState = useStoreState()
  const navigate = useNavigate();
  const { query, mutation, queries } = useMiddletier()
  // const { register, handleSubmit, getValues, setValue, reset } = useForm();
  const { getValues, setValue, reset } = useForm();
  // const { timeToggle, navIconToggle, desktopToggle, mobileToggle, dropdownImageSize, annLogoToggle, headerMenuPosition } = props
  const { navIconDesktopToggle, navIconMobileToggle, desktopToggle, mobileToggle, dropdownImageSize, annLogoToggle, annHeaderToggle, headerMenuPosition, dropdownGameProviderNameToggle, sidebarToggle, enableDropdownHoverImage, menuInNewRow } = props

  // const times = [new Date()];
  const [announcementShow, setAnnouncementShow] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [promoClaimShow, setPromoClaimShow] = useState(false);
  const [loginMultipleShow, setLoginMultipleShow] = useState(false);
  // const [gameWallet, setGameWallet] = useState();
  const [language, setLanguage] = useState([]);
  const [languageShow, setLanguageShow] = useState(false);
  const [defaultLiveChat, setDefaultLiveChat] = useState('');

  const [livechatID, setLiveChatID] = useState({ licenseId: '', propertyId: '', widgetId: '' });
  const [isLoading, setLoading] = useState(false);

  const [showBalance, setShowBalance] = useState(false);
  const [showCouponBalance, setShowCouponBalance] = useState(false);

  const { t, i18n } = useTranslation();
  // const [chatVisibility, setChatVisibility] = useState('hidden');
  const [visitorId, SetVisitorId] = useState()
  // const [hoverStates, setHoverStates] = useState([false, false, false, false]);
  const location = useLocation();
  const isProfilePage = location.pathname === '/my-profile';
  // const [headerState, headerDispatch] = useReducer(headerReducer, initialHeaderData)
  // const [games, setGames] = useState([]);
  const [gameProviders, setGameProviders] = useState([]);
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })
  const tawkMessengerRef = useRef();
  const [sideBarShow, setSideBarShow] = useState(false);
  // const [darkMode, setDarkMode] = useState(false);
  // const handleClose = () => setSideBarShow(false);
  // const handleShow = () => setSideBarShow(true);
  const menuIconPosition = ""

  const onChangeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem("current_language", language);
  }

  const onClickPointAlert = () => {
    appDispatch({
      type: APP_STORE_ACTION.SHOW_ALERT,
      payload: { info: 'point' }
    });
  }

  const onClickCouponPage = () => {
    navigate('/coupon')
  }



  const onSubmitLogin = (formData) => {
    const values = getValues();
    const method = 'loginByMember';

    let params = [
      { code: 'username', graphqlType: 'String', required: true, value: values.username },
      { code: 'password', graphqlType: 'String', required: true, value: values.password },
      { code: 'fingerprint', graphqlType: 'String', required: false, value: visitorId },
    ]


    if (values.force) {
      params.push({ code: 'force', graphqlType: 'Boolean', required: false, value: values.force })
    }

    mutation([{
      method,
      params,
      attributes: []
    }])
      .then(({ data }) => {
        // console.log(data[method])
        setValue('force', false)
        appDispatch({ type: APP_STORE_ACTION.LOGIN, payload: { ...data[method] } })
        reset()
      })
      .catch((error) => {
        console.log('error: ', error)
        if (error.toString().includes("multiple devices")) {
          setLoginMultipleShow(true)
        } else {
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: error.message.toString(), typeAlert: 'error' }
          });
        }

      })
  }

  // const onSubmitError = (data) => {
  //   //TODO: add error message, when verify login fail
  //   console.log(data);
  //   if (data) {
  //     setAlertShow({ show: true, message: Object.entries(data)[0][1].message.toString(), type: 'error' });
  //     // setAlertShow({ show: true, message: 'Incorrect Username or Password!', type: 'error' });

  //   }
  // }

  const onSubmitLogout = () => {
    mutation([
      {
        method: 'logout',
        params: [],
        attributes: []
      }
    ])
      .then(({ data }) => {
        appDispatch({ type: APP_STORE_ACTION.LOGOUT })
        if (window.LC_API) {
          window.LC_API.set_customer_name("");
        }
        navigate('/')
      })
      .catch((error) => {
        console.error('error: ', error)
        appDispatch({ type: APP_STORE_ACTION.LOGOUT })
        navigate('/')
      })
  }

  const onClickRestore = () => {
    if (appState.loggedIn) {
      setLoading(true)
      const method = 'restoreGameWalletByMember'

      mutation([{
        method,
        params: [
          { code: 'member', graphqlType: 'String', required: true, value: appState.user?.username },
        ],
        attributes: []

      }]).then(({ data }) => {

        query({

          method: 'getAllGamePrivoderBalanceByMember',
          params: [],
          attributes: []

        }).then(({ data }) => {
          onClickRefreshBalance()
          appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
          setLoading(false)

        }).catch((error) => {
          setLoading(false)
          console.log('error: ', error)

          if (error?.networkError?.statusCode === 401) {
            appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
          } else {
            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: error.message.toString(), typeAlert: 'error' }
            });
          }
        })


      }).catch((error) => {
        setLoading(false)
        console.log('error: ', error)
        if (error?.networkError?.statusCode === 401) {
          appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
        } else {
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: error.message.toString(), typeAlert: 'error' }
          });
        }
      })
    }
  }

  const onClickRefreshBalance = () => {
    if (appState.loggedIn) {
      setLoading(true)
      const method = 'member'

      query({
        method,
        params: [
          { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
        ],
        attributes: ['wallet_amount']

      }).then(({ data }) => {
        setLoading(false)
        appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data[method].wallet_amount })

      }).catch((error) => {
        setLoading(false)
        console.log('error: ', error)
      })
    }
  }

  const allInClick = (game_provider) => {
    setLoading(true)
    const method = 'depositGamePrivoderByMember'


    mutation([{
      method,
      params: [
        { code: 'gameProvider', graphqlType: 'String', required: true, value: game_provider },
        { code: 'currency', graphqlType: 'String', required: false, value: appState.user?.currency ?? appState.currency },
        { code: 'amount', graphqlType: 'Float', required: true, value: parseFloat(-1) }],
      attributes: []

    }]).then(({ data }) => {
      // IF STATUS IS APPROVED
      if (data[method].status === 'APPROVED') {
        queries([{
          index: 'getAllGamePrivoderBalanceByMember',
          method: 'getAllGamePrivoderBalanceByMember',
          params: [],
          attributes: []
        },
        {
          index: 'member',
          method: 'member',
          params: [
            { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
          ],
          attributes: ['wallet_amount']
        },])
          .then(({ data }) => {

            appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
            appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data['member'].wallet_amount })

            setLoading(false)

          }).catch((error) => {
            console.log('error: ', error)
            setLoading(false)
            if (error?.networkError?.statusCode === 401) {
              appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
            } else {
              appDispatch({
                type: APP_STORE_ACTION.SHOW_ALERT,
                payload: { description: error.message.toString(), typeAlert: 'error' }
              });
            }
          })

      } else {
        setLoading(false)
        const getErrorMsg = data[method].rejected_reason ? data[method].rejected_reason : (JSON.parse(data[method].response).errMsg)
        console.log('getErrorMsg: ', getErrorMsg)
        appDispatch({
          type: APP_STORE_ACTION.SHOW_ALERT,
          payload: { description: getErrorMsg, typeAlert: 'error' }
        });
      }
    }).catch((error) => {
      console.log('error: ', error)
      setLoading(false)
      if (error?.networkError?.statusCode === 401) {
        appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
      } else {
        appDispatch({
          type: APP_STORE_ACTION.SHOW_ALERT,
          payload: { description: error.message.toString(), typeAlert: 'error' }
        });
      }
    })

  };

  // const formatAmount = (amount, decimalPoint) => {

  //   const factor = Math.pow(10, decimalPoint);
  //   const truncatedAmount = Math.floor(amount * factor) / factor;
  //   const formattedAmount = truncatedAmount.toLocaleString(appState.currencyFormat?.locale, {
  //     minimumFractionDigits: decimalPoint,
  //     maximumFractionDigits: decimalPoint,
  //   });
  //   return formattedAmount;
  // }
  const formatAmount = (amount, decimalPoint) => {
    let strAmount = Number(amount).toString();
    let dotIndex = strAmount.indexOf('.');

    if (dotIndex === -1) {
      strAmount += '.' + '0'.repeat(decimalPoint);
    } else {
      const actualDecimals = strAmount.length - dotIndex - 1;
      if (actualDecimals > decimalPoint) {
        strAmount = strAmount.substring(0, dotIndex + decimalPoint + 1);
      } else if (actualDecimals < decimalPoint) {
        strAmount += '0'.repeat(decimalPoint - actualDecimals);
      }
    }

    const formattedAmount = parseFloat(strAmount).toLocaleString(appState.currencyFormat?.locale, {
      minimumFractionDigits: decimalPoint,
      maximumFractionDigits: decimalPoint,
    });

    return formattedAmount;
  };

  const filterGameProvider = (gameType) => {
    const getFilterData = gameProviders.filter(item =>
      item._game_provider_game_types.some(type => type.type === gameType)
    );

    return getFilterData
  }

  // Hover IMAGE
  // const handleMouseEnter = (index) => {
  //   const newHoverStates = [...hoverStates];
  //   newHoverStates[index] = true;
  //   setHoverStates(newHoverStates);
  // }

  // const handleMouseLeave = (index) => {
  //   const newHoverStates = [...hoverStates];
  //   newHoverStates[index] = false;
  //   setHoverStates(newHoverStates);
  // }



  useEffect(() => {
    const getVisitorId = async () => {
      const fp = await FingerprintJS.load();
      const retVal = await fp.get();
      // console.log('retVal: ', retVal)
      SetVisitorId(retVal.visitorId);
    };

    getVisitorId();
  }, [])

  useEffect(() => {
    if (appState.loggedIn && appState.user?.username) {
      // const method = 'getAllGamePrivoderBalanceByMember'

      queries([
        // {
        //   index: 'getAllGamePrivoderBalanceByMember',
        //   method: 'getAllGamePrivoderBalanceByMember',
        //   params: [],
        //   attributes: []

        // },
        {
          index: 'currency',
          method: 'currency',
          params: [
            { code: 'code', graphqlType: 'String', required: true, value: appState.user?.currency ?? appState.currency },
          ],
          attributes: ['code', 'locale', 'conversion_factor', 'prefix', 'postfix', 'min_deposit_amount', 'max_deposit_amount', 'default_deposit_amounts', 'min_transfer_amount', 'max_transfer_amount', 'decimal_point']

        },
        {
          index: 'inboxes',
          method: 'inboxes',
          params: [
            { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { recipient: appState.user.username } } }
          ],
          attributes: ['id', 'recipient', 'subject', 'message', 'update_ctr', 'status', 'created_at']

        },{
          index: 'getMemberFriendRequest',
          method: 'getMemberFriendRequest',
          params: [],
          attributes: []

        }]).then(({ data }) => {

          const getCurrency = {
            ...data['currency'],
            default_deposit_amounts: JSON.parse(data['currency']?.default_deposit_amounts),
          };

          appDispatch({ type: APP_STORE_ACTION.SET_CURRENCY_FORMAT, payload: getCurrency })

          const getUnreadNotiCount = data['inboxes'].filter(item => item.status === 'UNREAD').length;

          appDispatch({ type: APP_STORE_ACTION.SET_UNREAD_NOTI, payload: getUnreadNotiCount })

          const getFriendRequestCount = data['getMemberFriendRequest']?.length;

          appDispatch({ type: APP_STORE_ACTION.SET_FRIEND_REQUEST_NOTI, payload: getFriendRequestCount })
          

        }).catch((error) => {
          console.log('error: ', error)
          if (error?.networkError?.statusCode === 401) {
            appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
          } else {
            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: error.message.toString(), typeAlert: 'error' }
            });
          }
        })

    }

  }, [appState.user, queries, appDispatch, navigate, appState.loggedIn, appState.currency]);

  // GET LANGUAGE
  useEffect(() => {
    const method = 'getLanguage'

    query({
      method,
      params: [
        { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { status: 'ACTIVE' } } },
      ],
      attributes: ['code', 'name']

    }).then(({ data }) => {

      setLanguage(data[method]);

    }).catch((error) => {
      console.log('error: ', error)
    })

  }, [query]);

  // GET ALL GAME PROVIDER BALANCE BY MEMBER
  useEffect(() => {
    if (appState.loggedIn) {

      queries([{
        index: 'getGameTypes',
        method: 'getGameTypes',
        params: [
          { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { status: 'ACTIVE', game_provider: `GS` }, order: [['updated_at', 'DESC']] } }
        ],
        attributes: [['type', 'code']]
      }, {
        index: 'getAllGamePrivoderBalanceByMember',
        method: 'getAllGamePrivoderBalanceByMember',
        params: [],
        attributes: []

      }]).then(({ data }) => {

        appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
        appDispatch({ type: APP_STORE_ACTION.SET_GAME_TYPE, payload: data['getGameTypes'] })

      }).catch((error) => {
        appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: [] })
        appDispatch({ type: APP_STORE_ACTION.SET_GAME_TYPE, payload: [] })
        console.log('error: ', error)
        if (error?.networkError?.statusCode === 401) {
          appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
        } else {
          if (Array.isArray(error)) {
            error.forEach((err) => {
              if (err.extensions?.code !== 'queryE006') {
                appDispatch({
                  type: APP_STORE_ACTION.SHOW_ALERT,
                  payload: { description: err.message.toString(), typeAlert: 'error' }
                });
              }
            });
          }

        }
      })
    }

  }, [queries, appState.loggedIn, appDispatch]);


  // GET COUPON AMOUNT BY MEMBER
  useEffect(() => {
    if (appState.loggedIn) {
      const method = 'getCouponAmount'

      query({
        method,
        params: [],
        attributes: []

      }).then(({ data }) => {

        appDispatch({ type: APP_STORE_ACTION.SET_COUPON_AMOUNT, payload: data['getCouponAmount'] })

      }).catch((error) => {
        console.log('error: ', error)
        if (error?.networkError?.statusCode === 401) {
          appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
        } else {
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: error.message.toString(), typeAlert: 'error' }
          });
        }
      })
    }

  }, [query, appState.loggedIn, appDispatch]);

  // GET COUPON ACTIVE
  useEffect(() => {
    if (appState.loggedIn && typeof appState?.user?.username !== 'undefined') {
      const method = 'getCoupons'

      query({
        method,
        params: [
          { code: 'member', graphqlType: 'String', required: true, value: appState?.user?.username },
        ],
        attributes: []

      }).then(({ data }) => {
        const getCouponActive = data['getCoupons']?.filter(item => item.status === 'ACTIVE')?.length;

        appDispatch({ type: APP_STORE_ACTION.SET_COUPON_ACTIVE_COUNT, payload: getCouponActive })

      }).catch((error) => {
        console.log('error: ', error)
        if (error?.networkError?.statusCode === 401) {
          appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
        } else {
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: error.message.toString(), typeAlert: 'error' }
          });
        }
      })
    }

  }, [query, appState.loggedIn, appDispatch, appState?.user?.username]);

  useEffect(() => {
    query(
      {
        method: 'getGameProviders',
        params: [
          { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { '$_game_provider_game_types.status$': 'ACTIVE', status: 'ACTIVE' }, order: [['index', 'ASC'], ['name', 'ASC']] } },
        ],
        attributes: ['code', ['_game_provider_game_types', ['type']], 'name', 'live_link', 'status']
      }
    )
      .then(({ data }) => {
        // console.log('data: ', data['game_providers'], data['games'])
        // setGames(data['games'])
        setGameProviders(data['getGameProviders'])
      })
      .catch((error) => {
        console.error(error)
      })
  }, [query])

  useEffect(() => {
    query(
      {
        method: 'getSocialMedia',
        params: [],
        attributes: []
      }
    )
      .then(({ data }) => {
        // console.log('data live chat: ', data['getSocialMedia'])
        let getDefaultLiveChat = data['getSocialMedia'].find(e => e.code === 'DEFAULT_LIVE_CHAT');
        if (getDefaultLiveChat) {
          const liveChatValue = JSON.parse(getDefaultLiveChat.value);
          const chatData = liveChatValue?.data || "";

          setDefaultLiveChat(chatData);

          let chatConfig = { licenseId: '', propertyId: '', widgetId: '' };

          if (chatData === 'TAWK_TO') {
            const tawkLiveChat = data['getSocialMedia'].find(e => e.code === 'TAWK_TO');
            if (tawkLiveChat) {
              const tawkValue = JSON.parse(tawkLiveChat.value);
              chatConfig = {
                licenseId: '',
                propertyId: tawkValue.propertyId || "",
                widgetId: tawkValue.widgetId || ""
              };
            }
          } else if (chatData === 'LIVECHAT') {
            const liveChat = data['getSocialMedia'].find(e => e.code === 'LIVECHAT');
            if (liveChat) {
              const liveChatValue = JSON.parse(liveChat.value);
              chatConfig = {
                licenseId: liveChatValue.license_id || "",
                propertyId: '',
                widgetId: ''
              };
            }
          }

          setLiveChatID(chatConfig);
        }

      })
      .catch((error) => {
        console.error(error)
      })
  }, [query])

  useEffect(() => {
    const showChat = () => {
      if (window.LC_API) {
        window.LC_API.open_chat_window();
        // if (typeof appState.user?.username !== 'undefined') {
        //   console.log('set username',appState.user?.username)
        //   window.LC_API.set_customer_name(appState.user?.username);
        // }
      }
    };

    window.addEventListener('openChat', showChat);

    // Clean up the effect
    return () => window.removeEventListener('openChat', showChat);
  }, [appState.user?.username]);

  useEffect(() => {
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();


    window.addEventListener('openChat', () => {
      if (window.Tawk_API) {
        window.Tawk_API.maximize();
      }
    });
  }, []);


  // IF LOCATION CHANGE
  useEffect(() => {

    // CHECK SIDEBAR
    setSideBarShow(false)

    // console.log('abc')
    // CHECK ANNOUCEMENT
    setAnnouncementShow(true)

    // CHECK TRANSFER PAGE
    if (location.pathname === '/wallet/transfer') {
      setModalShow(false)
    }

    //  CHECK AFFILIATE ID
    const getAffiliate = new URLSearchParams(window.location.search).get("affiliate")?.match(/^\d{6}/)?.[0] || null;

    if (getAffiliate !== null) {
      localStorage.setItem("aff_id", getAffiliate);
      mutation([{
        method: 'updateAffiliateLinkClickCount',
        params: [
          { code: 'affiliate', graphqlType: 'String', required: true, value: getAffiliate },
        ],
        attributes: []

      }]).then(({ data }) => {
        console.log('affiliate count: ', data)
      }).catch((error) => {
        console.log('error: ', error)
      })

    }

  }, [location, mutation])

  useEffect(() => {
    const getLastLanguage = localStorage.getItem('current_language');
    if (getLastLanguage !== '') {
      i18n.changeLanguage(getLastLanguage)
    }

  }, [i18n])


  const handleMaximize = () => {
    if (tawkMessengerRef.current) {
      tawkMessengerRef.current.maximize();
    }
  };
  // useEffect(() => {
  //   const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

  //   const handleColorSchemeChange = (event) => {
  //     if (event.matches) {
  //       setDarkMode(true);
  //       console.log('dark mode');
  //     } else {
  //       setDarkMode(false);
  //       console.log('light mode');
  //     }
  //   };

  //   if (mediaQuery.matches) {
  //     setDarkMode(true);
  //     console.log('dark mode');
  //   } else {
  //     setDarkMode(false);
  //     console.log('light mode');
  //   }


  //   mediaQuery.addEventListener('change', handleColorSchemeChange);

  //   return () => {

  //     mediaQuery.removeEventListener('change', handleColorSchemeChange);
  //   };

  // }, []);


  if ((desktopToggle || mobileToggle) === true) {
    return (
      <>

        <header id="header_03" className={`section ${isDesktopOrLaptop ? "header_d" : "header_m"} ${desktopToggle ? "" : "d-lg-none"} ${mobileToggle ? "" : "d-none d-lg-block"} ${appState.loggedIn ? 'header_afterLogin' : 'header_beforeLogin'} ${DESIGNER_MODE && ('designerMode')}  ${mobileToggle ? "" : "d-none d-lg-block"} ${menuInNewRow ? 'header_newRowMenu' : ''} ${appState.enableLoyalty ? 'headerEnableLoyalty' : ''}`}>
          {/* <div className="testMode">{darkMode ? 'dark mode' : 'light mode'} dark green,light red</div> */}
          {["lg"].map((expand) => (
            <Navbar key={expand} expand={expand} className="color_primary header_topBar">
              <Container className="header_topBar_container">
                <div className="header_topBar_toolbar">
                  {sidebarToggle && (<Navbar.Toggle aria-controls={`header_menu_expand_${expand}`} onClick={() => setSideBarShow(true)}>
                    <AiOutlineMenu />
                  </Navbar.Toggle>)}

                  <Link to="/" className={`header_logo ${(!isDesktopOrLaptop && !sidebarToggle) && 'noSideBar'}`}>
                    <img src={`${TENANT_BUCKET}/icon/header_logo.gif`} alt="Logo" />
                    {/* <img src={`https://storage.googleapis.com/demo-images.flexgaming.xyz/testing/header_logo_${darkMode ? 'dark' : 'light'}.gif`} alt="Logo" /> */}
                  </Link>
                </div>
                {/* ----------  Header Top Bar (Mobile) Start ----------  */}
                {!isDesktopOrLaptop &&
                  <div className="header_topBar_btn">
                    {appState.loggedIn ?
                      <>
                        {/* ----------  After Login Start ----------  */}
                        {!sidebarToggle && (<Link to="/"><button className="color_button font_button header_btnLogout" onClick={onSubmitLogout}>{t('logout')}</button></Link>)}
                        <div className="header_user me-2" onClick={() => setPromoClaimShow(true)}>
                          <img src={`${TENANT_BUCKET}/icon/promo_claim_icon.png`} alt="" />
                        </div>
                        <Link to="/wallet/notification" className="header_noti">
                          <img src={`${TENANT_BUCKET}/icon/header_notification.png`} alt="" />
                          {appState.unreadNotiCount > 0 && (
                            <span className="noti_dot"></span>
                          )}
                        </Link>
                        {sidebarToggle && (<Link to="/my-profile" className="header_user ms-2">
                          <img src={`${TENANT_BUCKET}/icon/header_user.png`} alt="" />
                          {/* <AiFillExclamationCircle /> */}
                        </Link>)}

                        {/* ----------  After Login End ----------  */}
                      </> :
                      <>
                        {/* ----------  Before Login Start (Mobile) ----------  */}
                        <Link to="/login"><button className="color_button font_button header_btnLogin" >{t('login')}</button></Link>
                        <Link to="/register"><button className="color_button font_button header_btnRegister">{t('join_now')}</button></Link>
                        {/* ---------- Before Login End ----------  */}
                      </>}

                    {language.length > 0 && (
                      <div id="header_language" onClick={() => setLanguageShow(true)}>
                        <img src={`${GLOBAL_BUCKET}/language/` + i18n.resolvedLanguage + `.png`} alt="language flag" />
                      </div>
                    )}
                  </div>}
                {/* ----------  Header Top Bar (Mobile) End ----------  */}
                <Navbar.Offcanvas
                  id={`header_menu_expand_${expand}`}
                  className={!isDesktopOrLaptop && `header_sideBar`}
                  aria-labelledby={`header_menu_Label_expand_${expand}`}
                  placement="end"
                  show={sideBarShow}
                  onHide={() => setSideBarShow(false)}
                >
                  <Offcanvas.Header className=" header_sideBar_topBar color_primary">
                    <Offcanvas.Title id={`header_sideBar_Label_expand_${expand}`}>
                      <div className="header_sideBar_logo">
                        <img src={`${TENANT_BUCKET}/icon/sidebar_logo.gif`} alt="Logo" />
                      </div>
                    </Offcanvas.Title>
                    <button className="btn-close" aria-label="Close" onClick={() => setSideBarShow(false)}>
                      <FaTimes />
                    </button>
                  </Offcanvas.Header>
                  <Offcanvas.Body className=" header_sideBar_body">
                    {/* ----------  Header Top Bar (Desktop) Start ----------  */}
                    <Nav className="header_topBar_wrap">
                      <div className="d-none d-lg-block w-100">
                        <div className="header_navigation_section">
                          {/* ----------  Header Menu (Desktop) Start ----------  */}
                          {!menuInNewRow && (< div className="header_menu">
                            <Container className="header_menu_container" style={{ justifyContent: `${headerMenuPosition ?? 'start'}` }}>
                              {appState.menu.map((item, index) => {
                                item = HEADER_MENU_DATA[item] ?? ""
                                return (
                                  <div className="header_menu_page" key={index} style={{ minWidth: menuIconPosition === 'vertical' ? '6rem' : '' }}>

                                    {((!navIconDesktopToggle && index < 6) || (navIconDesktopToggle && index < 5)) ?
                                      <HeaderMenu
                                        name={item.name}
                                        icon={item.icon}
                                        selectedIcon={item.selectedIcon}
                                        gameType={item.gameType ?? false}
                                        gameProviders={filterGameProvider(item.gameType) ?? []}
                                        url={item.url}
                                        navIconToggle={navIconDesktopToggle}
                                        menuIconPosition={menuIconPosition}
                                        dropdownImageSize={dropdownImageSize}
                                        dropdownGameProviderNameToggle={dropdownGameProviderNameToggle}
                                        enableDropdownHoverImage={enableDropdownHoverImage}
                                      />
                                      : <></>}
                                  </div>
                                )
                              })
                              }

                              {((!navIconDesktopToggle && appState.menu.length > 6) || (navIconDesktopToggle && appState.menu.length > 5)) &&
                                <div className="more_menu">
                                  <div className="header_menu_page">

                                    <div className="header_menu_content false">
                                      {navIconDesktopToggle && <div style={{ '--menu-icon': `url(${`${TENANT_BUCKET}/icon/menu_more.png`})`, '--active-menu-icon': `url(${`${TENANT_BUCKET}/icon/menu_more_active.png`})` }} className={`header_menu_icon`} ></div>}
                                      <div className={`header_text ${!navIconDesktopToggle && ('m-0')} `} style={{ margin: menuIconPosition === 'vertical' ? '0.25rem 0 0 0' : '0 0 0 .35rem' }} >
                                        {t('more')}
                                      </div>
                                    </div>

                                    <div className="dropdown_more_content">
                                      <div className="container dropdown_more_content_container">
                                        <div className="dropdown_more_content_wrap">
                                          {appState.menu.map((item, index) => {
                                            item = HEADER_MENU_DATA[item] ?? ""
                                            if ((!navIconDesktopToggle && index >= 6) || (navIconDesktopToggle && index >= 5)) {
                                              return (
                                                // <Link to={item.url} key={index}>
                                                //   <div className="header_menu_content dropdown-item header_menu_dropdownItem ">
                                                //     {navIconDesktopToggle && <div className="header_menu_icon">
                                                //       <img src={item.icon} alt="header icon" />
                                                //     </div>}
                                                //     <div className="font_h5  header_text">{t('' + item.name + '')}</div>
                                                //   </div>
                                                // </Link>

                                                <HeaderMenu
                                                  name={item.name}
                                                  icon={item.icon}
                                                  selectedIcon={item.selectedIcon}
                                                  gameType={false}
                                                  gameProviders={[]}
                                                  url={item.url}
                                                  navIconToggle={navIconDesktopToggle}
                                                  menuIconPosition={menuIconPosition}
                                                  dropdownImageSize={dropdownImageSize}
                                                  dropdownGameProviderNameToggle={dropdownGameProviderNameToggle}
                                                  enableDropdownHoverImage={enableDropdownHoverImage}
                                                  dropdownMore={true}
                                                />
                                              );
                                            }
                                            return null
                                          })}

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              }


                            </Container>
                          </div>)}


                          {/* ----------  Header Menu (Desktop) End ----------  */}

                          <div className="header_info_section">
                            {appState.loggedIn ?
                              <>
                                {/* ----------  After Login Start ----------  */}
                                <div className="header_top_row">
                                  <div className="header_banking_info">
                                    <div className="header_balance">
                                      <div className="header_balance_currency"><span>{t('main_wallet')}</span> <span>{appState.user?.currency ?? appState.currency}</span></div>
                                      <div className="header_balance_amount">{!showBalance ? formatAmount(appState.user?.wallet_amount ?? 0, appState.currencyFormat.decimal_point) : '**********'}</div>
                                    </div>
                                    <div className="header_balance_icon">
                                      {/* <FaRedo onClick={() => onClickRefreshBalance()} /> */}
                                      {!showBalance ? <FaEye onClick={() => setShowBalance(!showBalance)} /> : <FaEyeSlash onClick={() => setShowBalance(!showBalance)} />}

                                      <button className="font_button color_button header_btnRestore " onClick={() => onClickRestore()}>
                                        {t("restore")}
                                      </button>
                                      ︙
                                      <div className="btn_gamewallet header_top_icon">
                                        <img src={`${TENANT_BUCKET}/icon/game_wallet_icon.png`} alt="" />
                                        <div className="game_wallet_dropdown">
                                          <div className="color_button main_wallet">
                                            <div>{t('main_wallet')}</div>
                                            <div>{formatAmount(appState.user?.wallet_amount ?? 0, appState.currencyFormat.decimal_point)}</div>
                                            <OverlayTrigger
                                              key={'right'}
                                              placement={'right'}
                                              overlay={
                                                <Popover>
                                                  <Popover.Body className="icon_name">
                                                    {t(`refresh_balance`)}
                                                  </Popover.Body>
                                                </Popover>
                                              }
                                            >
                                              <img src={`${TENANT_BUCKET}/icon/refresh_wallet_icon.png`} alt="" onClick={() => onClickRefreshBalance()} />
                                            </OverlayTrigger>
                                            {/* <div></div> */}
                                          </div>
                                          <Accordion className='gameWallet_accordion' >
                                            <SimpleBar style={{ maxHeight: '30rem' }}>
                                              {appState?.gameTypes?.filter(item =>
                                                appState?.gameWallet?.some(data => data.type.includes(item.code))
                                              ).map(item => {
                                                const entries = appState?.gameWallet?.filter(data => data.type.includes(item.code));
                                                const getTypeName = GAME_MENU_DATA[item?.code] ?? '';
                                                const totalBalance = entries.reduce((sum, data) => sum + parseFloat(data.balance), 0);
                                                return entries.length > 0 ? (
                                                  <Accordion.Item eventKey={`#${item?.code}`} id={item?.code} key={item?.code}>
                                                    <Accordion.Header>
                                                      <div>{t(getTypeName?.name)}</div>
                                                      <div>{formatAmount(totalBalance ?? 0, appState.currencyFormat.decimal_point)}</div>
                                                      <div><span className="gameWallet_accordion_icon"><FaChevronDown /></span></div>
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                      <div className="game_wallet_dropdown_body">
                                                        <table>
                                                          <thead>
                                                            <tr>
                                                              <th></th>
                                                              <th></th>
                                                              <th>{t('transfer')}</th>
                                                              <th>{t('all_in')}</th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            {entries.map(data => (
                                                              <tr key={data.id}>
                                                                <td>{data.name}</td>
                                                                <td>{formatAmount(data.balance ?? 0, appState.currencyFormat.decimal_point)}</td>
                                                                <td> <Link to={`/wallet/transfer?from=${data.code}`}><img src={`${TENANT_BUCKET}/icon/transfer_out_icon.png`} alt="" /></Link></td>
                                                                <td> <img src={`${TENANT_BUCKET}/icon/game_wallet_icon.png`} alt="" onClick={() => allInClick(data.code)} /></td>
                                                              </tr>
                                                            ))}
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </Accordion.Body>
                                                  </Accordion.Item>
                                                ) : null;
                                              })}
                                            </SimpleBar>
                                          </Accordion>

                                        </div>
                                      </div>
                                      <OverlayTrigger
                                        key={'promo_coupon_claim_bottom'}
                                        placement={'bottom'}
                                        overlay={
                                          <Popover>
                                            <Popover.Body className="icon_name">
                                              {t(`promo_coupon_claim`)}
                                            </Popover.Body>
                                          </Popover>
                                        }
                                      >
                                        <div className="header_top_icon">
                                          {/* <img src={`${TENANT_BUCKET}/icon/promo_claim_icon.png`} alt="" onClick={() => setPromoClaimShow(true)} /> */}
                                          <div className="btn_claim" onClick={() => setPromoClaimShow(true)}>
                                            <img src={`${TENANT_BUCKET}/icon/promo_claim_icon.png`} alt="" />
                                            {/* <ul className="claim_dropdown">
                                            <li onClick={() => setPromoClaimShow(true)}>
                                              <div style={{ '--wallet-icon': `url(${`${TENANT_BUCKET}/icon/claim_icon.png`})`, '--active-wallet-icon': `url(${`${TENANT_BUCKET}/icon/claim_icon_active.png`})` }} className={`header_WalletIcon`} ></div>
                                              <div><span>{t('promo_coupon_claim')}</span></div>
                                            </li>
                                            <NavLink to={`/coupon`} aria-disabled="true">
                                              {({ isActive }) => (
                                                <li >
                                                  <div style={{ '--wallet-icon': `url(${`${TENANT_BUCKET}/icon/coupon_icon.png`})`, '--active-wallet-icon': `url(${`${TENANT_BUCKET}/icon/coupon_icon_active.png`})` }} className={`header_WalletIcon ${isActive ? 'active ' : ''}`}></div>
                                                  <div><span className={`${isActive ? 'active ' : ''}`}>{t('coupon')}</span></div>
                                                </li>
                                              )}
                                            </NavLink>
                                          </ul> */}
                                          </div>
                                        </div>
                                      </OverlayTrigger>
                                      {/* </OverlayTrigger> */}
                                      <OverlayTrigger
                                        key={'notification_bottom'}
                                        placement={'bottom'}
                                        overlay={
                                          <Popover>
                                            <Popover.Body className="icon_name">
                                              {t(`notification`)}
                                            </Popover.Body>
                                          </Popover>
                                        }
                                      >
                                        <div className="header_noti header_top_icon">
                                          <Link to="/wallet/notification">
                                            <img src={`${TENANT_BUCKET}/icon/header_notification.png`} alt="" />
                                            {appState.unreadNotiCount > 0 && (
                                              <span className="noti_dot"></span>
                                            )}
                                          </Link>
                                        </div>
                                      </OverlayTrigger>
                                      <OverlayTrigger
                                        key={'user_info_bottom'}
                                        placement={'bottom'}
                                        overlay={
                                          <Popover>
                                            <Popover.Body className="icon_name">
                                              {t(`my_account`)}
                                            </Popover.Body>
                                          </Popover>
                                        }
                                      >

                                        <Link to="/wallet/user_info" className="header_top_icon">
                                          <img
                                            src={`${TENANT_BUCKET}/icon/header_user.png`}
                                            alt=""
                                          />
                                        </Link>
                                      </OverlayTrigger>
                                      ︙
                                    </div>



                                    {/* <button className="font_button color_button header_btnRestore " >
                                        {t("restore")}
                                      </button>
                                      <button className="font_button color_button header_btnPromoClaim " >
                                        {t("promo_claim")}
                                      </button> */}
                                    <div className="header_bankinginfo_actionbtn">
                                    </div>

                                  </div>
                                </div>
                                <div className="header_bottom_row">
                                  <div className="header_balance coupon_balance header_coupon" onClick={onClickCouponPage}>
                                    <div className="header_balance_currency"><span>{t('coupon')}</span>  <span>{appState.user?.currency ?? appState.currency}</span></div>
                                    <div className="header_balance_amount">{!showCouponBalance ? formatAmount(appState.couponAmount ?? 0, appState.currencyFormat.decimal_point) : '**********'}</div>
                                    {appState.couponActiveCount > 0 && (
                                      <span className="noti_dot">{appState.couponActiveCount}</span>
                                    )}

                                  </div>
                                  <div className="header_balance_icon">
                                    {/* <FaRedo onClick={() => onClickRefreshBalance()} /> */}
                                    {!showCouponBalance ? <FaEye onClick={() => setShowCouponBalance(!showCouponBalance)} /> : <FaEyeSlash onClick={() => setShowCouponBalance(!showCouponBalance)} />}
                                  </div>
                                  <div className="btn_banking">
                                    <button className="font_button color_button header_btnBanking"> {t('deposit')} </button>
                                    <ul className="banking_dropdown">
                                      {
                                        WALLET_MENU_DATA.funds.map((item, index) => {
                                          return (
                                            <NavLink to={`${item.url}`} key={index} >
                                              {({ isActive }) => (
                                                <li key={item.id}>
                                                  <div style={{ '--wallet-icon': `url(${item.icon})`, '--active-wallet-icon': `url(${item.selectedIcon})` }} className={`header_WalletIcon ${isActive ? 'active ' : ''}`}></div>
                                                  <div><span className={`${isActive ? 'active ' : ''}`}>{t(item.mobileName)}</span></div>
                                                </li>

                                              )}

                                            </NavLink>
                                          )
                                        })
                                      }
                                    </ul>
                                  </div>

                                  <Link to="/"><button className="font_button color_button header_btnLogout" onClick={onSubmitLogout}> {t("logout")}</button></Link>
                                  <div className="language_header">
                                    {language.length > 0 && (
                                      <>
                                        <div id="dropdown-languagemenu">
                                          <img src={`${GLOBAL_BUCKET}/language/` + i18n.resolvedLanguage + `.png`} alt="language flag" />
                                          <FiChevronDown />
                                        </div>
                                        <div className="language_dropdown">
                                          {language.length > 0 && (language?.map((items) => (
                                            <div key={items.code} style={{ fontWeight: i18n.resolvedLanguage === items.code ? 'bold' : 'normal' }} className="font_h5 header_menu_content dropdown-item header_menu_dropdownItem " type="submit" onClick={() => {
                                              onChangeLanguage(items.code);
                                            }}>
                                              <img src={`${GLOBAL_BUCKET}/language/` + items.code + `.png`} alt="language flag" className="me-2" />
                                              {items.name}
                                            </div>
                                          )))}
                                        </div>
                                      </>
                                    )}
                                  </div>

                                  {/* <div className=" font_h5 header_banking_menu ">

                                    <div className=" header_banking_menu_list">
                                      {
                                        WALLET_MENU_DATA.banking.map((item, index) => {
                                          return (
                                            <div key={index}>
                                              <Link to={`${item.url}`} key={index}>
                                                {t(item.mobileName)}
                                              </Link>︙
                                            </div>
                                          )
                                        })
                                      }
                                    </div>
                                    <button className="font_button color_button header_btnLogout" onClick={onSubmitLogout}>
                                      {t("logout")}
                                    </button>
                                    <div className="header_noti">
                                      ︙
                                      <Link to="/wallet/user_info">
                                        <img
                                          src={`${TENANT_BUCKET}/icon/header_user.png`}
                                          alt=""
                                        />
                                      </Link>
                                    </div>
                                  </div> */}
                                </div>
                                {/* ----------  After Login End ----------  */}

                              </>
                              : <>
                                {/* ----------  Before Login Start ----------  */}
                                {/* <div className="header_top_row">
                                  {timeToggle &&
                                    <div className="pb-1">
                                      {(times.map((time) => {
                                        return (<Timer key={time} time={time} />)
                                      }))}

                                    </div>}
                                </div> */}
                                <div className="header_bottom_row">
                                  <Link to="/login"> <button className="font_button color_button header_btnLogin"> {t('login')} </button></Link>
                                  <Link to="/register"><button className="font_button color_button header_btnRegister">{t('join_now')}</button></Link>
                                  {language.length > 0 && (
                                    <div className="language_header">
                                      <div id="dropdown-languagemenu">
                                        <img src={`${GLOBAL_BUCKET}/language/` + i18n.resolvedLanguage + `.png`} alt="language flag" />
                                        <FiChevronDown />
                                      </div>
                                      <div className="language_dropdown">
                                        {language.length > 0 && (language?.map((items) => (
                                          <div key={items.code} style={{ fontWeight: i18n.resolvedLanguage === items.code ? 'bold' : 'normal' }} className="font_h5 header_menu_content dropdown-item header_menu_dropdownItem " type="submit" onClick={() => {
                                            onChangeLanguage(items.code);
                                          }}>
                                            <img src={`${GLOBAL_BUCKET}/language/` + items.code + `.png`} alt="language flag" className="me-2" />
                                            {items.name}
                                          </div>
                                        )))}
                                      </div>
                                    </div>

                                  )}
                                </div>
                                {/* ----------  Before Login End ----------  */}</>}
                          </div>

                        </div>
                      </div>
                      {/* ----------  Header Top Bar (Desktop) End ----------  */}

                      {/* ----------  Header SideBar - Menu (Mobile) Start ----------  */}
                      <div className="d-lg-none">
                        <div className="header_sideBar_menu ">
                          {
                            appState.menu.map((item, index) => {
                              item = HEADER_MENU_DATA[item] ?? ""
                              // HELP ALREADY HARCODE IN MOBILE SIDEBAR
                              if (item?.name !== 'help') {
                                return (
                                  <div key={index}>
                                    <HeaderMenu
                                      name={item.name}
                                      icon={item.icon_m}
                                      selectedIcon={item.selectedIcon_m}
                                      gameType={item.gameType ?? false}
                                      gameProviders={filterGameProvider(item.gameType) ?? []}
                                      url={item.url}
                                      navIconToggle={navIconMobileToggle}
                                      menuIconPosition={menuIconPosition}
                                      mobileShow={item.mobileShow}
                                    />
                                  </div>
                                )
                              } return null;
                            }).filter(Boolean)
                          }
                          {!isDesktopOrLaptop &&
                            <>
                              {/* {language.length && (
                                <div className="header_menu_page">
                                  <details>
                                    <summary className="header_menu_content">
                                      {navIconToggle && <div className="header_menu_icon">
                                        <img src={`${TENANT_BUCKET}/icon/menu_language.png`} alt="header icon" />
                                      </div>}
                                      <div className="font_h5  header_text">{t('language')}</div>
                                    </summary>
                                    <div className="sidebar_language_selection">
                                      {language?.map((item) => (
                                        <p key={item.code} style={{ fontWeight: i18n.resolvedLanguage === item.code ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(item.code)}>
                                          {item.name}
                                        </p>))}
                                    </div>
                                  </details>
                                  
                                </div>)} */}

                              {appState.loggedIn && <div className="header_menu_page">
                                <Link to={`/coupon`} className={`header_menu_content ${location.pathname === '/coupon' ? 'color-active active' : ''}`}>
                                  {navIconMobileToggle &&
                                    <div style={{ '--menu-icon': `url(${`${TENANT_BUCKET}/icon/sideBar_menu_coupon.png`})`, '--active-menu-icon': `url(${`${TENANT_BUCKET}/icon/sideBar_menu_coupon_active.png`})` }} className={`header_menu_icon`} ></div>
                                  }
                                  <div className=" header_text">{t('coupon')}</div>
                                </Link>
                              </div>}

                              {/* {appState.loggedIn && <div className="header_menu_page coming_soon">
                                <div to={`/coupon`} className="header_menu_content">
                                  {navIconToggle && <div className="header_menu_icon">
                                    <img src={`${TENANT_BUCKET}/icon/menu_coupon.png`} alt="header icon" />
                                  </div>}
                                  <div className=" header_text">{t('coupon')}</div>
                                </div>
                              </div>} */}

                              <div className="header_menu_page">

                                <Link to={`/help`} className={`header_menu_content ${location.pathname === '/help' ? 'color-active active' : ''}`}>
                                  {/* {navIconMobileToggle && <div className="header_menu_icon">
                                    <img src={`${TENANT_BUCKET}/icon/menu_help.png`} alt="header icon" />
                                  </div>} */}
                                  {navIconMobileToggle &&
                                    <div style={{ '--menu-icon': `url(${`${TENANT_BUCKET}/icon/sideBar_menu_help.png`})`, '--active-menu-icon': `url(${`${TENANT_BUCKET}/icon/sideBar_menu_help_active.png`})` }} className={`header_menu_icon`} ></div>
                                  }
                                  <div className=" header_text">{t('help')}</div>
                                </Link>
                              </div>
                              <div className="header_menu_page">

                                <Link to={`/contact-us`} className={`header_menu_content ${location.pathname === '/contact-us' ? 'color-active active' : ''}`}>
                                  {navIconMobileToggle &&
                                    <div style={{ '--menu-icon': `url(${`${TENANT_BUCKET}/icon/sideBar_menu_contactUs.png`})`, '--active-menu-icon': `url(${`${TENANT_BUCKET}/icon/sideBar_menu_contactUs_active.png`})` }} className={`header_menu_icon`} ></div>
                                  }
                                  <div className=" header_text">{t('contact_us')}</div>
                                </Link>
                              </div>
                              {
                                appState.loggedIn &&
                                <div className="header_menu_page">
                                  <Link className="header_menu_content" onClick={onSubmitLogout}>
                                    {navIconMobileToggle &&
                                      <div style={{ '--menu-icon': `url(${`${TENANT_BUCKET}/icon/sideBar_menu_logout.png`})`, '--active-menu-icon': `url(${`${TENANT_BUCKET}/icon/sideBar_menu_logout_active.png`})` }} className={`header_menu_icon`} ></div>
                                    }
                                    <div className=" header_text">{t('logout')}</div>
                                  </Link>

                                </div>
                              }
                            </>}
                          {/* <HeaderMenu
                            name='language'
                            icon={`${TENANT_BUCKET}/icon/menu_language.png`}
                            selectedIcon={`${TENANT_BUCKET}/icon/menu_language_active.png`}
                            navIconToggle={navIconToggle}
                            menuIconPosition={menuIconPosition}
                          />
                          <HeaderMenu
                            name='logout'
                            icon={`${TENANT_BUCKET}/icon/menu_logout.png`}
                            selectedIcon={`${TENANT_BUCKET}/icon/menu_logout_active.png`}
                            navIconToggle={navIconToggle}
                            menuIconPosition={menuIconPosition}
                          /> */}
                        </div>
                      </div>
                      {/* ----------  Header SideBar - Menu (Mobile) End ----------  */}
                    </Nav>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar >
          ))}
          {/* ----------  BankingInfo Wallet (Mobile)  Start ----------  */}
          {
            (!isDesktopOrLaptop && appState.loggedIn && !isProfilePage) ?
              <div className={`color_primary header_banking_info ${appState.enableLoyalty ? 'enableLoyalty' : ''}`}>
                <div className="container header_banking_info_container">
                  <div className="header_banking_info_top"> <div className="header_banking_info_left">
                    <div className="header_balance_wrap">
                      <div className="header_balance">
                        <div className="header_balance_currency"><span>{t('main_wallet')}</span> <span>{appState.user?.currency}</span></div>
                        <div className="font_h2 header_balance_amount">
                          <span>{!showBalance ? formatAmount(appState.user?.wallet_amount ?? 0, appState.currencyFormat.decimal_point) : '**********'}</span>
                          <div className="font_h2 header_balance_icon">
                            {!showBalance ? <FaEye onClick={() => setShowBalance(!showBalance)} /> : <FaEyeSlash onClick={() => setShowBalance(!showBalance)} />}
                          </div>
                        </div>

                      </div>
                      <div className="header_balance">
                        <div className="header_balance_currency"><span>{t('coupon')}</span>  <span>{appState.user?.currency}</span></div>
                        <div className="font_h2 header_balance_amount coupon_balance">
                          <span>{!showCouponBalance ? formatAmount(appState.couponAmount ?? 0, appState.currencyFormat.decimal_point) : '**********'}</span>
                          <div className="font_h2 header_balance_icon">
                            {!showCouponBalance ? <FaEye onClick={() => setShowCouponBalance(!showCouponBalance)} /> : <FaEyeSlash onClick={() => setShowCouponBalance(!showCouponBalance)} />}
                          </div>
                        </div>
                      </div>

                    </div>


                  </div>
                    <div className="header_banking_info_right">
                      <div className="header_banking_menu_list">
                        {
                          WALLET_MENU_DATA.funds.map((item, index) => {
                            return (
                              <NavLink to={`${item.url}`} key={index}>
                                {({ isActive }) => (
                                  <>
                                    <img src={isActive ? item.selectedIcon : item.icon} alt="walletbar icon" />
                                    <span className={`${isActive ? 'active' : ''}`}>{t(item.mobileName)}</span>
                                  </>
                                )}

                              </NavLink>
                            )
                          })
                        }

                      </div>
                    </div></div>
                  <div className="header_banking_info_bottom">         <div className="header_bankinginfo_actionbtn">
                    <button className="font_button color_button header_btnRestore color_primary" onClick={() => onClickRestore()}>
                      <img src={`${TENANT_BUCKET}/icon/restore_icon.png`} alt="" />
                      <div>{t("restore")}</div>
                    </button>
                    <button className="font_button color_button header_btnPromoClaim color_primary header_coupon" onClick={onClickCouponPage}>
                      <img src={`${TENANT_BUCKET}/icon/coupon_icon_m.png`} alt="" />
                      <div> {t("coupon")}</div>
                      {appState.couponActiveCount > 0 && (
                        <span className="noti_dot">{appState.couponActiveCount}</span>
                      )}
                    </button>
                    <div className="font_h2 header_balance_icon ms-2">
                      <img src={`${TENANT_BUCKET}/icon/game_wallet_icon.png`} alt="" onClick={() => setModalShow(true)} />
                    </div>
                  </div></div>
                  {appState.enableLoyalty && (<div className="header_point">
                    <img src={`${TENANT_BUCKET}/icon/point_icon.png`} alt="header icon" />
                    <div className="mt-1"><span>{TENANT_NAME} {t('point')} &nbsp; :</span><span>{formatAmount(appState.user?.reward_points ?? 0, appState.currencyFormat.decimal_point)} {t('point')}</span></div>
                    <BsFillQuestionCircleFill onClick={onClickPointAlert} />
                  </div>)}
                </div> </div> :
              <></>
          }
          {/* ----------  BankingInfo Wallet (Mobile)  End ----------  */}

          {/* ----------  New Row Header Menu (Desktop) Start ----------  */}
          {(isDesktopOrLaptop && menuInNewRow) && (< div className="header_menu">
            <Container className="header_menu_container" style={{ justifyContent: `${headerMenuPosition ?? 'start'}` }}>
              {appState.menu.map((item, index) => {
                item = HEADER_MENU_DATA[item] ?? ""
                return (
                  <div className="header_menu_page" key={index} style={{ minWidth: menuIconPosition === 'vertical' ? '6rem' : '' }}>
                    <HeaderMenu
                      name={item.name}
                      icon={item.icon}
                      selectedIcon={item.selectedIcon}
                      gameType={item.gameType ?? false}
                      gameProviders={filterGameProvider(item.gameType) ?? []}
                      url={item.url}
                      navIconToggle={navIconDesktopToggle}
                      menuIconPosition={menuIconPosition}
                      dropdownImageSize={dropdownImageSize}
                      dropdownGameProviderNameToggle={dropdownGameProviderNameToggle}
                      enableDropdownHoverImage={enableDropdownHoverImage}
                    />
                  </div>
                )
              })
              }


            </Container>
          </div>)}

          {/* ----------  New Row Header Menu (Desktop) End ----------  */}
        </header>

        {
          defaultLiveChat === 'LIVECHAT' ? (< LiveChatWidget
            license={livechatID.licenseId}
            // visibility="maximized"
            onChatLoaded={() => window.LC_API.minimize_chat_window()}
            // visibility={chatVisibility}
            customerName={typeof appState.user?.username !== 'undefined' ? appState.user?.username : 'Visitor'}
          />) : (defaultLiveChat === 'TAWK_TO' ? <TawkMessengerReact
            propertyId={livechatID.propertyId}
            widgetId={livechatID.widgetId}
            ref={tawkMessengerRef}
            onLoad={() => {
              if (tawkMessengerRef.current) {
                tawkMessengerRef.current.minimize();
              }
            }}
          /> : <></>)
        }

        <Announcement show={announcementShow} onHide={() => (setAnnouncementShow(false))} annLogoToggle={annLogoToggle} annHeaderToggle={annHeaderToggle} />
        {isLoading && (<Loading />)}
        <PromoClaimDialog
          show={promoClaimShow}
          onHide={() => setPromoClaimShow(false)}
        />
        {
          !isDesktopOrLaptop && (<HeaderGameWallet
            show={modalShow}
            onHide={() => setModalShow(false)}
            modaltitle={t('game_wallet')}
            gamewallet={appState.gameWallet}
          />)
        }
        {
          !isDesktopOrLaptop && (<Modal show={languageShow} onHide={() => setLanguageShow(false)} centered id="languageModal">
            <Modal.Header closeButton>
              <Modal.Title>{t('language')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="language_content">
                {language.length > 0 && (language?.map((items) => (
                  <div key={items.code} style={{ fontWeight: i18n.resolvedLanguage === items.code ? 'bold' : 'normal' }} className="font_h3 header_menu_content dropdown-item header_menu_dropdownItem " type="submit" onClick={() => {

                    onChangeLanguage(items.code); setLanguageShow(false)
                  }}>
                    <img src={`${GLOBAL_BUCKET}/language/` + items.code + `.png`} alt="language flag" className="me-3" />
                    {items.name}
                  </div>
                )))}
              </div>
            </Modal.Body>
          </Modal>)
        }
        {/* <Alert show={alertShow.show} onHide={onHideAlert} message={alertShow.message} type={alertShow.type} title={alertShow.title} /> */}
        <Modal show={loginMultipleShow} onHide={() => setLoginMultipleShow(false)} centered id="multipleLoginDialog">
          <Modal.Header>
            <Modal.Title>{t('multiple_login_title')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{t('multiple_login_content')}</p>
          </Modal.Body>
          <Modal.Footer>
            <button className="cancel_button" onClick={() => setLoginMultipleShow(false)}>
              {t('cancel')}
            </button>
            <button className="confirm_button" onClick={() => {
              setValue('force', true)
              onSubmitLogin();
              setLoginMultipleShow(false);
            }}>
              {t('confirm')}
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  else {

  }
}

export default Header;
