
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Container, Tab, Tabs } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { AiOutlineRight } from "react-icons/ai";
import { TENANT_BUCKET } from '../../common/constants';
import { useStoreState } from '../../common/storeContext'
import { useMiddletier } from "../../common/middletier";

import "./template_01.css";

// const rankingDataDeposit = [
//   {username: 'User***001', amount: 7000},
//   {username: 'Abc***Xyz', amount: 6800},
//   {username: 'Foo***Bar', amount: 5644},
//   {username: 'Baz***Qux', amount: 4500},
//   {username: 'Qwe***Rty', amount: 4400},
//   {username: 'Asd***Fgh', amount: 4100},
//   {username: 'Zxc***Vbn', amount: 3750},
//   {username: 'Mno***Pqr', amount: 2750},
//   {username: 'Lkj***Hgf', amount: 1450},
//   {username: 'Alp***Bet', amount: 750}]

// const rankingDataWithdraw = [
//   { username: 'Gam***Del', amount: 10150 },
//   { username: 'Eps***Zet', amount: 6800 },
//   { username: 'Eta***The', amount: 4400 },
//   { username: 'Iot***Kap', amount: 4100 },
//   { username: 'Lam***Mu', amount: 2850 },
//   { username: 'Nun***Xi', amount: 2750 },
//   { username: 'Omi***Kro', amount: 928 },
//   { username: 'Pi***Rho', amount: 759 },
//   { username: 'Sig***Tau', amount: 528 },
//   { username: 'Ups***Phi', amount: 459 }]


const RankingList01 = (props) => {
  const appState = useStoreState()
  const { t } = useTranslation();
  const { queries } = useMiddletier();

  const { desktopToggle, mobileToggle, desktopBannerToggle, mobileBannerToggle, badgeToggle, userAvatarToggle, row, titleIconToggle,uniqueAvatarsToggle } = props;

  const [rankingDeposit, setRankingDeposit] = useState([])
  const [rankingWithdraw, setRankingWithdraw] = useState([])

  // console.log('now currency:',appState)

  // const rankingDataDeposit = [
  //   { username: 'm******6', amount: appState?.currency === 'IDR' ? 461095000 : (appState?.currency === 'HKD' ? 7000 : 7000) },
  //   { username: 'A******0', amount: appState?.currency === 'IDR' ? 325294000 : (appState?.currency === 'HKD' ? 6800 : 6800) },
  //   { username: 'n******8', amount: appState?.currency === 'IDR' ? 320589000 : (appState?.currency === 'HKD' ? 5644 : 5644) },
  //   { username: 'n******G', amount: appState?.currency === 'IDR' ? 261846000 : (appState?.currency === 'HKD' ? 4500 : 4500) },
  //   { username: 'S******v', amount: appState?.currency === 'IDR' ? 165701000 : (appState?.currency === 'HKD' ? 4400 : 4400) },
  //   { username: 'Asd***Fgh', amount: appState?.currency === 'IDR' ? 420100 : 4100 },
  //   { username: 'Zxc***Vbn', amount: appState?.currency === 'IDR' ? 320100 : 3750 },
  //   { username: 'Mno***Pqr', amount: appState?.currency === 'IDR' ? 310100 : 2750 },
  //   { username: 'Lkj***Hgf', amount: appState?.currency === 'IDR' ? 220100 : 1450 },
  //   { username: 'Alp***Bet', amount: appState?.currency === 'IDR' ? 200100 : 700 }]

  // const rankingDataWithdraw = [
  //   { username: 'y******s', amount: appState?.currency === 'IDR' ? 1268408000 : (appState?.currency === 'HKD' ? 6800 : 6800) },
  //   { username: 'j******Y', amount: appState?.currency === 'IDR' ? 1134493000 : (appState?.currency === 'HKD' ? 4400 : 4400) },
  //   { username: 'B******0', amount: appState?.currency === 'IDR' ? 980733000 : (appState?.currency === 'HKD' ? 4100 : 4100) },
  //   { username: 'b******L', amount: appState?.currency === 'IDR' ? 529693000 : (appState?.currency === 'HKD' ? 2850 : 2850) },
  //   { username: 'x******4', amount: appState?.currency === 'IDR' ? 522143000 : (appState?.currency === 'HKD' ? 2750 : 2750) },
  //   { username: 'Omi***Kro', amount: appState?.currency === 'IDR' ? 500500 : 928 },
  //   { username: 'Pi***Rho', amount: appState?.currency === 'IDR' ? 400500 : 759 },
  //   { username: 'Sig***Tau', amount: appState?.currency === 'IDR' ? 300500 : 528 },
  //   {
  //     username: 'Ups***Phi', amount: appState?.currency === 'IDR' ? 200500 : 459
  //   }]

  // const formatAmount = (amount, decimalPoint) => {
  //   const factor = Math.pow(10, decimalPoint);
  //   const truncatedAmount = Math.floor(amount * factor) / factor;
  //   const formattedAmount = truncatedAmount.toLocaleString(appState.currencyFormat?.locale, {
  //     minimumFractionDigits: decimalPoint,
  //     maximumFractionDigits: decimalPoint,
  //   });
  //   return formattedAmount;
  // }

  const formatAmount = (amount, decimalPoint) => {
    let strAmount = Number(amount).toString();
    let dotIndex = strAmount.indexOf('.');

    if (dotIndex === -1) {
      strAmount += '.' + '0'.repeat(decimalPoint);
    } else {
      const actualDecimals = strAmount.length - dotIndex - 1;
      if (actualDecimals > decimalPoint) {
        strAmount = strAmount.substring(0, dotIndex + decimalPoint + 1);
      } else if (actualDecimals < decimalPoint) {
        strAmount += '0'.repeat(decimalPoint - actualDecimals);
      }
    }

    const formattedAmount = parseFloat(strAmount).toLocaleString(appState.currencyFormat?.locale, {
      minimumFractionDigits: decimalPoint,
      maximumFractionDigits: decimalPoint,
    });

    return formattedAmount;
  };

  useEffect(() => {
    const attributes = []
    queries([{
      index: 'getRankingDeposit',
      method: 'getRanking',
      params: [{ code: 'filter', graphqlType: 'JSON', required: true, value: { where: { status: 'ACTIVE', category: 'DEPOSIT' }, order: [['amount', 'DESC']], limit: row } },],
      attributes
    },
    {
      index: 'getRankingWithdraw',
      method: 'getRanking',
      params: [{ code: 'filter', graphqlType: 'JSON', required: true, value: { where: { status: 'ACTIVE', category: 'WITHDRAW' }, order: [['amount', 'DESC']], limit: row } },],
      attributes
    }])
      .then(({ data }) => {


        // const getRankingDepositData = data['getRankingDeposit'].map((item) => {
        //   return { ...item, username: `${item.username[0]}${'*'.repeat(item.username.length - 2)}${item.username[item.username.length - 1]}` };
        // })

        // const getRankingWithdrawData = data['getRankingWithdraw'].map((item) => {
        //   return { ...item, username: `${item.username[0]}${'*'.repeat(item.username.length - 2)}${item.username[item.username.length - 1]}` };
        // })

        setRankingDeposit(data['getRankingDeposit'])
        setRankingWithdraw(data['getRankingWithdraw'])


      }).catch((error) => {
        console.log('error: ', error)
      })

  }, [queries, row]);


  if ((desktopToggle || mobileToggle) === true) {

    return (
      <section className={`section ${!desktopToggle && "d-lg-none"} ${!mobileToggle && "d-none d-lg-block"}`} id="ranking_01">
        <div className='rankingList_body'>
          <Container>
            <div className={`section_header ${desktopToggle ? "" : "d-lg-none"} ${mobileToggle ? "" : "d-none d-lg-block"}`}>
              <div className="font_h2 section_title">
                {titleIconToggle && (<img src={`${TENANT_BUCKET}/icon/ranking_title_icon.png`} alt="" />)}
                {appState?.transactionRankingData === 'LIVE' ?
                  (appState?.transactionRankingFrequency === 'ALL' ?
                    t('ranking_list_title') : t(`${appState?.transactionRankingFrequency?.toLowerCase()}_ranking_list_title`)) :
                  t('ranking_list_title')}
              </div>
            </div>

            <div className='rankingList_table_container'>
              <Tabs defaultActiveKey="top_deposit" id="rankingList_tableTab" className="color_text rankingList_tableTab" fill>
                <Tab eventKey="top_deposit" title={t('top_deposit')} tabClassName="rankingList_table_selection">
                  <div className="color_primary rankingList_table">
                    <div className="rankingList_table_body">
                      {mobileBannerToggle && <img src={`${TENANT_BUCKET}/icon/ranking_topDeposit_banner.jpg`} alt="ranking top deposit banner" />}
                      {Object.values(rankingDeposit ?? "").slice(0, row).map((item, index) => (
                        <div className="rankingList_table_row" key={index}>
                          {/* <div key={index + 1} className="font_h2 rankingList_user">
                              {badgeToggle && <img src={`${TENANT_BUCKET}/icon/ranking_badge_` + (index + 1) + `.png`} alt="ranking user" />}
                                             {userAvatarToggle && <img src={`${TENANT_BUCKET}/icon/ranking_user${uniqueAvatarsToggle?((index + 1) >= 10 ? (index + 1) : `0${index + 1}`):''}.png`} alt="ranking user" />}
                            </div>
                            <div className="font_h4 rankingList_username_col">
                              <div className="rankingList_username"> User*** {index + 1}</div>
                              <div className="rankingList_info">{t('has_deposited')}</div>
                            </div> */}
                          <div key={index + 1} className="font_h2 rankingList_user">
                            {badgeToggle && <img src={`${TENANT_BUCKET}/icon/ranking_badge_${(index + 1) >= 10 ? (index + 1) : `0${index + 1}`}.png`} alt="ranking badge" />}
                            {userAvatarToggle && <img src={`${TENANT_BUCKET}/icon/ranking_user${uniqueAvatarsToggle ? ((index + 1) >= 10 ? (index + 1) : `_0${index + 1}`) : ''}.png`} alt="ranking user" />}
                            <div className="font_h4 rankingList_username_col">
                              <div className="rankingList_username"> {item.username}</div>
                              <div className="rankingList_info">{t('has_deposited')}</div>
                            </div>
                          </div>
                          <div className="font_h4 rankingList_amount">{appState.currency} {formatAmount(item.amount ?? 0, appState.currencyFormat.decimal_point)}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="top_withdrawal" title={t('top_withdraw')} tabClassName="rankingList_table_selection">
                  <div className="color_primary rankingList_table">
                    <div className="rankingList_table_body">
                      {mobileBannerToggle && <img src={`${TENANT_BUCKET}/icon/ranking_topWithdrawal_banner.jpg`} alt="ranking top withdrawal banner" />}
                      {Object.values(rankingWithdraw ?? "").slice(0, row).map((item, index) => (
                        <div className="rankingList_table_row" key={index}>
                          <div key={index + 1} className="font_h2 rankingList_user">
                            {badgeToggle && <img src={`${TENANT_BUCKET}/icon/ranking_badge_${(index + 1) >= 10 ? (index + 1) : `0${index + 1}`}.png`} alt="ranking badge" />}
                            {userAvatarToggle && <img src={`${TENANT_BUCKET}/icon/ranking_user${uniqueAvatarsToggle ? ((index + 1) >= 10 ? (index + 1) : `_0${index + 1}`) : ''}.png`} alt="ranking user" />}
                            <div className="font_h4 rankingList_username_col">
                              <div className="rankingList_username"> {item.username}</div>
                              <div className="rankingList_info">{t('has_won')}</div>
                            </div>
                          </div>
                          <div className="font_h4 rankingList_amount">{appState.currency} {formatAmount(item.amount ?? 0, appState.currencyFormat.decimal_point)}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>

          </Container>
        </div>
      </section>
    );
  }
  else {

  }

}

export default RankingList01;