import md5 from 'crypto-js/md5';
import dayjs from "dayjs";
import { customAlphabet } from 'nanoid';
import React, { useEffect, useReducer, useRef, useState } from "react";
import { Col, Form, Modal, Nav, Row } from "react-bootstrap";
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { AiFillCopy, AiOutlineCheckCircle, AiOutlinePlus } from "react-icons/ai";
import { FaInfoCircle } from "react-icons/fa";
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from "react-router-dom";
// import fetch from "node-fetch";

import { DEPOSIT_MENU_DATA, GLOBAL_BUCKET, TENANT_BUCKET, TENANT_NAME, GAME_MENU_DATA } from '../../../../common/constants';
import { useMiddletier } from "../../../../common/middletier";
import { APP_STORE_ACTION, useStoreDispatch, useStoreState } from '../../../../common/storeContext';
import Loading from '../../../../components/Loading';
import ProviderSelector from "../../../../components/ProviderSelector";


const nanoid = customAlphabet('ABCDEFGHIJKLMNOPQRSTUWXYZ', 4)

const DEPOSIT_ACTION = {
  SET_DEPOSIT_MENU: 'SET_DEPOSIT_MENU',
  SET_SELECTED_DEPOSIT_MENU: 'SET_SELECTED_DEPOSIT_MENU',
}

const QUICK_PAY_ACTION = {
  SET_PAYMENT_PROVIDERS: 'SET_PAYMENT_PROVIDERS',
  SET_SELECTED_PAYMENT_PROVIDER: 'SET_SELECTED_PAYMENT_PROVIDER',
  SET_BANKS: 'SET_BANKS',
  SET_SELECTED_BANK: 'SET_SELECTED_BANK',
  SET_DEPOSIT_AMOUNT: 'SET_DEPOSIT_AMOUNT',
  SET_SELECTED_CURRENCY: 'SET_SELECTED_CURRENCY',
  SET_SELECTED_DEPOSIT_METHOD: 'SET_SELECTED_DEPOSIT_METHOD',
  SET_GAME_PROVIDERS: 'SET_GAME_PROVIDERS',
  SET_PROMOTIONS: 'SET_PROMOTIONS',
  SET_SELECTED_PROMOTION: 'SET_SELECTED_PROMOTION',
  SET_PROMOTION_CODE: 'SET_PROMOTION_CODE',
  SET_SELECTED_TRANSFER_TO_GAME_PROVIDER: 'SET_SELECTED_TRANSFER_TO_GAME_PROVIDER'
}

const BANK_TRANSFER_ACTION = {
  SET_BANK_ACCOUNTS: 'SET_BANK_ACCOUNTS',
  SET_SELECTED_BANK_ACCOUNT: 'SET_SELECTED_BANK_ACCOUNT',
  SET_DEPOSIT_AMOUNT: 'SET_DEPOSIT_AMOUNT',
  SET_SELECTED_DEPOSIT_METHOD: 'SET_SELECTED_DEPOSIT_METHOD',
  SET_SELECTED_DEPOSIT_RECEIPT: 'SET_SELECTED_DEPOSIT_RECEIPT',
  SET_GAME_PROVIDERS: 'SET_GAME_PROVIDERS',
  SET_PROMOTIONS: 'SET_PROMOTIONS',
  SET_SELECTED_PROMOTION: 'SET_SELECTED_PROMOTION',
  SET_PROMOTION_CODE: 'SET_PROMOTION_CODE',
  SET_SELECTED_TRANSFER_TO_GAME_PROVIDER: 'SET_SELECTED_TRANSFER_TO_GAME_PROVIDER'
}

const RELOAD_ACTION = {
  SET_TELCO: 'SET_TELCO',
  SET_SELECTED_TELCO: 'SET_SELECTED_TELCO',
  SET_RELOAD_AMOUNT: 'SET_RELOAD_AMOUNT',
  SET_RELOAD_PIN: 'SET_RELOAD_PIN',
  SET_GAME_PROVIDERS: 'SET_GAME_PROVIDERS',
  SET_PROMOTIONS: 'SET_PROMOTIONS',
  SET_SELECTED_PROMOTION: 'SET_SELECTED_PROMOTION',
  SET_PROMOTION_CODE: 'SET_PROMOTION_CODE',
  SET_SELECTED_TRANSFER_TO_GAME_PROVIDER: 'SET_SELECTED_TRANSFER_TO_GAME_PROVIDER'
}

const QRPAY_ACTION = {
  SET_BANK_ACCOUNTS: 'SET_BANK_ACCOUNTS',
  SET_SELECTED_BANK_ACCOUNT: 'SET_SELECTED_BANK_ACCOUNT',
  SET_DEPOSIT_AMOUNT: 'SET_DEPOSIT_AMOUNT',
  SET_SELECTED_DEPOSIT_METHOD: 'SET_SELECTED_DEPOSIT_METHOD',
  SET_SELECTED_DEPOSIT_RECEIPT: 'SET_SELECTED_DEPOSIT_RECEIPT',
  SET_GAME_PROVIDERS: 'SET_GAME_PROVIDERS',
  SET_PROMOTIONS: 'SET_PROMOTIONS',
  SET_SELECTED_PROMOTION: 'SET_SELECTED_PROMOTION',
  SET_PROMOTION_CODE: 'SET_PROMOTION_CODE',
  SET_SELECTED_TRANSFER_TO_GAME_PROVIDER: 'SET_SELECTED_TRANSFER_TO_GAME_PROVIDER'
}

const initialDepositData = {
  depositMenu: [],
  selectedDepositMenu: 'banktransfer',
}

const initialQuickPayData = {
  paymentProviders: [],
  selectedPaymentProvider: { code: '', name: '', min_deposit: 0, max_deposit: 0, bank_option: false, method_option: true },
  banks: [],
  selectedBank: { code: '', name: '', currency: '', min_deposit: 0, max_deposit: 0 },
  depositAmount: 0,
  currency: ['MYR', 'IDR', 'VND', 'HKD'],
  // selectedCurrency: '',
  depositMethod: [{ id: 'BANK', name: 'depositMethod_directBank' }, { id: 'WALLET', name: 'depositMethod_qrpay' }],
  selectedDepositMethod: { id: 'BANK', name: 'depositMethod_directBank' },
  gameProviders: [],
  selectedTransferToGameProvider: '',
  promotions: [],
  selectedPromotion: { id: '', name: '' },
  promotionCode: ''
}

const initialBankTransferData = {
  bankAccounts: [],
  selectedBankAccount: { id: '', name: '', bank_id: '', qr_image_url: '', _bank: { currency: '', name: '' }, min_deposit: 0, max_deposit: 0 },
  depositAmount: 0,
  depositMethod: [{ id: 'ONLINE', name: 'depositMethod_onlineTransfer' }, { id: 'ATM', name: 'depositMethod_atm' }],
  selectedDepositMethod: { id: 'ONLINE', name: 'depositMethod_onlineTransfer' },
  selectedDepositReceipt: '',
  gameProviders: [],
  selectedTransferToGameProvider: '',
  promotions: [],
  selectedPromotion: { id: '', name: '' },
  promotionCode: ''
}

const initialReloadData = {
  telco: [],
  selectedTelco: { code: '', name: '', currency: '' },
  reloadAmount: 0,
  reloadPin: '',
  gameProviders: [],
  selectedTransferToGameProvider: '',
  promotions: [],
  selectedPromotion: { id: '', name: '' },
  promotionCode: ''
}

const initialQrPayData = {
  bankAccounts: [],
  selectedBankAccount: { id: '', name: '', bank_id: '', qr_image_url: '', _bank: { currency: '', name: '' }, min_deposit: 0, max_deposit: 0 },
  depositAmount: 0,
  depositMethod: [{ id: 'ONLINE', name: 'depositMethod_onlineTransfer' }, { id: 'QR_CODE', name: 'depositMethod_qrcode' }, { id: 'ATM', name: 'depositMethod_atm' }],
  selectedDepositMethod: { id: 'ONLINE', name: 'depositMethod_onlineTransfer' },
  selectedDepositReceipt: '',
  gameProviders: [],
  selectedTransferToGameProvider: '',
  promotions: [],
  selectedPromotion: { id: '', name: '' },
  promotionCode: ''
}

const depositReducer = (state, action) => {
  switch (action.type) {

    case DEPOSIT_ACTION.SET_DEPOSIT_MENU: {
      const depositMenu = [...action.payload]
      return { ...state, depositMenu, selectedDepositMenu: depositMenu.length > 0 ? depositMenu[0] : '' };

    }

    case DEPOSIT_ACTION.SET_SELECTED_DEPOSIT_MENU: {
      const selectedDepositMenu = action.payload
      return { ...state, selectedDepositMenu };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
const quickPayReducer = (state, action) => {
  switch (action.type) {

    case QUICK_PAY_ACTION.SET_PAYMENT_PROVIDERS: {
      const paymentProviders = [...action.payload]
      return { ...state, paymentProviders, selectedPaymentProvider: paymentProviders.length > 0 ? { ...paymentProviders[0] } : { id: '', name: '', min_deposit: 0, max_deposit: 0, bank_option: false } };
      // return { ...state, paymentProviders: [{ id: '', name: '' }, ...paymentProviders], selectedPaymentProvider };
    }

    case QUICK_PAY_ACTION.SET_SELECTED_PAYMENT_PROVIDER: {
      const selectedPaymentProvider = state.paymentProviders.find((provider) => provider.code === action.payload)

      return { ...state, selectedPaymentProvider };
    }

    case QUICK_PAY_ACTION.SET_BANKS: {
      const banks = [...action.payload]
      return { ...state, banks, selectedBank: banks.length > 0 ? { ...banks[0] } : { code: '', name: '', currency: '', min_deposit: 0, max_deposit: 0 } };
    }

    case QUICK_PAY_ACTION.SET_SELECTED_BANK: {
      return { ...state, selectedBank: action.payload };
    }

    case QUICK_PAY_ACTION.SET_DEPOSIT_AMOUNT: {
      return { ...state, depositAmount: action.payload };
    }

    // case QUICK_PAY_ACTION.SET_SELECTED_CURRENCY: {
    //   const selectedCurrency = action.payload
    //   return { ...state, selectedCurrency };
    // }

    case QUICK_PAY_ACTION.SET_SELECTED_DEPOSIT_METHOD: {
      const selectedDepositMethod = state.depositMethod.find((depositMethod) => depositMethod.id === action.payload)
      return { ...state, selectedDepositMethod };
    }

    case BANK_TRANSFER_ACTION.SET_GAME_PROVIDERS: {
      const gameProviders = [...action.payload]
      return { ...state, gameProviders };
    }

    case BANK_TRANSFER_ACTION.SET_SELECTED_TRANSFER_TO_GAME_PROVIDER: {
      const selectedTransferToGameProvider = action.payload

      return { ...state, selectedTransferToGameProvider };
    }

    case BANK_TRANSFER_ACTION.SET_PROMOTIONS: {
      const promotions = [...action.payload]
      return { ...state, promotions };
    }

    case BANK_TRANSFER_ACTION.SET_SELECTED_PROMOTION: {
      const selectedPromotion = state.promotions.find((promotions) => promotions.id === action.payload)
      return { ...state, selectedPromotion: selectedPromotion ?? { id: '', name: '' } };
    }

    case BANK_TRANSFER_ACTION.SET_PROMOTION_CODE: {
      return { ...state, promotionCode: action.payload };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const bankTransferReducer = (state, action) => {
  switch (action.type) {

    case BANK_TRANSFER_ACTION.SET_BANK_ACCOUNTS: {
      const bankAccounts = [...action.payload]
      return { ...state, bankAccounts, selectedBankAccount: bankAccounts.length > 0 ? { ...bankAccounts[0] } : { id: '', name: '', bank_id: '', qr_image_url: '', _bank: { currency: '', name: '' }, min_deposit: 0, max_deposit: 0 } };
    }

    case BANK_TRANSFER_ACTION.SET_SELECTED_BANK_ACCOUNT: {
      const selectedBankAccount = state.bankAccounts.find((bankAccounts) => bankAccounts.code === action.payload)
      return { ...state, selectedBankAccount };
    }

    case BANK_TRANSFER_ACTION.SET_DEPOSIT_AMOUNT: {
      return { ...state, depositAmount: action.payload };
    }

    case BANK_TRANSFER_ACTION.SET_DEPOSIT_METHOD: {
      const depositMethod = [...action.payload]
      return { ...state, depositMethod, selectedDepositMethod: depositMethod.length > 0 ? { ...depositMethod[0] } : { id: '', name: '' } };
    }

    case BANK_TRANSFER_ACTION.SET_SELECTED_DEPOSIT_METHOD: {
      const selectedDepositMethod = state.depositMethod.find((depositMethod) => depositMethod.id === action.payload)
      const bankAccounts = state.bankAccounts?.filter(item => selectedDepositMethod?.id !== 'QR_CODE' || (selectedDepositMethod?.id === 'QR_CODE' && item.qr_image_url))
      return { ...state, selectedDepositMethod, selectedBankAccount: bankAccounts.length > 0 ? { ...bankAccounts[0] } : { id: '', name: '', bank_id: '', qr_image_url: '', _bank: { currency: '', name: '' }, min_deposit: 0, max_deposit: 0 } };
    }

    case BANK_TRANSFER_ACTION.SET_SELECTED_DEPOSIT_RECEIPT: {
      return { ...state, selectedDepositReceipt: action.payload };
    }

    case BANK_TRANSFER_ACTION.SET_GAME_PROVIDERS: {
      const gameProviders = [...action.payload]
      return { ...state, gameProviders };
    }

    case BANK_TRANSFER_ACTION.SET_SELECTED_TRANSFER_TO_GAME_PROVIDER: {
      const selectedTransferToGameProvider = action.payload

      return { ...state, selectedTransferToGameProvider };
    }

    case BANK_TRANSFER_ACTION.SET_PROMOTIONS: {
      const promotions = [...action.payload]
      return { ...state, promotions };
    }

    case BANK_TRANSFER_ACTION.SET_SELECTED_PROMOTION: {
      const selectedPromotion = state.promotions.find((promotions) => promotions.id === action.payload)
      return { ...state, selectedPromotion: selectedPromotion ?? { id: '', name: '' } };
    }

    case BANK_TRANSFER_ACTION.SET_PROMOTION_CODE: {
      return { ...state, promotionCode: action.payload };
    }


    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const reloadReducer = (state, action) => {
  switch (action.type) {

    case RELOAD_ACTION.SET_TELCO: {
      const telco = [...action.payload]
      return { ...state, telco, selectedTelco: telco.length > 0 ? { ...telco[0] } : { code: '', name: '' } };
    }

    case RELOAD_ACTION.SET_SELECTED_TELCO: {
      const selectedTelco = state.telco.find((telco) => telco.code === action.payload)
      return { ...state, selectedTelco };
    }

    case RELOAD_ACTION.SET_RELOAD_AMOUNT: {
      return { ...state, reloadAmount: action.payload };
    }

    case RELOAD_ACTION.SET_RELOAD_PIN: {
      return { ...state, reloadPin: action.payload };
    }

    case RELOAD_ACTION.SET_GAME_PROVIDERS: {
      const gameProviders = [...action.payload]
      return { ...state, gameProviders };
    }

    case RELOAD_ACTION.SET_SELECTED_TRANSFER_TO_GAME_PROVIDER: {
      const selectedTransferToGameProvider = action.payload

      return { ...state, selectedTransferToGameProvider };
    }

    case RELOAD_ACTION.SET_PROMOTIONS: {
      const promotions = [...action.payload]
      return { ...state, promotions };
    }

    case RELOAD_ACTION.SET_SELECTED_PROMOTION: {
      const selectedPromotion = state.promotions.find((promotions) => promotions.id === action.payload)
      return { ...state, selectedPromotion: selectedPromotion ?? { id: '', name: '' } };
    }

    case RELOAD_ACTION.SET_PROMOTION_CODE: {
      return { ...state, promotionCode: action.payload };
    }


    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const qrPayReducer = (state, action) => {
  switch (action.type) {

    case QRPAY_ACTION.SET_BANK_ACCOUNTS: {
      const bankAccounts = [...action.payload]
      return { ...state, bankAccounts, selectedBankAccount: bankAccounts.length > 0 ? { ...bankAccounts[0] } : { id: '', name: '', bank_id: '', qr_image_url: '', _bank: { currency: '', name: '' }, min_deposit: 0, max_deposit: 0 } };
    }

    case QRPAY_ACTION.SET_SELECTED_BANK_ACCOUNT: {
      const selectedBankAccount = state.bankAccounts.find((bankAccounts) => bankAccounts.code === action.payload)
      return { ...state, selectedBankAccount };
    }

    case QRPAY_ACTION.SET_DEPOSIT_AMOUNT: {
      return { ...state, depositAmount: action.payload };
    }

    case QRPAY_ACTION.SET_DEPOSIT_METHOD: {
      const depositMethod = [...action.payload]
      return { ...state, depositMethod, selectedDepositMethod: depositMethod.length > 0 ? { ...depositMethod[0] } : { id: '', name: '' } };
    }

    case QRPAY_ACTION.SET_SELECTED_DEPOSIT_METHOD: {
      const selectedDepositMethod = state.depositMethod.find((depositMethod) => depositMethod.id === action.payload)
      const bankAccounts = state.bankAccounts?.filter(item => selectedDepositMethod?.id !== 'QR_CODE' || (selectedDepositMethod?.id === 'QR_CODE' && item.qr_image_url))
      return { ...state, selectedDepositMethod, selectedBankAccount: bankAccounts.length > 0 ? { ...bankAccounts[0] } : { id: '', name: '', bank_id: '', qr_image_url: '', _bank: { currency: '', name: '' }, min_deposit: 0, max_deposit: 0 } };
    }

    case QRPAY_ACTION.SET_SELECTED_DEPOSIT_RECEIPT: {
      return { ...state, selectedDepositReceipt: action.payload };
    }

    case QRPAY_ACTION.SET_GAME_PROVIDERS: {
      const gameProviders = [...action.payload]
      return { ...state, gameProviders };
    }

    case QRPAY_ACTION.SET_SELECTED_TRANSFER_TO_GAME_PROVIDER: {
      const selectedTransferToGameProvider = action.payload

      return { ...state, selectedTransferToGameProvider };
    }

    case QRPAY_ACTION.SET_PROMOTIONS: {
      const promotions = [...action.payload]
      return { ...state, promotions };
    }

    case QRPAY_ACTION.SET_SELECTED_PROMOTION: {
      const selectedPromotion = state.promotions.find((promotions) => promotions.id === action.payload)
      return { ...state, selectedPromotion: selectedPromotion ?? { id: '', name: '' } };
    }

    case QRPAY_ACTION.SET_PROMOTION_CODE: {
      return { ...state, promotionCode: action.payload };
    }


    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}
// const defaultDepositAmountSelection = [50, 100, 300, 500, 1000]
// const defaultDepositAmountSelectionIDR = [100000, 200000, 300000, 400000, 500000]

const Quickpay = () => {
  const appDispatch = useStoreDispatch()
  const appState = useStoreState();
  const { t, i18n } = useTranslation()
  const { queries, query } = useMiddletier()
  const [quickPayState, quickPayDispatch] = useReducer(quickPayReducer, initialQuickPayData)
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate()
  const [formElements, setFormElements] = useState([])
  const [checkDepositBank, setCheckDepositBank] = useState(false);
  const [showToProviderSelection, setShowToProviderSelection] = useState(false);
  const toContainerRef = useRef(null);
  const [deviceType, setDeviceType] = useState('');


  const depositForm = useRef()

  const baseUrl = window.location.origin;

  const onChangeDepositAmount = (newValue) => {
    quickPayDispatch({ type: QUICK_PAY_ACTION.SET_DEPOSIT_AMOUNT, payload: newValue })
  }

  const onSelectBank = (newValue) => {
    quickPayDispatch({ type: QUICK_PAY_ACTION.SET_SELECTED_BANK, payload: newValue })
  }

  const onChangePaymentProvider = (newValue) => {
    quickPayDispatch({ type: QUICK_PAY_ACTION.SET_SELECTED_PAYMENT_PROVIDER, payload: newValue })
  }

  // const onChangeCurrency = (newValue) => {
  //   quickPayDispatch({ type: QUICK_PAY_ACTION.SET_SELECTED_CURRENCY, payload: newValue })
  // }

  const onChangeDepositMethod = (newValue) => {
    quickPayDispatch({ type: QUICK_PAY_ACTION.SET_SELECTED_DEPOSIT_METHOD, payload: newValue })
  }

  const onChangeTransferToGameProvider = (newValue) => {
    quickPayDispatch({ type: QUICK_PAY_ACTION.SET_SELECTED_TRANSFER_TO_GAME_PROVIDER, payload: newValue })
    setShowToProviderSelection(false)


  }

  const onChangePromotion = (newValue) => {
    quickPayDispatch({ type: QUICK_PAY_ACTION.SET_SELECTED_PROMOTION, payload: newValue })
  }

  const onChangePromotionCode = (newValue) => {
    quickPayDispatch({ type: QUICK_PAY_ACTION.SET_PROMOTION_CODE, payload: newValue })
    // transferDispatch({ type: TRANSFER_ACTION.SET_SELECTED_PROMOTION, payload: newValue })
  }


  const onClickShowSelectProvider = () => {

    if (showToProviderSelection) {
      setShowToProviderSelection(false)
    } else {
      setShowToProviderSelection(true)
    }

  }

  const handleClickOutside = (event) => {

    if (toContainerRef.current && !toContainerRef.current.contains(event.target)) {
      setShowToProviderSelection(false)
    }
  };

  useEffect(() => {

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const userAgent = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
        setDeviceType("mobile");
    } else {
        setDeviceType("desktop");
    }

}, [])


  const getMinMaxDeposit = () => {
    let min, max;

    if (parseFloat(quickPayState.selectedBank.min_deposit) > 0) {
      min = parseFloat(quickPayState.selectedBank.min_deposit);
    } else {
      if (parseFloat(quickPayState.selectedPaymentProvider?.min_deposit) > 0) {
        min = parseFloat(quickPayState.selectedPaymentProvider?.min_deposit);
      } else {
        min = parseFloat(appState.currencyFormat?.min_deposit_amount) ?? 0;
      }
    }

    if (parseFloat(quickPayState.selectedBank.max_deposit) > 0) {
      max = parseFloat(quickPayState.selectedBank.max_deposit);
    } else {
      if (parseFloat(quickPayState.selectedPaymentProvider?.max_deposit) > 0) {
        max = parseFloat(quickPayState.selectedPaymentProvider?.max_deposit);
      } else {
        max = parseFloat(appState.currencyFormat?.max_deposit_amount) ?? 0;
      }
    }

    const formattedMin = parseFloat(min).toLocaleString(appState.currencyFormat?.locale);
    const formattedMax = parseFloat(max).toLocaleString(appState.currencyFormat?.locale);

    return { min: formattedMin, max: formattedMax };
  };
  const onDepositFormSubmit = () => {
    setLoading(true)
    const options = quickPayState.selectedPaymentProvider?.options
    query(
      {
        method: 'getReferenceIdByPaymentProviders',
        params: [
          { code: 'paymentProvider', graphqlType: 'String', required: true, value: quickPayState.selectedPaymentProvider?.code },
          { code: 'member', graphqlType: 'String', required: true, value: appState.user?.username },
          { code: 'amount', graphqlType: 'Float', required: true, value: parseFloat(quickPayState.depositAmount) },
        ],
        attributes: []
      },
    )
      .then(({ data }) => {
        setLoading(false)
        // if (typeof fbq !== 'undefined') {

        // }



        // if (appState.user?.affiliate!==null) {
        //   query({
        //     method: 'affiliate',
        //     params: [
        //       { code: 'username', graphqlType: 'String', required: true, value: appState?.user?.affiliate },
        //     ],
        //     attributes: ['meta_pixel']
        //   })
        //     .then(({ data }) => {
        //       const getMetaPixelByAffiliate = data['affiliate']?.meta_pixel ?? ''
        //       if (getMetaPixelByAffiliate !== '') {
        //         window.fbq('trackSingle', getMetaPixelByAffiliate, 'Purchase', {
        //           currency: appState?.currency,
        //           value: parseFloat(quickPayState.depositAmount),
        //           content_ids: [`${data['getReferenceIdByPaymentProviders']}`]
        //         });

        //         console.log(`trackSingle ${getMetaPixelByAffiliate}`)
        //       } else {
        //         if (typeof fbq !== 'undefined') {

        //           console.log('fbq', {
        //             currency: appState?.currency,
        //             value: parseFloat(quickPayState.depositAmount),
        //             content_ids: [`${data['getReferenceIdByPaymentProviders']}`]
        //           })

        //           window.fbq('track', 'Purchase', {
        //             currency: appState?.currency,
        //             value: parseFloat(quickPayState.depositAmount),
        //             content_ids: [`${data['getReferenceIdByPaymentProviders']}`]
        //           });
        //         }

        //       }

        //     })
        //     .catch((error) => {
        //       console.error(error)
        //     })
        // } else {
        //   if (typeof fbq !== 'undefined') {

        //     console.log('fbq', {
        //       currency: appState?.currency,
        //       value: parseFloat(quickPayState.depositAmount),
        //       content_ids: [`${data['getReferenceIdByPaymentProviders']}`]
        //     })

        //     window.fbq('track', 'Purchase', {
        //       currency: appState?.currency,
        //       value: parseFloat(quickPayState.depositAmount),
        //       content_ids: [`${data['getReferenceIdByPaymentProviders']}`]
        //     });
        //   }
        // }

        if (appState?.pixelID !== '' && typeof appState?.pixelID !== 'undefined') {
          window.fbq('trackSingle', appState?.pixelID, 'Purchase', {
            currency: appState?.currency,
            value: parseFloat(quickPayState.depositAmount),
            content_ids: [`${data['getReferenceIdByPaymentProviders']}`]
          });
        }



        if (options.mapping['id']) {
          depositForm.current[options.mapping['id']].value = `${data['getReferenceIdByPaymentProviders']}` //TODO
        }

        if (options.mapping['member']) {
          depositForm.current[options.mapping['member']].value = appState.user?.username
        }

        if (options.mapping['deposit_amount']) {
          depositForm.current[options.mapping['deposit_amount']].value = parseFloat(quickPayState.depositAmount)
        }

        if (options.mapping['language']) {
          depositForm.current[options.mapping['language']].value = i18n.resolvedLanguage
        }

        if (options.mapping['bankcode']) {
          depositForm.current[options.mapping['bankcode']].value = quickPayState.selectedBank.external_code // '10000532'quickPayState.selectedBank.external_code
        }

        if (options.token) {
          if (options.token.format === 'md5') {
            const values = options.token.data.map((itm) => depositForm.current[itm].value)
            depositForm.current[options.token.mapping].value = md5(values.join('')).toString()
          }
        }
        // console.log('depositForm.current.outerHTML',depositForm.current.outerHTML);
        depositForm.current.submit();
      })
      .catch((error) => {
        setLoading(false)
        console.error(error)
        if (error?.networkError?.statusCode === 401) {
          appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })

        } else {
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: error.message.toString(), typeAlert: 'error' }
          });
        }
      })

  }


  const handleSubmit = () => {
    //paymentProvider, bankCode, depositType, currency, amount, userVerification, displayLanguage,channel
    setLoading(true)

    // if (quickPayState.selectedPaymentProvider?.form_option) {
    //   console.log('quickPayState.selectedPaymentProvider: ', quickPayState.selectedPaymentProvider.options.url)

    //   let data = Object.keys(quickPayState.selectedPaymentProvider?.options.mapping).reduce((result, itm) => {
    //     const value = quickPayState.selectedPaymentProvider?.options.mapping[itm]
    //     if (itm === 'apiKey') {
    //       result[value] = '4449a5c22d99b4635748df69409eaaebd4099c02'//quickPayState.selectedPaymentProvider?.options.apiKey
    //     }
    //     else if (itm === 'id') {
    //       result[value] = nanoid()
    //     }
    //     else if (itm === 'member') {
    //       result[value] = appState.user?.username
    //     }
    //     else if (itm === 'deposit_amount') {
    //       result[value] = parseFloat(quickPayState.depositAmount).toFixed(0)
    //     }
    //     // else if (itm === 'language') {
    //     //   result[value] = i18n.resolvedLanguage
    //     // }
    //     else if (itm === 'bankcode') {
    //       result[value] = '10000532' //quickPayState.selectedBank.external_code //'10000532'
    //     }
    //     else if (itm === 'failed_return_url') {
    //       result[value] = `${baseUrl}/payment_failed.html`
    //     }
    //     else if (itm === 'return_url') {
    //       result[value] = `${baseUrl}/payment_successful.html`
    //     }
    //     else if (itm === 'post_url') {
    //       result[value] = `https://api.flexgaming.xyz/api/${TENANT_NAME}/payment/${quickPayState.selectedPaymentProvider?.code.toLowerCase()}/deposit`//TODO: 
    //     }

    //     return result
    //   }, { ...quickPayState.selectedPaymentProvider?.options.data })

    //   data.currency = 'MYR'
    //   // data.currency = 'MYR'

    //   if (quickPayState.selectedPaymentProvider.options.token.format === 'md5') {
    //     const values = quickPayState.selectedPaymentProvider.options.token.data.map((itm) => data[itm])
    //     data[quickPayState.selectedPaymentProvider.options.token.mapping] = md5(values.join('')).toString()
    //   }

    //   // data.apikey = '4449a5c22d99b4635748df69409eaaebd4099c02'


    //   delete data.apikey

    //   const params = new URLSearchParams();
    //   Object.keys(data).forEach((key) => {
    //     params.append(key, data[key])
    //   })

    //   const formData = new FormData();
    //   Object.keys(data).forEach((key) => {
    //     formData.append(key, data[key])
    //   })

    //   const url = `${quickPayState.selectedPaymentProvider.options.url}?${params.toString()}`

    //   window.open(url, '_blank');

    //   fetch(quickPayState.selectedPaymentProvider.options.url, { method: 'POST', mode: 'no-cors', body: formData }).then((response) => {
    //     return response.text()
    //   }).then((html) => {
    //     console.log('html: ', html)
    //   })
    //     .catch((err) => {
    //       console.log('err:', err)
    //     })

    //   // fetch(quickPayState.selectedPaymentProvider.options.url, { method: 'POST', body: params })
    //   //   .then((data) => {
    //   //     // console.log(data.text())
    //   //   })
    //   setLoading(false)

    // }
    // else {
    let params = [
      { code: 'paymentProvider', value: quickPayState.selectedPaymentProvider?.code, graphqlType: 'String', required: true },
      { code: 'currency', value: appState.user?.currency ?? appState.currency, graphqlType: 'String', required: true },
      { code: 'amount', value: parseFloat(quickPayState.depositAmount), graphqlType: 'Float', required: true },
      { code: 'device', graphqlType: 'String', required: false, value: deviceType === 'desktop' ? '0' : '1' },
      { code: 'userVerification', graphqlType: 'Boolean', required: false, value: true },
    ];

    if (quickPayState.selectedTransferToGameProvider !== 'MAIN_WALLET' && quickPayState.selectedTransferToGameProvider !== '') {
      params.push({ code: 'gameProvider', graphqlType: 'String', required: false, value: quickPayState.selectedTransferToGameProvider })
    }


    // CHECK PROMOTION
    if (quickPayState.selectedPromotion?.id !== '' || quickPayState.promotionCode !== '') {
      if (quickPayState.promotionCode !== '') {
        const checkPromotionCode = quickPayState.promotionCode?.trim()
        let promoCodeExist = quickPayState.promotions.find((promotions) => promotions.code === checkPromotionCode)
        if (typeof promoCodeExist === 'undefined') {
          setLoading(false)
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: `Promotion Code[${checkPromotionCode}] Is NOT EXIST`, typeAlert: 'error' }
          });

          return
        }
        else {
          params.push({ code: 'promotion', graphqlType: 'String', required: false, value: promoCodeExist.id })
        }
      } else {
        params.push({ code: 'promotion', graphqlType: 'String', required: false, value: quickPayState.selectedPromotion.id })
      }

    }

    if (quickPayState.selectedPaymentProvider?.bank_option) {
      params.push({ code: 'bank', value: quickPayState.selectedBank.external_code, graphqlType: 'String', required: false })
    }
    else {
      params.push({ code: 'channel', value: quickPayState.selectedDepositMethod.id, graphqlType: 'String', required: false })
    }

    const mytab = window.open("/loading.html", "_blank");

    queries([
      {
        index: 'getDepositLinkByMember',
        method: 'getDepositLinkByMember',
        params,
        attributes: []
      },
    ])
      .then(({ data }) => {

        setLoading(false)

        const getTransactionId = data['getDepositLinkByMember']?.transactionId


        // if (appState.user?.affiliate!==null) {
        //   query({
        //     method: 'affiliate',
        //     params: [
        //       { code: 'username', graphqlType: 'String', required: true, value: appState?.user?.affiliate },
        //     ],
        //     attributes: ['meta_pixel']
        //   })
        //     .then(({ data }) => {
        //       const getMetaPixelByAffiliate = data['affiliate']?.meta_pixel ?? ''
        //       if (getMetaPixelByAffiliate !== '') {
        //         window.fbq('trackSingle', getMetaPixelByAffiliate, 'Purchase', {
        //           currency: appState?.currency,
        //           value: parseFloat(quickPayState.depositAmount),
        //           content_ids: [`${getTransactionId}`]
        //         });

        //         console.log(`trackSingle ${getMetaPixelByAffiliate}`)
        //       } else {
        //         if (typeof fbq !== 'undefined') {

        //           console.log('fbq', {
        //             currency: appState?.currency,
        //             value: parseFloat(quickPayState.depositAmount),
        //             content_ids: [`${getTransactionId}`]
        //           })
        //           window.fbq('track', 'Purchase', {
        //             currency: appState?.currency,
        //             value: parseFloat(quickPayState.depositAmount),
        //             content_ids: [`${getTransactionId}`]
        //           });
        //         }

        //       }

        //     })
        //     .catch((error) => {
        //       console.error(error)
        //     })
        // } else {
        //   if (typeof fbq !== 'undefined') {

        //     console.log('fbq', {
        //       currency: appState?.currency,
        //       value: parseFloat(quickPayState.depositAmount),
        //       content_ids: [`${getTransactionId}`]
        //     })
        //     window.fbq('track', 'Purchase', {
        //       currency: appState?.currency,
        //       value: parseFloat(quickPayState.depositAmount),
        //       content_ids: [`${getTransactionId}`]
        //     });
        //   }
        // }

        if (appState?.pixelID !== '' && typeof appState?.pixelID !== 'undefined') {
          window.fbq('trackSingle', appState?.pixelID, 'Purchase', {
            currency: appState?.currency,
            value: parseFloat(quickPayState.depositAmount),
            content_ids: [`${getTransactionId}`]
          });
        }


        const url = data['getDepositLinkByMember'].url
        if (url) {
          mytab.location = url
        } else {
          mytab.close()
        }

        // window.open(url, '_blank', 'noreferrer')
      })
      .catch((error) => {
        mytab.close()
        console.error(error)
        setLoading(false)
        if (error?.networkError?.statusCode === 401) {
          appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
        } else {
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: error.message.toString(), typeAlert: 'error' }
          });
        }
      })
    // }

  }

  useEffect(() => {
    if (quickPayState.selectedPaymentProvider?.form_option === true) {
      const options = quickPayState.selectedPaymentProvider?.options
      let _elements = Object.keys(options.data).reduce((result, key) => {
        result.push({ key, value: options.data[key] })
        return result
      }, [])

      _elements = Object.keys(options.mapping).reduce((result, key) => {
        if (key === 'apiKey') {
          result.push({ key: options.mapping[key], value: options.apiKey })
        }
        else if (key === 'failed_return_url') {
          result.push({ key: options.mapping[key], value: `${baseUrl}/payment_failed.html` })//TODO
        }
        else if (key === 'return_url') {
          result.push({ key: options.mapping[key], value: `${baseUrl}/payment_successful.html` })//TODO
        }
        else if (key === 'post_url') {
          result.push({ key: options.mapping[key], value: `https://api.imbaweb.com/api/${TENANT_NAME}/payment/${quickPayState.selectedPaymentProvider?.code.toLowerCase()}/deposit` })//TODO
        }
        else {
          result.push({ key: options.mapping[key], value: '' })
        }
        return result
      }, [..._elements])


      setFormElements(_elements)
      console.log('element', _elements)
    }
    else {
      setFormElements([])
    }
  }, [baseUrl, quickPayState.selectedPaymentProvider?.code, quickPayState.selectedPaymentProvider?.form_option, quickPayState.selectedPaymentProvider?.options])

  useEffect(() => {

    let min, max;

    if (parseFloat(quickPayState.selectedBank.min_deposit) > 0) {
      min = parseFloat(quickPayState.selectedBank.min_deposit);
    } else {
      if (parseFloat(quickPayState.selectedPaymentProvider?.min_deposit) > 0) {
        min = parseFloat(quickPayState.selectedPaymentProvider?.min_deposit);
      } else {
        min = parseFloat(appState.currencyFormat?.min_deposit_amount) ?? 0;
      }
    }

    if (parseFloat(quickPayState.selectedBank.max_deposit) > 0) {
      max = parseFloat(quickPayState.selectedBank.max_deposit);
    } else {
      if (parseFloat(quickPayState.selectedPaymentProvider?.max_deposit) > 0) {
        max = parseFloat(quickPayState.selectedPaymentProvider?.max_deposit);
      } else {
        max = parseFloat(appState.currencyFormat?.max_deposit_amount) ?? 0;
      }
    }

    const formattedMin = parseFloat(min);
    const formattedMax = parseFloat(max);


    if (quickPayState.selectedPaymentProvider?.bank_option) {
      if (quickPayState.selectedBank.code !== '') {
        setDisabledSubmit(false)
      } else {
        setDisabledSubmit(true)
      }
    }

    if (quickPayState.selectedPaymentProvider?.code !== '' && (quickPayState.depositAmount >= formattedMin && quickPayState.depositAmount <= formattedMax)) {
      setDisabledSubmit(false)
    }
    else {
      setDisabledSubmit(true)
    }

  }, [quickPayState.selectedPaymentProvider?.code, quickPayState.selectedPaymentProvider?.bank_option, quickPayState.selectedBank, quickPayState.depositAmount, appState.currencyFormat?.min_deposit_amount, appState.currencyFormat?.max_deposit_amount, quickPayState.selectedPaymentProvider?.min_deposit, quickPayState.selectedPaymentProvider?.max_deposit])

  useEffect(() => {
    setCheckDepositBank(false)
    if (quickPayState.selectedPaymentProvider?.code !== '') {
      setLoading(true)
      queries([
        {
          index: 'getBanksByPaymentProvider',
          method: 'getBanksByPaymentProvider',
          params: [
            { code: 'paymentProvider', value: quickPayState.selectedPaymentProvider?.code, graphqlType: 'String', required: true },
            { code: 'currency', value: appState.currency || appState.user?.currency, graphqlType: 'String', required: true },
            { code: 'type', value: 'DEPOSIT', graphqlType: 'String', required: true }
          ],
          attributes: []
        },
      ])
        .then(({ data }) => {
          setLoading(false)
          setCheckDepositBank(true)
          quickPayDispatch({ type: QUICK_PAY_ACTION.SET_BANKS, payload: data['getBanksByPaymentProvider'] })

        })
        .catch((error) => {
          setLoading(false)

          console.error(error)

          if (error?.networkError?.statusCode === 401) {
            appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
          } else {
            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: error.message.toString(), typeAlert: 'error' }
            });
          }
        })
    }
  }, [queries, quickPayState.selectedPaymentProvider?.code, appState.user?.currency, appState.currency, appDispatch, navigate])

  useEffect(() => {
    queries([
      {
        index: 'getPaymentProviders',
        method: 'getPaymentProviders',
        params: [
          { code: 'type', value: 'DEPOSIT', graphqlType: 'String', required: true }
        ],
        attributes: []
      },
    ])
      .then(({ data }) => {
        quickPayDispatch({ type: QUICK_PAY_ACTION.SET_PAYMENT_PROVIDERS, payload: data['getPaymentProviders'] })
      })
      .catch((error) => {
        console.error(error)

        if (error?.networkError?.statusCode === 401) {
          appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
        } else {
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: error.message.toString(), typeAlert: 'error' }
          });
        }
      })
  }, [queries, appDispatch, navigate])

  // GET ALL ACTIVE GAME PROVIDER
  useEffect(() => {
    setLoading(true)
    if (typeof appState.user?.username !== 'undefined') {


      let initialdepositqueries = [
        {
          index: 'getGameTypes',
          method: 'getGameTypes',
          params: [
            { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { status: 'ACTIVE', game_provider: `GS` }, order: [['updated_at', 'DESC']] } }
          ],
          attributes: [['type', 'code'], '_label']
        }, {
          index: 'getGameProviders',
          method: 'getGameProviders',
          params: [
            { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { '$_game_provider_game_types.status$': 'ACTIVE', status: `ACTIVE` }, order: [['name', 'ASC']] } },
          ],
          attributes: ['code', 'name', ['_game_provider_game_types', ['type']]]
        }]

      if (appState?.gameWallet?.length > 0) {
        initialdepositqueries.push({ index: 'getAllGamePrivoderBalanceByMember', method: 'getAllGamePrivoderBalanceByMember', params: [], attributes: [] })
      }

      queries(initialdepositqueries)
        .then(({ data }) => {

          setLoading(false)

          const checkAllGameProviderBalance = data['getGameProviders']?.map(item => {
            const checkGameProviderBalance = data['getAllGamePrivoderBalanceByMember']
              ? data['getAllGamePrivoderBalanceByMember'].find(e => e.code === item.code)
              : null;
            return {
              ...item,
              balance: checkGameProviderBalance ? checkGameProviderBalance.balance : 0

            };
          });

          const groups = data['getGameTypes']
          const providersData = checkAllGameProviderBalance


          const sortedData = groups.map(type => {
            let getTypeName = GAME_MENU_DATA[type.code] ?? ''
            const options = providersData
              .filter(provider => provider._game_provider_game_types.some(gameType => gameType.type === type.code))
              .map(provider => ({ code: provider.code, name: provider.name, locked: provider.locked, balance: provider.balance }));
            return { code: type.code, label: getTypeName.name, options };
          });

          quickPayDispatch({ type: QUICK_PAY_ACTION.SET_GAME_PROVIDERS, payload: sortedData })

        }).catch((error, data) => {
          console.log('error: ', error)


          setLoading(false)

          if (error?.networkError?.statusCode === 401) {
            appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
          } else {
            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: error.message.toString(), typeAlert: 'error' }
            });
          }

        })
    }



  }, [queries, appState.user?.username, appDispatch, appState?.gameWallet]);

  // GET PROMOTION
  useEffect(() => {
    if (quickPayState.selectedTransferToGameProvider !== 'MAIN_WALLET') {
      let getPromoParam = [
        { code: 'member', graphqlType: 'String', required: false, value: appState.user?.username },
        { code: 'language', graphqlType: 'String', required: true, value: i18n.resolvedLanguage },
        { code: 'fromDeposit', graphqlType: 'Boolean', required: false, value: true },
      ]


      if (quickPayState.selectedTransferToGameProvider !== '') {
        getPromoParam.push({ code: 'gameProvider', graphqlType: 'String', required: false, value: quickPayState.selectedTransferToGameProvider })
      }

      queries([
        {
          index: 'getPromotions',
          method: 'getPromotions',
          params: getPromoParam,
          attributes: []
        },])
        .then(({ data }) => {
          quickPayDispatch({ type: QUICK_PAY_ACTION.SET_PROMOTIONS, payload: data['getPromotions'] })
          // setLoading(false)

        }).catch((error) => {
          console.log('error: ', error)
          setLoading(false)
          if (error?.networkError?.statusCode === 401) {
            appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
          } else {
            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: error.message.toString(), typeAlert: 'error' }
            });
          }
        })
    } else {
      quickPayDispatch({ type: QUICK_PAY_ACTION.SET_PROMOTIONS, payload: [] })
      quickPayDispatch({ type: QUICK_PAY_ACTION.SET_PROMOTION_CODE, payload: '' })
      quickPayDispatch({ type: QUICK_PAY_ACTION.SET_SELECTED_PROMOTION, payload: { id: '', name: '' } })
    }

  }, [queries, quickPayState.selectedTransferToGameProvider, appState.user?.username, i18n.resolvedLanguage, appDispatch]);

  return (

    <>
      <Form className="quickpay_form">
        <Form.Group className="form_input_wrap" >
          <div className="input_wrap">
            <Form.Label>{t('deposit_channel')}
              <span className="form_required_input">* {t('required_select')}</span>
            </Form.Label>
            {/* <Form.Select aria-label="depositMethod" className="input_depositMethod" value={quickPayState.selectedPaymentProvider?.id} onChange={(evt) => { onChangePaymentProvider(evt.target.value) }}>
            {quickPayState.paymentProviders.map(function (item, index) {
              return (
                <option value={item.name} key={item.id}>{item.name}</option>
              )
            })}
          </Form.Select> */}
            <div className={`deposit_channel_container`}>
              {quickPayState.paymentProviders.map(function (item, index) {
                return (
                  <div onClick={() => { onChangePaymentProvider(item.code) }} key={item.code} className={`deposit_channel_row`}>
                    <img src={`${TENANT_BUCKET}/icon/deposit_${item.code.toLowerCase()}${quickPayState.selectedPaymentProvider?.code === item.code ? '_active' : ''}.png`} alt={`channel icon ${item.name}`} key={item.code} onClick={() => onSelectBank(item)} />
                    <div className={`${quickPayState.selectedPaymentProvider?.code === item.code ? 'color_active active' : ''}`}>{item.name}</div>
                  </div>

                )
              })}
            </div>
          </div>
        </Form.Group>
        {/* <Form.Group className="form_input_wrap" >
          <div className="input_wrap">
            <Form.Label>{t('currency')}
              <span className="form_required_input">* {t('required_select')}</span>
            </Form.Label>
            <Form.Select aria-label="depositCurrency" className="input_currency" value={quickPayState.selectedCurrency} onChange={(evt) => { onChangeCurrency(evt.target.value) }}>
              <option hidden></option>
              {quickPayState.currency.map(function (item, index) {
                return (
                  <option value={item} key={index}>{item}</option>
                )
              })}
            </Form.Select>
          </div>
        </Form.Group> */}
        {(quickPayState.selectedPaymentProvider?.method_option || (!quickPayState.selectedPaymentProvider?.method_option && quickPayState.selectedPaymentProvider?.bank_option)) && (
          <Form.Group className="form_input_wrap" >
            <div className="input_wrap">
              <Form.Label>{t('deposit_method')}
                <span className="form_required_input">* {t('required_select')}</span>
              </Form.Label>
              {quickPayState.selectedPaymentProvider?.bank_option ?
                <>
                  {/* BANK */}
                  <div className={`depositBank_col ${quickPayState.banks.filter(item => item.type === "BANK").length === 0 ? 'd-none' : ''}`}>
                    {/* <label>{t('BANKING')}</label> */}
                    <div className={`depositBankImg`}>
                      {quickPayState.banks.map((item) => {
                        if (item.type === 'BANK') {
                          return (
                            <div className={`depositBankImg_col ${quickPayState.selectedBank?.code === item.code ? 'active' : ''}`} key={item.id} onClick={() => onSelectBank(item)}>
                              <img src={`${GLOBAL_BUCKET}/bank/${item.currency}/${item.code}.png`} alt={`bank icon ${item.name}`} />
                              <span>{item.name}</span>
                            </div>
                          )
                        }
                        return null
                      })}
                    </div>
                  </div>
                  {/* WALLET */}
                  <div className={`depositBank_col  ${quickPayState.banks.filter(item => item.type === "WALLET").length === 0 ? 'd-none' : ''}`}>
                    <label>{t('QR ScanCode')}</label>
                    <div className={`depositBankImg`}>
                      {quickPayState.banks.map((item) => {
                        if (item.type === 'WALLET') {
                          return (
                            <div className={`depositBankImg_col ${quickPayState.selectedBank?.code === item.code ? 'active' : ''}`} key={item.id} onClick={() => onSelectBank(item)}>
                              <img src={`${GLOBAL_BUCKET}/bank/${item.currency}/${item.code}.png`} alt={`bank icon ${item.name}`} />
                              <span>{item.name}</span>
                            </div>
                          )
                        }
                        return null
                      })}
                    </div>
                  </div>
                  {/* WALLET */}
                  <div className={`depositBank_col  ${quickPayState.banks.filter(item => item.type === "TELCO").length === 0 ? 'd-none' : ''}`}>
                    <label>{t('TELCO')}</label>
                    <div className={`depositBankImg`}>
                      {quickPayState.banks.map((item) => {
                        if (item.type === 'TELCO') {
                          return (
                            <div className={`depositBankImg_col ${quickPayState.selectedBank?.code === item.code ? 'active' : ''}`} key={item.id} onClick={() => onSelectBank(item)}>
                              <img src={`${GLOBAL_BUCKET}/bank/${item.currency}/${item.code}.png`} alt={`bank icon ${item.name}`} />
                              <span>{item.name}</span>
                            </div>
                          )
                        }
                        return null
                      })}
                    </div>
                  </div>
                  {/* IF NO BANK NO WALLET */}
                  {(checkDepositBank && quickPayState.banks.length === 0) && (<img src={`${TENANT_BUCKET}/icon/under_maintenance_1_${i18n.resolvedLanguage}.png`} alt={`under maintenance`} className={`maintenance_img`} />)}
                </> :
                <Form.Select aria-label="depositMethod" className="input_depositMethod" value={quickPayState.selectedDepositMethod?.id} onChange={(evt) => { onChangeDepositMethod(evt.target.value) }}>
                  {quickPayState.depositMethod.map(function (item, index) {
                    return (
                      <option value={item.id} key={item.id}>{t(item.name)}</option>
                    )
                  })}
                </Form.Select>}
            </div>
          </Form.Group>)}
        <Form.Group className="form_input_wrap" >
          <div className="input_wrap">
            <Form.Label>{t('deposit_amount')}
              <span className="form_required_input">* {t('required_fill_in')}</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={checkDepositBank && (t('deposit_quickpay_amount_placeholder', getMinMaxDeposit()))}
              className="input_depositAmount"
              aria-label="depositAmount"
              autoComplete="off"
              value={quickPayState.depositAmount === 0 ? '' : quickPayState.depositAmount}
              onChange={(evt) => { onChangeDepositAmount(evt.target.value) }}
              onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
              onInput={(e) => {
                let value = appState.currencyFormat.decimal_point === 0
                  ? e.target.value.replace(/[^0-9]/g, '')
                  : e.target.value.replace(/[^0-9.]/g, '');

                if (value.split('.').length - 1 > 1) {
                  value = value.substring(0, value.lastIndexOf('.'));
                }

                const decimal_part = value.split(".")[1];
                if (decimal_part && decimal_part.length > appState.currencyFormat.decimal_point) {
                  value = value.slice(0, -1);
                }
                e.target.value = value;
                // e.target.value = e.target.value.replace(/[^0-9]/g, '');
              }}
            />
            {checkDepositBank && (<div className="info_input_wrap">
              <FaInfoCircle /> <span>{t('deposit_quickpay_amount_placeholder', getMinMaxDeposit())}</span>
            </div>
            )}
            <div type="radio" name="options" className="wallet_btnAmount" >

              {appState.currencyFormat.default_deposit_amounts?.map(function (item, index) {

                return (
                  <button value={item.value} key={index} type="button" onClick={() => onChangeDepositAmount(item.value)} className={`${parseFloat(quickPayState.depositAmount) === item.value ? 'active color_active' : ''}`}>{item.text}</button>
                )
              })}

            </div>
          </div>

        </Form.Group>
        {/* TRANSFER */}
        <div className='deposit_transferContainer'>
          <Form.Group className="form_input_wrap" >
            <div className="input_wrap">
              <Form.Label>{t('transfer_to')}
                <span className="form_required_input">* {t('optional')}</span>
              </Form.Label>
              {/* <Form.Select aria-label="transferTo" className="input_transferTo" value={quickPayState.selectedTransferToGameProvider} onChange={(evt) => { onChangeTransferToGameProvider(evt.target.value) }}>
                <option hidden></option>
                {quickPayState.selectedTransferFromGameProvider !== 'MAIN_WALLET' && (<option value={'MAIN_WALLET'}>{t('main_wallet')}</option>)}
                {quickPayState.gameProviders.map((provider) => (
                  <optgroup key={provider.label} label={t(provider.label)}>
                    {provider?.options?.filter(item => item.code !== quickPayState.selectedTransferFromGameProvider).map((option) => (
                      <option key={option.code} value={option.code}>{option.name}</option>
                    ))}
                  </optgroup>
                ))}
              </Form.Select> */}
              <div className="selectProviderContainer" ref={toContainerRef}>
                <div className="form-select" onClick={() => { onClickShowSelectProvider() }}>
                  {quickPayState.selectedTransferToGameProvider === 'MAIN_WALLET' ? t('main_wallet') :
                    quickPayState.gameProviders.flatMap(item => item.options)
                      .find(option => option.code === quickPayState.selectedTransferToGameProvider)
                      ?.name || ''
                  }</div>
                {showToProviderSelection && (<ProviderSelector section={'DEPOSIT'} onSelect={onChangeTransferToGameProvider} data={quickPayState.gameProviders} currentProvider={''} otherProvider={''} />)}
              </div>
            </div>
          </Form.Group>
          {((quickPayState.selectedTransferToGameProvider !== "MAIN_WALLET") && !appState.disablePromotion) && (
            <>
              <Form.Group className="form_input_wrap " >
                <div className="input_wrap">
                  <Form.Label>{t('promotion')}
                    <span className="form_required_input">* {t('optional')}</span>
                  </Form.Label>
                  <Form.Select aria-label="transferPromotion" className="input_transferPromotion" value={quickPayState.selectedPromotion?.id} onChange={(evt) => { onChangePromotion(evt.target.value) }} disabled={quickPayState.promotionCode?.length > 0 ? true : false}>
                    {quickPayState.promotions?.filter(item => item.appear_in_website === true)?.length === 0 ? (<option hidden>No Promo Available</option>) : (<option value="">No Promotion</option>)}
                    {quickPayState.promotions?.filter(item => item.appear_in_website === true).map(function (item, index) {
                      return (
                        <option value={item.id} key={item.id}>{item.title ?? item.name} {item.code && `(${item.code})`}</option>
                      )
                    })}
                  </Form.Select>
                </div>
              </Form.Group>

              <Form.Group className="form_input_wrap " >
                <div className="input_wrap">
                  <Form.Label>{t('promotion_code')}
                    <span className="form_required_input">* {t('optional')}</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="input_promotionCode"
                    value={quickPayState.promotionCode ?? ''}
                    onChange={(evt) => { onChangePromotionCode(evt.target.value) }}
                    aria-label="promotionCode"
                    disabled={quickPayState.selectedPromotion?.id !== '' ? true : false}
                  />
                </div>
              </Form.Group></>)}
        </div>
        <input className={`font_button color_button wallet_btnSubmit ${quickPayState.selectedPaymentProvider?.form_option ? 'd-none' : ''}`} disabled={disabledSubmit} type='button' value={t('submit')} onClick={handleSubmit} />
      </Form>
      {quickPayState.selectedPaymentProvider?.form_option === true ?
        (<>
          <button className="font_button color_button wallet_btnSubmit" disabled={disabledSubmit} onClick={onDepositFormSubmit} >{t('submit')}</button>
          <form ref={depositForm} action={`${quickPayState.selectedPaymentProvider?.options.url}`} method={`${quickPayState.selectedPaymentProvider?.options.method}`} target="_blank" >
            {formElements.map((itm) => {
              return (<><input key={itm.key} id={itm.key} name={itm.key} type="hidden" placeholder={itm.key} defaultValue={itm.value} /></>)
            })}
            <input className="font_button color_button wallet_btnSubmit d-none" disabled={disabledSubmit} type="submit" value={t('submit')} />
          </form></>) : <></>}

      {/* <Alert show={alertShow.show} onHide={onHideAlert} message={alertShow.message} type={alertShow.type} title={alertShow.title} /> */}
      {isLoading && (<Loading />)}
    </>
  )
}


const Banktransfer = () => {
  const appDispatch = useStoreDispatch()
  const appState = useStoreState();
  const { query, mutation, queries } = useMiddletier();
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const { t, i18n } = useTranslation();
  const [bankTransferState, bankTransferDispatch] = useReducer(bankTransferReducer, initialBankTransferData)
  const [files, setFiles] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showContactImgDialog, setShowContactImgDialog] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [checkDepositBank, setCheckDepositBank] = useState(false);
  const navigate = useNavigate();
  const [showToProviderSelection, setShowToProviderSelection] = useState(false);
  const toContainerRef = useRef(null);
  let isProcessing = false;


  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': [],
      'image/jpeg': []
    },
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
      setLoading(true)

      if(acceptedFiles?.length>1){
        setLoading(false)
        setFiles([])
        return appDispatch({
          type: APP_STORE_ACTION.SHOW_ALERT,
          payload: { description: 'You can only upload one image at a time.', typeAlert: 'error' }
      });
      }

      // console.log('params Upload Image:', acceptedFiles[0]?.type)
      const acceptedFileTypes = ['image/jpeg', 'image/png'];
      const isAcceptedFileType = acceptedFileTypes.includes(acceptedFiles[0]?.type);
      const isLt8MB = acceptedFiles[0].size / 1024 / 1024 < 8;

      if (isAcceptedFileType&&isLt8MB) {

        let params = [
          { code: 'folder', value: 'depositBankTransfer', graphqlType: 'String', required: false },
          { code: 'file', value: acceptedFiles[0], graphqlType: 'Upload', required: true }
        ]

        mutation([
          {
            method: 'singleUpload',
            params,

          }
        ])
          .then(({ data }) => {
            setLoading(false)
            bankTransferDispatch({ type: BANK_TRANSFER_ACTION.SET_SELECTED_DEPOSIT_RECEIPT, payload: data['singleUpload'].id })
          })
          .catch((error) => {
            setFiles([])
            setLoading(false)
            console.error(error)
            if (error?.networkError?.statusCode === 401) {
              appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })

            } else {
              appDispatch({
                type: APP_STORE_ACTION.SHOW_ALERT,
                payload: { description: error.message.toString(), typeAlert: 'error' }
              });
            }
          })
      } else {
        setFiles([])
        setLoading(false)
        if (!isAcceptedFileType) {
            return appDispatch({
                type: APP_STORE_ACTION.SHOW_ALERT,
                payload: { description: 'Only JPEG and PNG image formats are supported. Please check your file type and try again.', typeAlert: 'error' }
            });
        }
        if (!isLt8MB) {
            return appDispatch({
                type: APP_STORE_ACTION.SHOW_ALERT,
                payload: { description: 'Image size must below 8MB', typeAlert: 'error' }
            });
        }

    }


    }
  });

  const onChangeDepositMethod = (newValue) => {
    bankTransferDispatch({ type: BANK_TRANSFER_ACTION.SET_SELECTED_DEPOSIT_METHOD, payload: newValue })
  }

  const onSelectBankAccount = (newValue) => {
    bankTransferDispatch({ type: BANK_TRANSFER_ACTION.SET_SELECTED_BANK_ACCOUNT, payload: newValue })
  }

  const onChangeDepositAmount = (newValue) => {
    bankTransferDispatch({ type: BANK_TRANSFER_ACTION.SET_DEPOSIT_AMOUNT, payload: newValue })
  }

  const onChangeTransferToGameProvider = (newValue) => {
    bankTransferDispatch({ type: BANK_TRANSFER_ACTION.SET_SELECTED_TRANSFER_TO_GAME_PROVIDER, payload: newValue })
    setShowToProviderSelection(false)

  }

  const onChangePromotion = (newValue) => {
    bankTransferDispatch({ type: BANK_TRANSFER_ACTION.SET_SELECTED_PROMOTION, payload: newValue })
  }

  const onChangePromotionCode = (newValue) => {
    bankTransferDispatch({ type: BANK_TRANSFER_ACTION.SET_PROMOTION_CODE, payload: newValue })
    // transferDispatch({ type: TRANSFER_ACTION.SET_SELECTED_PROMOTION, payload: newValue })
  }


  const onClickShowSelectProvider = () => {

    if (showToProviderSelection) {
      setShowToProviderSelection(false)
    } else {
      setShowToProviderSelection(true)
    }

  }

  const handleClickOutside = (event) => {

    if (toContainerRef.current && !toContainerRef.current.contains(event.target)) {
      setShowToProviderSelection(false)
    }
  };

  useEffect(() => {

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const copyText = (newValue) => {
    navigator.clipboard.writeText(newValue);

    appDispatch({
      type: APP_STORE_ACTION.SHOW_ALERT,
      payload: { description: `Copied ${newValue}`, typeAlert: 'success' }
    });
  };

  const clearInput = () => {
    bankTransferState.depositMethod?.length > 0 && (bankTransferDispatch({ type: BANK_TRANSFER_ACTION.SET_SELECTED_DEPOSIT_METHOD, payload: bankTransferState.depositMethod[0]?.id }))
    bankTransferState.bankAccounts?.length > 0 && (bankTransferDispatch({ type: BANK_TRANSFER_ACTION.SET_SELECTED_BANK_ACCOUNT, payload: bankTransferState.bankAccounts[0]?.code }))
    bankTransferDispatch({ type: BANK_TRANSFER_ACTION.SET_DEPOSIT_AMOUNT, payload: 0 })
    bankTransferDispatch({ type: BANK_TRANSFER_ACTION.SET_SELECTED_DEPOSIT_RECEIPT, payload: '' })
    setFiles([])
    bankTransferDispatch({ type: BANK_TRANSFER_ACTION.SET_SELECTED_TRANSFER_TO_GAME_PROVIDER, payload: '' })
    bankTransferDispatch({ type: BANK_TRANSFER_ACTION.SET_PROMOTION_CODE, payload: '' })

  }

  const onClickImage = (image_url) => {
    setCurrentImage(image_url)
    setShowContactImgDialog(true)
  };

  // DEPOSIT (BANK TRANSFER) SUBMIT
  const handleSubmit = () => {
    if (isLoading || isProcessing) return;

    isProcessing = true;
    setLoading(true)
    if (typeof appState.user?.username !== 'undefined') {
      const method = 'depositMainWalletByMember'


      let params = [
        // { code: 'member', graphqlType: 'String', required: true, value: appState.user?.username },
        // { code: 'transaction_type', graphqlType: 'String', required: true, value: 'DEPOSIT' },
        { code: 'deposit_method', graphqlType: 'String', required: false, value: bankTransferState.selectedDepositMethod?.id },
        { code: 'currency', graphqlType: 'String', required: true, value: appState.user?.currency ?? appState?.currency },
        { code: 'amount', graphqlType: 'Float', required: true, value: parseFloat(bankTransferState.depositAmount) },
        { code: 'bank_account', graphqlType: 'String', required: false, value: bankTransferState.selectedBankAccount?.code },
        { code: 'deposit_slip', graphqlType: 'String', required: false, value: bankTransferState.selectedDepositReceipt },
        { code: 'force_withdraw', graphqlType: 'Boolean', required: true, value: false },
      ]

      if (bankTransferState.selectedTransferToGameProvider !== 'MAIN_WALLET' && bankTransferState.selectedTransferToGameProvider !== '') {
        params.push({ code: 'game_provider', graphqlType: 'String', required: false, value: bankTransferState.selectedTransferToGameProvider })
      }


      // CHECK PROMOTION
      if (bankTransferState.selectedPromotion?.id !== '' || bankTransferState.promotionCode !== '') {
        if (bankTransferState.promotionCode !== '') {
          const checkPromotionCode = bankTransferState.promotionCode?.trim()
          let promoCodeExist = bankTransferState.promotions.find((promotions) => promotions.code === checkPromotionCode)
          if (typeof promoCodeExist === 'undefined') {
            setLoading(false)
            isProcessing = false;
            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: `Promotion Code[${checkPromotionCode}] Is NOT EXIST`, typeAlert: 'error' }
            });

            return
          }
          else {
            params.push({ code: 'promotion', graphqlType: 'String', required: false, value: promoCodeExist.id })
          }
        } else {
          params.push({ code: 'promotion', graphqlType: 'String', required: false, value: bankTransferState.selectedPromotion.id })
        }

      }

      mutation([{
        method,
        params,
        attributes: []

      }])
        .then(({ data }) => {
          // setLoading(false)

          if (appState?.pixelID !== '' && typeof appState?.pixelID !== 'undefined') {
            window.fbq('trackSingle', appState?.pixelID, 'Purchase', {
              currency: appState?.currency,
              value: parseFloat(bankTransferState.depositAmount),
              content_ids: [`${data[method]?.id}`]
            });
          }

          let afterDepositqueries = [
            {
              index: 'member',
              method: 'member',
              params: [
                { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
              ],
              attributes: ['wallet_amount']
            }]

          if (appState?.gameWallet?.length > 0) {
            afterDepositqueries.push({ index: 'getAllGamePrivoderBalanceByMember', method: 'getAllGamePrivoderBalanceByMember', params: [], attributes: [] })
          }


          queries(afterDepositqueries)
            .then(({ data }) => {
              appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
              appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data['member'].wallet_amount })
              setLoading(false)
              isProcessing = false;

              appDispatch({
                type: APP_STORE_ACTION.SHOW_ALERT,
                payload: { description: `Deposit Successful`, typeAlert: 'success', forwardUrl: '/wallet/history' }
              });


            }).catch((error) => {
              console.log('error: ', error)
              setLoading(false)
              isProcessing = false;
              if (error?.networkError?.statusCode === 401) {
                appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
              } else {
                appDispatch({
                  type: APP_STORE_ACTION.SHOW_ALERT,
                  payload: { description: error.message.toString(), typeAlert: 'error' }
                });
              }
            })



          clearInput()
        })
        .catch((error) => {
          setLoading(false)
          isProcessing = false;
          console.log('error: ', error)
          if (error?.networkError?.statusCode === 401) {
            appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })

          } else {
            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: error.message.toString(), typeAlert: 'error' }
            });
          }
        })
    } else {
      setLoading(false)
      isProcessing = false;
      appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
    }

  };

  // GET DEPOSIT BANK TRANSFER ACCOUNT
  useEffect(() => {
    setCheckDepositBank(false)
    setLoading(true)
    const params = [
      { code: 'currency', value: appState?.currency || appState.user?.currency, graphqlType: 'String', required: true },
      { code: 'appearInWebsite', graphqlType: 'Boolean', required: false, value: true },
      { code: 'type', graphqlType: 'String', required: false, value: 'BANK' },
    ]
    query(
      {
        method: 'getBankOption',
        params,
        attributes: []
      },
    )
      .then(({ data }) => {
        // const filterBankTransfer = data['getBankOption']?.filter((bankAccount) =>
        //   bankAccount.appear_in_web === true
        // );
        console.log('filterBankTransfer :', data['getBankOption'])
        console.log('bankAccount:', params)
        console.log('appState.currency', appState?.currency)
        console.log('appState.user?.currency', appState.user?.currency)

        bankTransferDispatch({ type: BANK_TRANSFER_ACTION.SET_BANK_ACCOUNTS, payload: data['getBankOption'] })
        setCheckDepositBank(true)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        setCheckDepositBank(true)

        console.error(error)
        if (error?.networkError?.statusCode === 401) {
          appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })

        } else {
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: error.message.toString(), typeAlert: 'error' }
          });
        }
      })
  }, [query, appState.user?.currency, appState.currency, appDispatch, navigate])

  // GET ALL ACTIVE GAME PROVIDER
  useEffect(() => {
    setLoading(true)
    if (typeof appState.user?.username !== 'undefined') {


      let initialdepositqueries = [
        {
          index: 'getGameTypes',
          method: 'getGameTypes',
          params: [
            { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { status: 'ACTIVE', game_provider: `GS` }, order: [['updated_at', 'DESC']] } }
          ],
          attributes: [['type', 'code'], '_label']
        }, {
          index: 'getGameProviders',
          method: 'getGameProviders',
          params: [
            { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { '$_game_provider_game_types.status$': 'ACTIVE', status: `ACTIVE` }, order: [['name', 'ASC']] } },
          ],
          attributes: ['code', 'name', ['_game_provider_game_types', ['type']]]
        }]

      if (appState?.gameWallet?.length > 0) {
        initialdepositqueries.push({ index: 'getAllGamePrivoderBalanceByMember', method: 'getAllGamePrivoderBalanceByMember', params: [], attributes: [] })
      }

      queries(initialdepositqueries)
        .then(({ data }) => {

          setLoading(false)

          const checkAllGameProviderBalance = data['getGameProviders']?.map(item => {
            const checkGameProviderBalance = data['getAllGamePrivoderBalanceByMember']
              ? data['getAllGamePrivoderBalanceByMember'].find(e => e.code === item.code)
              : null;
            return {
              ...item,
              balance: checkGameProviderBalance ? checkGameProviderBalance.balance : 0

            };
          });

          const groups = data['getGameTypes']
          const providersData = checkAllGameProviderBalance


          const sortedData = groups.map(type => {
            let getTypeName = GAME_MENU_DATA[type.code] ?? ''
            const options = providersData
              .filter(provider => provider._game_provider_game_types.some(gameType => gameType.type === type.code))
              .map(provider => ({ code: provider.code, name: provider.name, locked: provider.locked, balance: provider.balance }));
            return { code: type.code, label: getTypeName.name, options };
          });

          bankTransferDispatch({ type: QUICK_PAY_ACTION.SET_GAME_PROVIDERS, payload: sortedData })

        }).catch((error, data) => {
          console.log('error: ', error)


          setLoading(false)

          if (error?.networkError?.statusCode === 401) {
            appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
          } else {
            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: error.message.toString(), typeAlert: 'error' }
            });
          }

        })
    }



  }, [queries, appState.user?.username, appDispatch, appState?.gameWallet]);

  // GET PROMOTION
  useEffect(() => {
    if (bankTransferState.selectedTransferToGameProvider !== 'MAIN_WALLET') {
      let getPromoParam = [
        { code: 'member', graphqlType: 'String', required: false, value: appState.user?.username },
        { code: 'language', graphqlType: 'String', required: true, value: i18n.resolvedLanguage },
        { code: 'fromDeposit', graphqlType: 'Boolean', required: false, value: true },
      ]


      if (bankTransferState.selectedTransferToGameProvider !== '') {
        getPromoParam.push({ code: 'gameProvider', graphqlType: 'String', required: false, value: bankTransferState.selectedTransferToGameProvider })
      }

      queries([
        {
          index: 'getPromotions',
          method: 'getPromotions',
          params: getPromoParam,
          attributes: []
        },])
        .then(({ data }) => {
          console.log('promotion123: ', data['getPromotions'])

          bankTransferDispatch({ type: BANK_TRANSFER_ACTION.SET_PROMOTIONS, payload: data['getPromotions'] })
          // setLoading(false)

        }).catch((error) => {
          console.log('error: ', error)
          setLoading(false)
          if (error?.networkError?.statusCode === 401) {
            appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
          } else {
            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: error.message.toString(), typeAlert: 'error' }
            });
          }
        })
    } else {
      bankTransferDispatch({ type: BANK_TRANSFER_ACTION.SET_PROMOTIONS, payload: [] })
      bankTransferDispatch({ type: BANK_TRANSFER_ACTION.SET_PROMOTION_CODE, payload: '' })
      bankTransferDispatch({ type: BANK_TRANSFER_ACTION.SET_SELECTED_PROMOTION, payload: { id: '', name: '' } })
    }


  }, [queries, bankTransferState.selectedTransferToGameProvider, appState.user?.username, i18n.resolvedLanguage, appDispatch]);


  useEffect(() => {
    if (bankTransferState.selectedbankAccount?.code !== '' && (bankTransferState.depositAmount >= parseFloat(bankTransferState.selectedBankAccount?.min_deposit > 0 ? bankTransferState.selectedBankAccount?.min_deposit : (appState.currencyFormat?.min_deposit_amount ?? 0)) && bankTransferState.depositAmount <= parseFloat(bankTransferState.selectedBankAccount?.max_deposit > 0 ? bankTransferState.selectedBankAccount?.max_deposit : (appState.currencyFormat?.max_deposit_amount ?? 0))) && bankTransferState.selectedDepositReceipt !== '') {
      setDisabledSubmit(false)
    }
    else {
      setDisabledSubmit(true)
    }

  }, [bankTransferState.selectedbankAccount?.code, bankTransferState.depositAmount, bankTransferState.selectedDepositReceipt, appState.currencyFormat?.min_deposit_amount, appState.currencyFormat?.max_deposit_amount, bankTransferState.selectedBankAccount?.min_deposit, bankTransferState.selectedBankAccount?.max_deposit])


  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <>
      <Form className="banktransfer_form">
        <div className="d-none">
          bank account{bankTransferState.bankAccounts?.length}
          <br />
          select bank account{bankTransferState.selectedbankAccount?.code}
          <br />
          selectedBankAccount min deposit{bankTransferState.selectedBankAccount?.min_deposit}<br />
          currencyFormat min deposit{appState.currencyFormat?.min_deposit_amount}  <br />
          selectedBankAccount max deposit {bankTransferState.selectedBankAccount?.max_deposit} <br />
          currencyFormat max deposit{appState.currencyFormat?.max_deposit_amount}  <br />
          <br />
          last get min deposit:{parseFloat(bankTransferState.selectedBankAccount?.min_deposit > 0 ? bankTransferState.selectedBankAccount?.min_deposit : (appState.currencyFormat?.min_deposit_amount ?? 0)).toLocaleString(appState.currencyFormat?.locale)}
          <br />
          last get max deposit:{parseFloat(bankTransferState.selectedBankAccount?.max_deposit > 0 ? bankTransferState.selectedBankAccount?.max_deposit : (appState.currencyFormat?.max_deposit_amount ?? 0)).toLocaleString(appState.currencyFormat?.locale)}
        </div>
        <Form.Group className="form_input_wrap" >
          <div className="input_wrap">
            <Form.Label>{t('deposit_method')}
              <span className="form_required_input">* {t('required_select')}</span>{bankTransferState.selectedDepositMethod?.id !== '' && (<AiOutlineCheckCircle className="ps-1" />)}
            </Form.Label>
            <Form.Select aria-label="depositMethod" className="input_depositMethod" value={bankTransferState.selectedDepositMethod?.id} onChange={(evt) => { onChangeDepositMethod(evt.target.value) }}>
              {bankTransferState.depositMethod.map(function (item, index) {
                // const _item = DEPOSIT_METHOD_DATA[item.name];
                return (
                  <option value={item.id} key={item.id}>{t(item.name)}</option>
                )
              })}
            </Form.Select>
          </div>
        </Form.Group>
        <Form.Group className="form_input_wrap">
          <div className="input_wrap">
            <Form.Label>{t('deposit_bank')}
              <span className="form_required_input">* {t('required_select')}</span>{(bankTransferState.selectedbankAccount?.code !== '') && (<AiOutlineCheckCircle className="ps-1" />)}
            </Form.Label>
            {bankTransferState.bankAccounts?.filter(item => bankTransferState.selectedDepositMethod?.id !== 'QR_CODE' || (bankTransferState.selectedDepositMethod?.id === 'QR_CODE' && item.qr_image_url)).length ? (<>
              <div className="depositBankImg">
                {bankTransferState.bankAccounts?.filter(item => bankTransferState.selectedDepositMethod?.id !== 'QR_CODE' || (bankTransferState.selectedDepositMethod?.id === 'QR_CODE' && item.qr_image_url)).map(function (item, index) {

                  return (
                    <div className={`depositBankImg_col ${bankTransferState.selectedBankAccount?.code === item.code ? 'active' : ''}`} key={item.code} onClick={() => onSelectBankAccount(item.code)}>
                      <img src={`${GLOBAL_BUCKET}/bank/${appState.currency || appState.user?.currency}/${item.bank}.png`} alt={`bank icon ${item.bank}`} />
                      <span>{item._bank.name}</span>
                    </div>
                  )
                })}
              </div>
              <div className="deposit_banktransfer_info_container">
                {bankTransferState.selectedBankAccount?.qr_image_url && (<div className="displayQR_row">
                  <img src={`${bankTransferState.selectedBankAccount?.qr_image_url}`} alt={`${bankTransferState.selectedBankAccount?.bank}`} onClick={() => onClickImage(bankTransferState.selectedBankAccount?.qr_image_url)} />
                  <label>*{t('required_scan')}</label>
                </div>)}

                {bankTransferState.selectedDepositMethod?.id !== 'QR_CODE' && (<div className="deposit_banktransfer_info">
                  <div className="deposit_banktransfer_info_row">
                    <Form.Control
                      type="text"
                      className="input_depositBankAccount"
                      value={bankTransferState.selectedBankAccount?.code ?? ''}
                      disabled
                    />
                    <AiFillCopy onClick={() => copyText(bankTransferState.selectedBankAccount?.code ?? '')} />
                  </div>
                  <div className="deposit_banktransfer_info_row"> <Form.Control
                    type="text"
                    className="input_depositBankAccountName my-1"
                    value={bankTransferState.selectedBankAccount?.name ?? ''}
                    as="textarea"
                    disabled
                  />
                    <AiFillCopy onClick={() => copyText(bankTransferState.selectedBankAccount?.name ?? '')} />
                  </div>
                </div>)}
              </div>
            </>) : (checkDepositBank && (<img src={`${TENANT_BUCKET}/icon/under_maintenance_1_${i18n.resolvedLanguage}.png`} alt={`under maintenance`} className={`maintenance_img`} />))}

          </div>
        </Form.Group>
        <Form.Group className="form_input_wrap" >
          <div className="input_wrap">
            <Form.Label>{t('deposit_amount')}
              <span className="form_required_input">* {t('required_fill_in')}</span>{(bankTransferState.depositAmount >= parseFloat(bankTransferState.selectedBankAccount?.min_deposit > 0 ? bankTransferState.selectedBankAccount?.min_deposit : (appState.currencyFormat?.min_deposit_amount ?? 0)) && bankTransferState.depositAmount <= parseFloat(bankTransferState.selectedBankAccount?.max_deposit > 0 ? bankTransferState.selectedBankAccount?.max_deposit : (appState.currencyFormat?.max_deposit_amount ?? 0))) && (<AiOutlineCheckCircle className="ps-1" />)}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={checkDepositBank && (t('deposit_banktransfer_amount_placeholder', { "min": parseFloat(bankTransferState.selectedBankAccount?.min_deposit > 0 ? bankTransferState.selectedBankAccount?.min_deposit : (appState.currencyFormat?.min_deposit_amount ?? 0)).toLocaleString(appState.currencyFormat?.locale), "max": parseFloat(bankTransferState.selectedBankAccount?.max_deposit > 0 ? bankTransferState.selectedBankAccount?.max_deposit : (appState.currencyFormat?.max_deposit_amount ?? 0)).toLocaleString(appState.currencyFormat?.locale) }))}
              className="input_depositAmount"
              aria-label="depositAmount"
              value={bankTransferState.depositAmount === 0 ? '' : bankTransferState.depositAmount}
              onChange={(evt) => { onChangeDepositAmount(evt.target.value) }}
              onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
              autoComplete="off"
              onInput={(e) => {
                let value = appState.currencyFormat.decimal_point === 0
                  ? e.target.value.replace(/[^0-9]/g, '')
                  : e.target.value.replace(/[^0-9.]/g, '');

                if (value.split('.').length - 1 > 1) {
                  value = value.substring(0, value.lastIndexOf('.'));
                }

                const decimal_part = value.split(".")[1];
                if (decimal_part && decimal_part.length > appState.currencyFormat.decimal_point) {
                  value = value.slice(0, -1);
                }
                e.target.value = value;
                // e.target.value = e.target.value.replace(/[^0-9]/g, '');
              }}
            />

            {checkDepositBank && (<div className="info_input_wrap">
              <FaInfoCircle /> <span>{t('deposit_banktransfer_amount_placeholder', { "min": parseFloat(bankTransferState.selectedBankAccount?.min_deposit > 0 ? bankTransferState.selectedBankAccount?.min_deposit : (appState.currencyFormat?.min_deposit_amount ?? 0)).toLocaleString(appState.currencyFormat?.locale), "max": parseFloat(bankTransferState.selectedBankAccount?.max_deposit > 0 ? bankTransferState.selectedBankAccount?.max_deposit : (appState.currencyFormat?.max_deposit_amount ?? 0)).toLocaleString(appState.currencyFormat?.locale) })}</span>
            </div>)}
            <div type="radio" name="options" className="wallet_btnAmount" >
              {/* {defaultDepositAmountSelection.map(function (item, index) {
                return (
                  <button value={item} key={index} type="button" onClick={() => onChangeDepositAmount(item)} className={`${bankTransferState.depositAmount === item ? 'active color_active' : ''}`}>{parseFloat(item).toLocaleString("en-US")}</button>
                )
              })} */}

              {appState.currencyFormat.default_deposit_amounts?.map(function (item, index) {
                return (
                  <button value={item.value} key={index} type="button" onClick={() => onChangeDepositAmount(item.value)} className={`${parseFloat(bankTransferState.depositAmount) === item.value ? 'active color_active' : ''}`}>{item.text}</button>
                )
              })}
            </div>

          </div>
        </Form.Group>
        <Form.Group className="form_input_wrap"  >
          <div className="input_wrap">
            <Form.Label>{t('upload_receipt')}
              <span className="form_required_input">* {t('required_upload')}</span>{bankTransferState.selectedDepositReceipt !== '' && (<AiOutlineCheckCircle className="ps-1" />)}
            </Form.Label>
            <section {...getRootProps({ className: 'uploadImageContainer' })}>
              <input {...getInputProps()} />
              <div className={`${files.length > 0 ? ('d-none') : 'beforeUploadImage'}`}>
                <AiOutlinePlus />
                <span>{t('upload')}</span>
              </div>
              <div className="afterUploadImageContainer">
                <div className="afterUploadImage">
                  {files?.map(function (item, index) {
                    return (
                      <img
                        src={item.preview}
                        key={item.name}
                        // Revoke data uri after image is loaded
                        onLoad={() => { URL.revokeObjectURL(item.preview) }}
                        alt=""
                      />
                    )
                  })}
                </div>
              </div>
            </section>
          </div>
        </Form.Group>
        {/* TRANSFER */}
        <div className='deposit_transferContainer'>
          <Form.Group className="form_input_wrap" >
            <div className="input_wrap">
              <Form.Label>{t('transfer_to')}
                <span className="form_required_input">* {t('optional')}</span>
              </Form.Label>
              {/* <Form.Select aria-label="transferTo" className="input_transferTo" value={bankTransferState.selectedTransferToGameProvider} onChange={(evt) => { onChangeTransferToGameProvider(evt.target.value) }}>
                <option hidden></option>
                {bankTransferState.selectedTransferFromGameProvider !== 'MAIN_WALLET' && (<option value={'MAIN_WALLET'}>{t('main_wallet')}</option>)}
                {bankTransferState.gameProviders.map((provider) => (
                  <optgroup key={provider.label} label={t(provider.label)}>
                    {provider?.options?.filter(item => item.code !== bankTransferState.selectedTransferFromGameProvider).map((option) => (
                      <option key={option.code} value={option.code}>{option.name}</option>
                    ))}
                  </optgroup>
                ))}
              </Form.Select> */}
              <div className="selectProviderContainer" ref={toContainerRef}>
                <div className="form-select" onClick={() => { onClickShowSelectProvider() }}>
                  {bankTransferState.selectedTransferToGameProvider === 'MAIN_WALLET' ? t('main_wallet') :
                    bankTransferState.gameProviders.flatMap(item => item.options)
                      .find(option => option.code === bankTransferState.selectedTransferToGameProvider)
                      ?.name || ''
                  }</div>
                {showToProviderSelection && (<ProviderSelector section={'DEPOSIT'} onSelect={onChangeTransferToGameProvider} data={bankTransferState.gameProviders} currentProvider={''} otherProvider={''} />)}
              </div>
            </div>
          </Form.Group>
          {((bankTransferState.selectedTransferToGameProvider !== "MAIN_WALLET") && !appState.disablePromotion) && (
            <>
              <Form.Group className="form_input_wrap " >
                <div className="input_wrap">
                  <Form.Label>{t('promotion')}
                    <span className="form_required_input">* {t('optional')}</span>
                  </Form.Label>
                  <Form.Select aria-label="transferPromotion" className="input_transferPromotion" value={bankTransferState.selectedPromotion?.id} onChange={(evt) => { onChangePromotion(evt.target.value) }} disabled={bankTransferState.promotionCode?.length > 0 ? true : false}>
                    {bankTransferState.promotions?.filter(item => item.appear_in_website === true)?.length === 0 ? (<option hidden>No Promo Available</option>) : (<option value="">No Promotion</option>)}
                    {bankTransferState.promotions?.filter(item => item.appear_in_website === true).map(function (item, index) {
                      return (
                        <option value={item.id} key={item.id}>{item.title ?? item.name} {item.code && `(${item.code})`}</option>
                      )
                    })}
                  </Form.Select>
                </div>
              </Form.Group>

              <Form.Group className="form_input_wrap " >
                <div className="input_wrap">
                  <Form.Label>{t('promotion_code')}
                    <span className="form_required_input">* {t('optional')}</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="input_promotionCode"
                    value={bankTransferState.promotionCode ?? ''}
                    onChange={(evt) => { onChangePromotionCode(evt.target.value) }}
                    aria-label="promotionCode"
                    disabled={bankTransferState.selectedPromotion?.id !== '' ? true : false}
                  />
                </div>
              </Form.Group></>)}
        </div>
        <input className="font_button color_button wallet_btnSubmit" type='button' disabled={disabledSubmit} value={t('submit')} onClick={handleSubmit} />
      </Form>
      {/* <Alert show={alertShow.show} onHide={onHideAlert} message={alertShow.message} type={alertShow.type} title={alertShow.title} /> */}
      {isLoading && (<Loading />)}
      {<Modal show={showContactImgDialog}
        onHide={() => setShowContactImgDialog(false)}
        id="contactImgDialog"
        centered >
        <Modal.Body closeButton>
          <img src={currentImage} alt={'QR_IMAGE'} />
        </Modal.Body>
      </Modal>
      }
    </>
  )
}


const Reload = () => {
  const appDispatch = useStoreDispatch()
  const appState = useStoreState();
  const { query, mutation, queries } = useMiddletier();
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const { t, i18n } = useTranslation();
  const [reloadState, reloadDispatch] = useReducer(reloadReducer, initialReloadData)
  const [isLoading, setLoading] = useState(false);
  const [checkDepositBank, setCheckDepositBank] = useState(false);
  const [showToProviderSelection, setShowToProviderSelection] = useState(false);
  const toContainerRef = useRef(null);
  let isProcessing = false;


  // const navigate = useNavigate();

  const onChangeTelco = (newValue) => {
    reloadDispatch({ type: RELOAD_ACTION.SET_SELECTED_TELCO, payload: newValue })
  }

  const onChangeReloadAmount = (newValue) => {
    reloadDispatch({ type: RELOAD_ACTION.SET_RELOAD_AMOUNT, payload: newValue })
  }

  const onChangeReloadPin = (newValue) => {
    reloadDispatch({ type: RELOAD_ACTION.SET_RELOAD_PIN, payload: newValue })
  }

  const onChangeTransferToGameProvider = (newValue) => {
    reloadDispatch({ type: RELOAD_ACTION.SET_SELECTED_TRANSFER_TO_GAME_PROVIDER, payload: newValue })
    setShowToProviderSelection(false)

  }

  const onChangePromotion = (newValue) => {
    reloadDispatch({ type: RELOAD_ACTION.SET_SELECTED_PROMOTION, payload: newValue })
  }

  const onChangePromotionCode = (newValue) => {
    reloadDispatch({ type: RELOAD_ACTION.SET_PROMOTION_CODE, payload: newValue })
    // transferDispatch({ type: TRANSFER_ACTION.SET_SELECTED_PROMOTION, payload: newValue })
  }

  const onClickShowSelectProvider = () => {

    if (showToProviderSelection) {
      setShowToProviderSelection(false)
    } else {
      setShowToProviderSelection(true)
    }

  }

  const handleClickOutside = (event) => {

    if (toContainerRef.current && !toContainerRef.current.contains(event.target)) {
      setShowToProviderSelection(false)
    }
  };

  useEffect(() => {

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  // CLEAR RELOAD INPUT
  const clearInput = () => {
    reloadState.telco?.length > 0 && (reloadDispatch({ type: RELOAD_ACTION.SET_SELECTED_TELCO, payload: reloadState.telco[0]?.code }))
    reloadDispatch({ type: RELOAD_ACTION.SET_RELOAD_AMOUNT, payload: 0 })
    reloadDispatch({ type: RELOAD_ACTION.SET_RELOAD_PIN, payload: '' })
    reloadDispatch({ type: RELOAD_ACTION.SET_SELECTED_TRANSFER_TO_GAME_PROVIDER, payload: '' })
    reloadDispatch({ type: RELOAD_ACTION.SET_PROMOTION_CODE, payload: '' })
  }

  // DEPOSIT (RELOAD) SUBMIT
  const handleSubmit = () => {
    if (isLoading || isProcessing) return;

    isProcessing = true;
    setLoading(true)
    const method = 'depositMainWalletByMember'

    let params = [
      // { code: 'member', graphqlType: 'String', required: true, value: appState.user?.username },
      // { code: 'transaction_type', graphqlType: 'String', required: true, value: 'DEPOSIT' },
      { code: 'deposit_method', graphqlType: 'String', required: false, value: 'RELOAD' },
      { code: 'currency', graphqlType: 'String', required: true, value: appState.user?.currency ?? appState.currency },
      { code: 'amount', graphqlType: 'Float', required: true, value: parseFloat(reloadState.reloadAmount) },
      { code: 'reload_channel', graphqlType: 'String', required: false, value: reloadState.selectedTelco?.code },
      { code: 'reload_pin', graphqlType: 'String', required: false, value: reloadState.reloadPin },
      { code: 'force_withdraw', graphqlType: 'Boolean', required: true, value: false },
    ]

    if (reloadState.selectedTransferToGameProvider !== 'MAIN_WALLET' && reloadState.selectedTransferToGameProvider !== '') {
      params.push({ code: 'game_provider', graphqlType: 'String', required: false, value: reloadState.selectedTransferToGameProvider })
    }


    // CHECK PROMOTION
    if (reloadState.selectedPromotion?.id !== '' || reloadState.promotionCode !== '') {
      if (reloadState.promotionCode !== '') {
        const checkPromotionCode = reloadState.promotionCode?.trim()
        let promoCodeExist = reloadState.promotions.find((promotions) => promotions.code === checkPromotionCode)
        if (typeof promoCodeExist === 'undefined') {
          setLoading(false)
          isProcessing = false;
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: `Promotion Code[${checkPromotionCode}] Is NOT EXIST`, typeAlert: 'error' }
          });

          return
        }
        else {
          params.push({ code: 'promotion', graphqlType: 'String', required: false, value: promoCodeExist.id })
        }
      } else {
        params.push({ code: 'promotion', graphqlType: 'String', required: false, value: reloadState.selectedPromotion.id })
      }

    }

    mutation([{
      method,
      params,
      attributes: []

    }])
      .then(({ data }) => {
        // setLoading(false)


        if (appState?.pixelID !== '' && typeof appState?.pixelID !== 'undefined') {
          window.fbq('trackSingle', appState?.pixelID, 'Purchase', {
            currency: appState?.currency,
            value: parseFloat(reloadState.reloadAmount),
            content_ids: [`${data[method]?.id}`]
          });
        }

        let afterDepositqueries = [
          {
            index: 'member',
            method: 'member',
            params: [
              { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
            ],
            attributes: ['wallet_amount']
          }]

        if (appState?.gameWallet?.length > 0) {
          afterDepositqueries.push({ index: 'getAllGamePrivoderBalanceByMember', method: 'getAllGamePrivoderBalanceByMember', params: [], attributes: [] })
        }


        queries(afterDepositqueries)
          .then(({ data }) => {
            appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
            appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data['member'].wallet_amount })
            setLoading(false)
            isProcessing = false;

            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: `Deposit Successful`, typeAlert: 'success', forwardUrl: '/wallet/history' }
            });


          }).catch((error) => {
            console.log('error: ', error)
            setLoading(false)
            isProcessing = false;

            if (error?.networkError?.statusCode === 401) {
              appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
            } else {
              appDispatch({
                type: APP_STORE_ACTION.SHOW_ALERT,
                payload: { description: error.message.toString(), typeAlert: 'error' }
              });
            }
          })


        clearInput()
      })
      .catch((error) => {
        setLoading(false)
        isProcessing = false;
        console.log('error: ', error)
        if (error?.networkError?.statusCode === 401) {
          appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })

        } else {
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: error.message.toString(), typeAlert: 'error' }
          });
        }
      })
  };



  useEffect(() => {
    setCheckDepositBank(false)
    query(
      {
        method: 'banks',
        params: [
          { code: 'filter', value: { where: { type: "TELCO", currency: appState.user?.currency ?? appState.currency, status: "ACTIVE" } }, graphqlType: 'JSON', required: true }
        ],
        attributes: ['code', ['_label', 'name'], 'currency']
      },
    )
      .then(({ data }) => {
        reloadDispatch({ type: RELOAD_ACTION.SET_TELCO, payload: data['banks'] })
        setCheckDepositBank(true)
      })
      .catch((error) => {
        console.error(error)
      })
  }, [query, appState.user?.currency, appState.currency])

  // GET ALL ACTIVE GAME PROVIDER
  useEffect(() => {
    setLoading(true)
    if (typeof appState.user?.username !== 'undefined') {


      let initialdepositqueries = [
        {
          index: 'getGameTypes',
          method: 'getGameTypes',
          params: [
            { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { status: 'ACTIVE', game_provider: `GS` }, order: [['updated_at', 'DESC']] } }
          ],
          attributes: [['type', 'code'], '_label']
        }, {
          index: 'getGameProviders',
          method: 'getGameProviders',
          params: [
            { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { '$_game_provider_game_types.status$': 'ACTIVE', status: `ACTIVE` }, order: [['name', 'ASC']] } },
          ],
          attributes: ['code', 'name', ['_game_provider_game_types', ['type']]]
        }]

      if (appState?.gameWallet?.length > 0) {
        initialdepositqueries.push({ index: 'getAllGamePrivoderBalanceByMember', method: 'getAllGamePrivoderBalanceByMember', params: [], attributes: [] })
      }

      queries(initialdepositqueries)
        .then(({ data }) => {

          setLoading(false)

          const checkAllGameProviderBalance = data['getGameProviders']?.map(item => {
            const checkGameProviderBalance = data['getAllGamePrivoderBalanceByMember']
              ? data['getAllGamePrivoderBalanceByMember'].find(e => e.code === item.code)
              : null;
            return {
              ...item,
              balance: checkGameProviderBalance ? checkGameProviderBalance.balance : 0

            };
          });

          const groups = data['getGameTypes']
          const providersData = checkAllGameProviderBalance


          const sortedData = groups.map(type => {
            let getTypeName = GAME_MENU_DATA[type.code] ?? ''
            const options = providersData
              .filter(provider => provider._game_provider_game_types.some(gameType => gameType.type === type.code))
              .map(provider => ({ code: provider.code, name: provider.name, locked: provider.locked, balance: provider.balance }));
            return { code: type.code, label: getTypeName.name, options };
          });

          reloadDispatch({ type: QUICK_PAY_ACTION.SET_GAME_PROVIDERS, payload: sortedData })

        }).catch((error, data) => {
          console.log('error: ', error)


          setLoading(false)

          if (error?.networkError?.statusCode === 401) {
            appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
          } else {
            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: error.message.toString(), typeAlert: 'error' }
            });
          }

        })
    }



  }, [queries, appState.user?.username, appDispatch, appState?.gameWallet]);

  // GET PROMOTION
  useEffect(() => {

    if (reloadState.selectedTransferToGameProvider !== 'MAIN_WALLET') {

      let getPromoParam = [
        { code: 'member', graphqlType: 'String', required: false, value: appState.user?.username },
        { code: 'language', graphqlType: 'String', required: true, value: i18n.resolvedLanguage },
        { code: 'fromDeposit', graphqlType: 'Boolean', required: false, value: true },
      ]


      if (reloadState.selectedTransferToGameProvider !== '') {
        getPromoParam.push({ code: 'gameProvider', graphqlType: 'String', required: false, value: reloadState.selectedTransferToGameProvider })
      }

      queries([
        {
          index: 'getPromotions',
          method: 'getPromotions',
          params: getPromoParam,
          attributes: []
        },])
        .then(({ data }) => {
          reloadDispatch({ type: RELOAD_ACTION.SET_PROMOTIONS, payload: data['getPromotions'] })
          // setLoading(false)

        }).catch((error) => {
          console.log('error: ', error)
          setLoading(false)
          if (error?.networkError?.statusCode === 401) {
            appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
          } else {
            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: error.message.toString(), typeAlert: 'error' }
            });
          }
        })
    } else {
      reloadDispatch({ type: RELOAD_ACTION.SET_PROMOTIONS, payload: [] })
      reloadDispatch({ type: RELOAD_ACTION.SET_PROMOTION_CODE, payload: '' })
      reloadDispatch({ type: RELOAD_ACTION.SET_SELECTED_PROMOTION, payload: { id: '', name: '' } })
    }

  }, [queries, reloadState.selectedTransferToGameProvider, appState.user?.username, i18n.resolvedLanguage, appDispatch]);

  useEffect(() => {
    if (reloadState.selectedTelco?.code !== '' && (reloadState.reloadAmount >= parseFloat(appState.currencyFormat?.min_deposit_amount ?? 0) && reloadState.reloadAmount <= parseFloat(appState.currencyFormat?.max_deposit_amount)) && reloadState.reloadPin !== '') {
      setDisabledSubmit(false)
    }
    else {
      setDisabledSubmit(true)
    }

  }, [reloadState.selectedTelco?.code, reloadState.reloadAmount, reloadState.reloadPin, appState.currencyFormat?.min_deposit_amount, appState.currencyFormat?.max_deposit_amount])


  return (
    <>
      <Form className="reload_form">
        <Form.Group className="form_input_wrap " >
          <div className="input_wrap">
            <Form.Label>{t('reload_channel')}
              <span className="form_required_input">* {t('required_select')}</span>
            </Form.Label>
            {reloadState.telco.length > 0 ? (
              <div className={`depositBank_col`}>
                <div className={`depositBankImg`}>
                  {reloadState.telco.map((item) => {
                    return (
                      <div className={`depositBankImg_col ${reloadState.selectedTelco?.code === item.code ? 'active' : ''}`} key={item.code} onClick={() => onChangeTelco(item.code)}>
                        <img src={`${GLOBAL_BUCKET}/bank/${item.currency}/${item.code}.png`} alt={`bank icon ${item.name}`} />
                        <span>{item.name}</span>
                      </div>
                    )
                  })}
                </div>
              </div>
            ) :
              (checkDepositBank && (<img src={`${TENANT_BUCKET}/icon/under_maintenance_1_${i18n.resolvedLanguage}.png`} alt={`under maintenance`} className={`maintenance_img`} />))}
          </div>
        </Form.Group>
        <Form.Group className="form_input_wrap" >
          <div className="input_wrap">
            <Form.Label>{t('reload_amount')}
              <span className="form_required_input">* {t('required_fill_in')}</span>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={checkDepositBank && (t('deposit_reload_amount_placeholder', { "min": parseFloat(appState.currencyFormat?.min_deposit_amount ?? 0).toLocaleString(appState.currencyFormat?.locale), "max": parseFloat(appState.currencyFormat?.max_deposit_amount ?? 0).toLocaleString(appState.currencyFormat?.locale) }))}
              className="input_reloadAmount"
              aria-label="reloadAmount"
              value={reloadState.reloadAmount === 0 ? '' : reloadState.reloadAmount}
              onChange={(evt) => { onChangeReloadAmount(evt.target.value) }}
              onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
              autoComplete="off"
              onInput={(e) => {
                let value = appState.currencyFormat.decimal_point === 0
                  ? e.target.value.replace(/[^0-9]/g, '')
                  : e.target.value.replace(/[^0-9.]/g, '');

                if (value.split('.').length - 1 > 1) {
                  value = value.substring(0, value.lastIndexOf('.'));
                }

                const decimal_part = value.split(".")[1];
                if (decimal_part && decimal_part.length > appState.currencyFormat.decimal_point) {
                  value = value.slice(0, -1);
                }
                e.target.value = value;
                // e.target.value = e.target.value.replace(/[^0-9]/g, '');
              }}
            />
            {checkDepositBank && (<div className="info_input_wrap">
              <FaInfoCircle /> <span>{t('deposit_reload_amount_placeholder', { "min": parseFloat(appState.currencyFormat?.min_deposit_amount ?? 0).toLocaleString(appState.currencyFormat?.locale), "max": parseFloat(appState.currencyFormat?.max_deposit_amount ?? 0).toLocaleString(appState.currencyFormat?.locale) })}</span>
            </div>)}
            <div type="radio" name="options" className="wallet_btnAmount" >
              {appState.currencyFormat.default_deposit_amounts?.map(function (item, index) {
                return (
                  <button value={item.value} key={index} type="button" onClick={() => onChangeReloadAmount(item.value)} className={`${parseFloat(reloadState.reloadAmount) === item.value ? 'active color_active' : ''}`}>{item.value}</button>
                )
              })}
            </div>
          </div>
        </Form.Group>
        <Form.Group className="form_input_wrap" >
          <div className="input_wrap">
            <Form.Label>{t('reload_pin')}
              <span className="form_required_input">* {t('required_fill_in')}</span>
            </Form.Label>
            <Form.Control
              type="text"
              className="input_reloadPin"
              aria-label="reloadPin"
              value={reloadState.reloadPin ?? ''}
              onChange={(evt) => { onChangeReloadPin(evt.target.value) }}
              autoComplete="off"
            />
          </div>
        </Form.Group>
        {/* TRANSFER */}
        <div className='deposit_transferContainer'>
          <Form.Group className="form_input_wrap" >
            <div className="input_wrap">
              <Form.Label>{t('transfer_to')}
                <span className="form_required_input">* {t('optional')}</span>
              </Form.Label>
              {/* <Form.Select aria-label="transferTo" className="input_transferTo" value={reloadState.selectedTransferToGameProvider} onChange={(evt) => { onChangeTransferToGameProvider(evt.target.value) }}>
                <option hidden></option>
                {reloadState.selectedTransferFromGameProvider !== 'MAIN_WALLET' && (<option value={'MAIN_WALLET'}>{t('main_wallet')}</option>)}
                {reloadState.gameProviders.map((provider) => (
                  <optgroup key={provider.label} label={t(provider.label)}>
                    {provider?.options?.filter(item => item.code !== reloadState.selectedTransferFromGameProvider).map((option) => (
                      <option key={option.code} value={option.code}>{option.name}</option>
                    ))}
                  </optgroup>
                ))}
              </Form.Select> */}

              <div className="selectProviderContainer" ref={toContainerRef}>
                <div className="form-select" onClick={() => { onClickShowSelectProvider() }}>
                  {reloadState.selectedTransferToGameProvider === 'MAIN_WALLET' ? t('main_wallet') :
                    reloadState.gameProviders.flatMap(item => item.options)
                      .find(option => option.code === reloadState.selectedTransferToGameProvider)
                      ?.name || ''
                  }</div>
                {showToProviderSelection && (<ProviderSelector section={'DEPOSIT'} onSelect={onChangeTransferToGameProvider} data={reloadState.gameProviders} currentProvider={''} otherProvider={''} />)}
              </div>
            </div>
          </Form.Group>
          {((reloadState.selectedTransferToGameProvider !== "MAIN_WALLET") && !appState.disablePromotion) && (
            <>
              <Form.Group className="form_input_wrap " >
                <div className="input_wrap">
                  <Form.Label>{t('promotion')}
                    <span className="form_required_input">* {t('optional')}</span>
                  </Form.Label>
                  <Form.Select aria-label="transferPromotion" className="input_transferPromotion" value={reloadState.selectedPromotion?.id} onChange={(evt) => { onChangePromotion(evt.target.value) }} disabled={reloadState.promotionCode?.length > 0 ? true : false}>
                    {reloadState.promotions?.filter(item => item.appear_in_website === true)?.length === 0 ? (<option hidden>No Promo Available</option>) : (<option value="">No Promotion</option>)}
                    {reloadState.promotions?.filter(item => item.appear_in_website === true).map(function (item, index) {
                      return (
                        <option value={item.id} key={item.id}>{item.title ?? item.name} {item.code && `(${item.code})`}</option>
                      )
                    })}
                  </Form.Select>
                </div>
              </Form.Group>

              <Form.Group className="form_input_wrap " >
                <div className="input_wrap">
                  <Form.Label>{t('promotion_code')}
                    <span className="form_required_input">* {t('optional')}</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="input_promotionCode"
                    value={reloadState.promotionCode ?? ''}
                    onChange={(evt) => { onChangePromotionCode(evt.target.value) }}
                    aria-label="promotionCode"
                    disabled={reloadState.selectedPromotion?.id !== '' ? true : false}
                  />
                </div>
              </Form.Group></>)}
        </div>
        <input className="font_button color_button wallet_btnSubmit" type='button' disabled={disabledSubmit} value={t('submit')} onClick={handleSubmit} />
      </Form>
      {/* <Alert show={alertShow.show} onHide={onHideAlert} message={alertShow.message} type={alertShow.type} title={alertShow.title} /> */}
      {isLoading && (<Loading />)}
    </>
  )
}

const Qrpay = () => {
  const appDispatch = useStoreDispatch()
  const appState = useStoreState();
  const { query, mutation, queries } = useMiddletier();
  const [disabledSubmit, setDisabledSubmit] = useState(true)
  const { t, i18n } = useTranslation();
  const [qrPayState, qrPayDispatch] = useReducer(qrPayReducer, initialQrPayData)
  const [files, setFiles] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showContactImgDialog, setShowContactImgDialog] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [checkDepositBank, setCheckDepositBank] = useState(false);
  const [showToProviderSelection, setShowToProviderSelection] = useState(false);
  const toContainerRef = useRef(null);
  const navigate = useNavigate();
  let isProcessing = false;


  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': [],
      'image/jpeg': []
    },
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
      setLoading(true)

      const acceptedFileTypes = ['image/jpeg', 'image/png'];
      const isAcceptedFileType = acceptedFileTypes.includes(acceptedFiles[0]?.type);
      const isLt8MB = acceptedFiles[0].size / 1024 / 1024 < 8;

      if (isAcceptedFileType&&isLt8MB) {

      let params = [
        { code: 'folder', value: 'depositQrPay', graphqlType: 'String', required: false },
        { code: 'file', value: acceptedFiles[0], graphqlType: 'Upload', required: true }
      ]
      mutation([
        {
          method: 'singleUpload',
          params,

        }
      ])
        .then(({ data }) => {
          setLoading(false)
          qrPayDispatch({ type: QRPAY_ACTION.SET_SELECTED_DEPOSIT_RECEIPT, payload: data['singleUpload'].id })
        })
        .catch((error) => {
          setFiles([])
          setLoading(false)
          console.error(error)
          if (error?.networkError?.statusCode === 401) {
            appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })

          } else {
            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: error.message.toString(), typeAlert: 'error' }
            });
          }
        })
      } else {
        setFiles([])
        setLoading(false)
        if (!isAcceptedFileType) {
            return appDispatch({
                type: APP_STORE_ACTION.SHOW_ALERT,
                payload: { description: 'Only JPEG and PNG image formats are supported. Please check your file type and try again.', typeAlert: 'error' }
            });
        }
        if (!isLt8MB) {
            return appDispatch({
                type: APP_STORE_ACTION.SHOW_ALERT,
                payload: { description: 'Image size must below 8MB', typeAlert: 'error' }
            });
        }

    }


    }
  });

  // const onChangeDepositMethod = (newValue) => {
  //   qrPayDispatch({ type: QRPAY_ACTION.SET_SELECTED_DEPOSIT_METHOD, payload: newValue })
  // }

  const onSelectBankAccount = (newValue) => {
    qrPayDispatch({ type: QRPAY_ACTION.SET_SELECTED_BANK_ACCOUNT, payload: newValue })
  }

  const onChangeDepositAmount = (newValue) => {
    qrPayDispatch({ type: QRPAY_ACTION.SET_DEPOSIT_AMOUNT, payload: newValue })
  }

  const onChangeTransferToGameProvider = (newValue) => {
    qrPayDispatch({ type: QRPAY_ACTION.SET_SELECTED_TRANSFER_TO_GAME_PROVIDER, payload: newValue })
    setShowToProviderSelection(false)


  }

  const onChangePromotion = (newValue) => {
    qrPayDispatch({ type: QRPAY_ACTION.SET_SELECTED_PROMOTION, payload: newValue })
  }

  const onChangePromotionCode = (newValue) => {
    qrPayDispatch({ type: QRPAY_ACTION.SET_PROMOTION_CODE, payload: newValue })
    // transferDispatch({ type: TRANSFER_ACTION.SET_SELECTED_PROMOTION, payload: newValue })
  }

  const onClickShowSelectProvider = () => {

    if (showToProviderSelection) {
      setShowToProviderSelection(false)
    } else {
      setShowToProviderSelection(true)
    }

  }

  const handleClickOutside = (event) => {

    if (toContainerRef.current && !toContainerRef.current.contains(event.target)) {
      setShowToProviderSelection(false)
    }
  };

  useEffect(() => {

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  // const copyText = (newValue) => {
  //   navigator.clipboard.writeText(newValue);

  //   appDispatch({
  //     type: APP_STORE_ACTION.SHOW_ALERT,
  //     payload: { description: `Copied ${newValue}`, typeAlert: 'success' }
  //   });
  // };

  const clearInput = () => {
    qrPayState.depositMethod?.length > 0 && (qrPayDispatch({ type: QRPAY_ACTION.SET_SELECTED_DEPOSIT_METHOD, payload: qrPayState.depositMethod[0]?.id }))
    qrPayState.bankAccounts?.length > 0 && (qrPayDispatch({ type: QRPAY_ACTION.SET_SELECTED_BANK_ACCOUNT, payload: qrPayState.bankAccounts[0]?.code }))
    qrPayDispatch({ type: QRPAY_ACTION.SET_DEPOSIT_AMOUNT, payload: 0 })
    qrPayDispatch({ type: QRPAY_ACTION.SET_SELECTED_DEPOSIT_RECEIPT, payload: '' })
    setFiles([])
    qrPayDispatch({ type: QRPAY_ACTION.SET_SELECTED_TRANSFER_TO_GAME_PROVIDER, payload: '' })
    qrPayDispatch({ type: QRPAY_ACTION.SET_PROMOTION_CODE, payload: '' })

  }

  const onClickImage = (image_url) => {
    setCurrentImage(image_url)
    setShowContactImgDialog(true)
  };

  // DEPOSIT (BANK TRANSFER) SUBMIT
  const handleSubmit = () => {
    if (isLoading || isProcessing) return;

    isProcessing = true;
    setLoading(true)
    const method = 'depositMainWalletByMember'


    let params = [
      // { code: 'member', graphqlType: 'String', required: true, value: appState.user?.username },
      // { code: 'transaction_type', graphqlType: 'String', required: true, value: 'DEPOSIT' },
      { code: 'deposit_method', graphqlType: 'String', required: false, value: 'QR_CODE' },
      { code: 'currency', graphqlType: 'String', required: true, value: appState.user?.currency ?? appState?.currency },
      { code: 'amount', graphqlType: 'Float', required: true, value: parseFloat(qrPayState.depositAmount) },
      { code: 'bank_account', graphqlType: 'String', required: false, value: qrPayState.selectedBankAccount?.code },
      { code: 'deposit_slip', graphqlType: 'String', required: false, value: qrPayState.selectedDepositReceipt },
      { code: 'force_withdraw', graphqlType: 'Boolean', required: true, value: false },
    ]

    if (qrPayState.selectedTransferToGameProvider !== 'MAIN_WALLET' && qrPayState.selectedTransferToGameProvider !== '') {
      params.push({ code: 'game_provider', graphqlType: 'String', required: false, value: qrPayState.selectedTransferToGameProvider })
    }


    // CHECK PROMOTION
    if (qrPayState.selectedPromotion?.id !== '' || qrPayState.promotionCode !== '') {
      if (qrPayState.promotionCode !== '') {
        const checkPromotionCode = qrPayState.promotionCode?.trim()
        let promoCodeExist = qrPayState.promotions.find((promotions) => promotions.code === checkPromotionCode)
        if (typeof promoCodeExist === 'undefined') {
          setLoading(false)
          isProcessing = false;
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: `Promotion Code[${checkPromotionCode}] Is NOT EXIST`, typeAlert: 'error' }
          });

          return
        }
        else {
          params.push({ code: 'promotion', graphqlType: 'String', required: false, value: promoCodeExist.id })
        }
      } else {
        params.push({ code: 'promotion', graphqlType: 'String', required: false, value: qrPayState.selectedPromotion.id })
      }

    }

    mutation([{
      method,
      params,
      attributes: []

    }])
      .then(({ data }) => {
        setLoading(false)
        isProcessing = false;


        if (appState?.pixelID !== '' && typeof appState?.pixelID !== 'undefined') {
          window.fbq('trackSingle', appState?.pixelID, 'Purchase', {
            currency: appState?.currency,
            value: parseFloat(qrPayState.depositAmount),
            content_ids: [`${data[method]?.id}`]
          });
        }

        let afterDepositqueries = [
          {
            index: 'member',
            method: 'member',
            params: [
              { code: 'username', graphqlType: 'String', required: true, value: appState.user?.username },
            ],
            attributes: ['wallet_amount']
          }]

        if (appState?.gameWallet?.length > 0) {
          afterDepositqueries.push({ index: 'getAllGamePrivoderBalanceByMember', method: 'getAllGamePrivoderBalanceByMember', params: [], attributes: [] })
        }


        queries(afterDepositqueries)
          .then(({ data }) => {
            appDispatch({ type: APP_STORE_ACTION.REFRESH_GET_GAME_WALLET, payload: data['getAllGamePrivoderBalanceByMember'] })
            appDispatch({ type: APP_STORE_ACTION.REFRESH_WALLET_AMOUNT, payload: data['member'].wallet_amount })
            setLoading(false)
            isProcessing = false;

            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: `Deposit Successful`, typeAlert: 'success', forwardUrl: '/wallet/history' }
            });


          }).catch((error) => {
            console.log('error: ', error)
            setLoading(false)
          isProcessing = false;

            if (error?.networkError?.statusCode === 401) {
              appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
            } else {
              appDispatch({
                type: APP_STORE_ACTION.SHOW_ALERT,
                payload: { description: error.message.toString(), typeAlert: 'error' }
              });
            }
          })

        clearInput()
      })
      .catch((error) => {
        setLoading(false)
        isProcessing = false;

        console.log('error: ', error)
        if (error?.networkError?.statusCode === 401) {
          appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })

        } else {
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: error.message.toString(), typeAlert: 'error' }
          });
        }
      })
  };



  useEffect(() => {
    setCheckDepositBank(false)
    setLoading(true)
    query(
      {
        method: 'getBankOption',
        params: [
          { code: 'currency', value: appState.currency ?? appState.user?.currency, graphqlType: 'String', required: true },
          { code: 'appearInWebsite', graphqlType: 'Boolean', required: false, value: true },
          { code: 'type', graphqlType: 'String', required: false, value: 'WALLET' },
        ],
        attributes: []
      },
    )
      .then(({ data }) => {
        // const filterqrPay = data['getBankOption']?.filter((bankAccount) =>
        //   bankAccount.appear_in_web === true
        // );
        // console.log('filterqrPay :',filterqrPay)
        qrPayDispatch({ type: QRPAY_ACTION.SET_BANK_ACCOUNTS, payload: data['getBankOption'] })
        setCheckDepositBank(true)
        setLoading(false)

      })
      .catch((error) => {
        console.error(error)
        setLoading(false)
        if (error?.networkError?.statusCode === 401) {
          appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })

        } else {
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: error.message.toString(), typeAlert: 'error' }
          });
        }
      })
  }, [query, appState.user?.currency, appState.currency, appDispatch, navigate])

  // GET ALL ACTIVE GAME PROVIDER
  useEffect(() => {
    setLoading(true)
    if (typeof appState.user?.username !== 'undefined') {


      let initialdepositqueries = [
        {
          index: 'getGameTypes',
          method: 'getGameTypes',
          params: [
            { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { status: 'ACTIVE', game_provider: `GS` }, order: [['updated_at', 'DESC']] } }
          ],
          attributes: [['type', 'code'], '_label']
        }, {
          index: 'getGameProviders',
          method: 'getGameProviders',
          params: [
            { code: 'filter', graphqlType: 'JSON', required: true, value: { where: { '$_game_provider_game_types.status$': 'ACTIVE', status: `ACTIVE` }, order: [['name', 'ASC']] } },
          ],
          attributes: ['code', 'name', ['_game_provider_game_types', ['type']]]
        }]

      if (appState?.gameWallet?.length > 0) {
        initialdepositqueries.push({ index: 'getAllGamePrivoderBalanceByMember', method: 'getAllGamePrivoderBalanceByMember', params: [], attributes: [] })
      }

      queries(initialdepositqueries)
        .then(({ data }) => {

          setLoading(false)

          const checkAllGameProviderBalance = data['getGameProviders']?.map(item => {
            const checkGameProviderBalance = data['getAllGamePrivoderBalanceByMember']
              ? data['getAllGamePrivoderBalanceByMember'].find(e => e.code === item.code)
              : null;
            return {
              ...item,
              balance: checkGameProviderBalance ? checkGameProviderBalance.balance : 0

            };
          });

          const groups = data['getGameTypes']
          const providersData = checkAllGameProviderBalance


          const sortedData = groups.map(type => {
            let getTypeName = GAME_MENU_DATA[type.code] ?? ''
            const options = providersData
              .filter(provider => provider._game_provider_game_types.some(gameType => gameType.type === type.code))
              .map(provider => ({ code: provider.code, name: provider.name, locked: provider.locked, balance: provider.balance }));
            return { code: type.code, label: getTypeName.name, options };
          });

          qrPayDispatch({ type: QUICK_PAY_ACTION.SET_GAME_PROVIDERS, payload: sortedData })

        }).catch((error, data) => {
          console.log('error: ', error)


          setLoading(false)

          if (error?.networkError?.statusCode === 401) {
            appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
          } else {
            appDispatch({
              type: APP_STORE_ACTION.SHOW_ALERT,
              payload: { description: error.message.toString(), typeAlert: 'error' }
            });
          }

        })
    }



  }, [queries, appState.user?.username, appDispatch, appState?.gameWallet]);

  // GET PROMOTION
  useEffect(() => {

    if (qrPayState.selectedTransferToGameProvider !== 'MAIN_WALLET') {
    let getPromoParam = [
      { code: 'member', graphqlType: 'String', required: false, value: appState.user?.username },
      { code: 'language', graphqlType: 'String', required: true, value: i18n.resolvedLanguage },
      { code: 'fromDeposit', graphqlType: 'Boolean', required: false, value: true },
    ]


    if (qrPayState.selectedTransferToGameProvider !== '') {
      getPromoParam.push({ code: 'gameProvider', graphqlType: 'String', required: false, value: qrPayState.selectedTransferToGameProvider })
    }

    queries([
      {
        index: 'getPromotions',
        method: 'getPromotions',
        params: getPromoParam,
        attributes: []
      },])
      .then(({ data }) => {
        qrPayDispatch({ type: QRPAY_ACTION.SET_PROMOTIONS, payload: data['getPromotions'] })
        // setLoading(false)

      }).catch((error) => {
        console.log('error: ', error)
        setLoading(false)
        if (error?.networkError?.statusCode === 401) {
          appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
        } else {
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: error.message.toString(), typeAlert: 'error' }
          });
        }
      })
    } else {
      qrPayDispatch({ type: QRPAY_ACTION.SET_PROMOTIONS, payload: [] })
      qrPayDispatch({ type: QRPAY_ACTION.SET_PROMOTION_CODE, payload: '' })
      qrPayDispatch({ type: QRPAY_ACTION.SET_SELECTED_PROMOTION, payload: { id: '', name: '' } })
  }

  }, [queries, qrPayState.selectedTransferToGameProvider, appState.user?.username, i18n.resolvedLanguage, appDispatch]);

  useEffect(() => {
    if (qrPayState.selectedbankAccount?.code !== '' && (qrPayState.depositAmount >= parseFloat(qrPayState.selectedBankAccount?.min_deposit > 0 ? qrPayState.selectedBankAccount?.min_deposit : (appState.currencyFormat?.min_deposit_amount ?? 0)) && qrPayState.depositAmount <= parseFloat(qrPayState.selectedBankAccount?.max_deposit > 0 ? qrPayState.selectedBankAccount?.max_deposit : (appState.currencyFormat?.max_deposit_amount ?? 0))) && qrPayState.selectedDepositReceipt !== '') {
      setDisabledSubmit(false)
    }
    else {
      setDisabledSubmit(true)
    }

  }, [qrPayState.selectedbankAccount?.code, qrPayState.depositAmount, qrPayState.selectedDepositReceipt, appState.currencyFormat?.min_deposit_amount, appState.currencyFormat?.max_deposit_amount, qrPayState.selectedBankAccount?.min_deposit, qrPayState.selectedBankAccount?.max_deposit])


  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <>
      <Form className="qrPay_form">
        <div className="d-none">
          selectedBankAccount min deposit{qrPayState.selectedBankAccount?.min_deposit}<br />
          currencyFormat min deposit{appState.currencyFormat?.min_deposit_amount}  <br />
          selectedBankAccount max deposit {qrPayState.selectedBankAccount?.max_deposit} <br />
          currencyFormat max deposit{appState.currencyFormat?.max_deposit_amount}  <br />
          <br />
          last get min deposit:{parseFloat(qrPayState.selectedBankAccount?.min_deposit > 0 ? qrPayState.selectedBankAccount?.min_deposit : (appState.currencyFormat?.min_deposit_amount ?? 0)).toLocaleString(appState.currencyFormat?.locale)}
          <br />
          last get max deposit:{parseFloat(qrPayState.selectedBankAccount?.max_deposit > 0 ? qrPayState.selectedBankAccount?.max_deposit : (appState.currencyFormat?.max_deposit_amount ?? 0)).toLocaleString(appState.currencyFormat?.locale)}
        </div>

        <Form.Group className="form_input_wrap">
          <div className="input_wrap">
            <Form.Label>{t('deposit_bank')}
              <span className="form_required_input">* {t('required_select')}</span>{qrPayState.selectedbankAccount?.code !== '' !== '' && (<AiOutlineCheckCircle className="ps-1" />)}
            </Form.Label>
            {qrPayState.bankAccounts?.length ? (<>
              <div className="depositBankImg">
                {qrPayState.bankAccounts?.map(function (item, index) {
                  return (
                    <div className={`depositBankImg_col ${qrPayState.selectedBankAccount?.code === item.code ? 'active' : ''}`} key={item.code} onClick={() => onSelectBankAccount(item.code)}>
                      <img src={`${GLOBAL_BUCKET}/bank/${appState.user?.currency ?? appState.currency}/${item.bank}.png`} alt={`bank icon ${item.bank}`} />
                      <span>{item._bank.name}</span>
                    </div>
                  )
                })}
              </div>
              <div className="deposit_qrPay_info_container">
                {qrPayState.selectedBankAccount?.qr_image_url && (<div className="displayQR_row">
                  <img src={`${qrPayState.selectedBankAccount?.qr_image_url}`} alt={`${qrPayState.selectedBankAccount?.bank}`} onClick={() => onClickImage(qrPayState.selectedBankAccount?.qr_image_url)} />
                  <label>*{t('required_scan')}</label>
                </div>)}
              </div>
            </>) : (checkDepositBank && (<img src={`${TENANT_BUCKET}/icon/under_maintenance_1_${i18n.resolvedLanguage}.png`} alt={`under maintenance`} className={`maintenance_img`} />))}

          </div>
        </Form.Group>
        <Form.Group className="form_input_wrap" >
          <div className="input_wrap">
            <Form.Label>{t('deposit_amount')}
              <span className="form_required_input">* {t('required_fill_in')}</span>{(qrPayState.depositAmount >= parseFloat(qrPayState.selectedBankAccount?.min_deposit > 0 ? qrPayState.selectedBankAccount?.min_deposit : (appState.currencyFormat?.min_deposit_amount ?? 0)) && qrPayState.depositAmount <= parseFloat(qrPayState.selectedBankAccount?.max_deposit > 0 ? qrPayState.selectedBankAccount?.max_deposit : (appState.currencyFormat?.max_deposit_amount ?? 0))) && (<AiOutlineCheckCircle className="ps-1" />)}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={checkDepositBank && (t('deposit_qrpay_amount_placeholder', { "min": parseFloat(qrPayState.selectedBankAccount?.min_deposit > 0 ? qrPayState.selectedBankAccount?.min_deposit : (appState.currencyFormat?.min_deposit_amount ?? 0)).toLocaleString(appState.currencyFormat?.locale), "max": parseFloat(qrPayState.selectedBankAccount?.max_deposit > 0 ? qrPayState.selectedBankAccount?.max_deposit : (appState.currencyFormat?.max_deposit_amount ?? 0)).toLocaleString(appState.currencyFormat?.locale) }))}
              className="input_depositAmount"
              aria-label="depositAmount"
              value={qrPayState.depositAmount === 0 ? '' : qrPayState.depositAmount}
              onChange={(evt) => { onChangeDepositAmount(evt.target.value) }}
              onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
              autoComplete="off"
              onInput={(e) => {
                let value = appState.currencyFormat.decimal_point === 0
                  ? e.target.value.replace(/[^0-9]/g, '')
                  : e.target.value.replace(/[^0-9.]/g, '');

                if (value.split('.').length - 1 > 1) {
                  value = value.substring(0, value.lastIndexOf('.'));
                }

                const decimal_part = value.split(".")[1];
                if (decimal_part && decimal_part.length > appState.currencyFormat.decimal_point) {
                  value = value.slice(0, -1);
                }
                e.target.value = value;
                // e.target.value = e.target.value.replace(/[^0-9]/g, '');
              }}
            />

            {checkDepositBank && (<div className="info_input_wrap">
              <FaInfoCircle /> <span>{t('deposit_qrpay_amount_placeholder', { "min": parseFloat(qrPayState.selectedBankAccount?.min_deposit > 0 ? qrPayState.selectedBankAccount?.min_deposit : (appState.currencyFormat?.min_deposit_amount ?? 0)).toLocaleString(appState.currencyFormat?.locale), "max": parseFloat(qrPayState.selectedBankAccount?.max_deposit > 0 ? qrPayState.selectedBankAccount?.max_deposit : (appState.currencyFormat?.max_deposit_amount ?? 0)).toLocaleString(appState.currencyFormat?.locale) })}</span>
            </div>)}
            <div type="radio" name="options" className="wallet_btnAmount" >
              {/* {defaultDepositAmountSelection.map(function (item, index) {
                return (
                  <button value={item} key={index} type="button" onClick={() => onChangeDepositAmount(item)} className={`${qrPayState.depositAmount === item ? 'active color_active' : ''}`}>{parseFloat(item).toLocaleString("en-US")}</button>
                )
              })} */}

              {appState.currencyFormat.default_deposit_amounts?.map(function (item, index) {
                return (
                  <button value={item.value} key={index} type="button" onClick={() => onChangeDepositAmount(item.value)} className={`${parseFloat(qrPayState.depositAmount) === item.value ? 'active color_active' : ''}`}>{item.text}</button>
                )
              })}
            </div>

          </div>
        </Form.Group>
        <Form.Group className="form_input_wrap"  >
          <div className="input_wrap">
            <Form.Label>{t('upload_receipt')}
              <span className="form_required_input">* {t('required_upload')}</span>{qrPayState.selectedDepositReceipt !== '' && (<AiOutlineCheckCircle className="ps-1" />)}
            </Form.Label>
            <section {...getRootProps({ className: 'uploadImageContainer' })}>
              <input {...getInputProps()} />
              <div className={`${files.length > 0 ? ('d-none') : 'beforeUploadImage'}`}>
                <AiOutlinePlus />
                <span>upload</span>
              </div>
              <div className="afterUploadImageContainer">
                <div className="afterUploadImage">
                  {files?.map(function (item, index) {
                    return (
                      <img
                        src={item.preview}
                        key={item.name}
                        // Revoke data uri after image is loaded
                        onLoad={() => { URL.revokeObjectURL(item.preview) }}
                        alt=""
                      />
                    )
                  })}
                </div>
              </div>
            </section>
          </div>
        </Form.Group>
        {/* TRANSFER */}
        <div className='deposit_transferContainer'>
          <Form.Group className="form_input_wrap" >
            <div className="input_wrap">
              <Form.Label>{t('transfer_to')}
                <span className="form_required_input">* {t('optional')}</span>
              </Form.Label>
              {/* <Form.Select aria-label="transferTo" className="input_transferTo" value={qrPayState.selectedTransferToGameProvider} onChange={(evt) => { onChangeTransferToGameProvider(evt.target.value) }}>
                <option hidden></option>
                {qrPayState.selectedTransferFromGameProvider !== 'MAIN_WALLET' && (<option value={'MAIN_WALLET'}>{t('main_wallet')}</option>)}
                {qrPayState.gameProviders.map((provider) => (
                  <optgroup key={provider.label} label={t(provider.label)}>
                    {provider?.options?.filter(item => item.code !== qrPayState.selectedTransferFromGameProvider).map((option) => (
                      <option key={option.code} value={option.code}>{option.name}</option>
                    ))}
                  </optgroup>
                ))}
              </Form.Select> */}
              <div className="selectProviderContainer" ref={toContainerRef}>
                <div className="form-select" onClick={() => { onClickShowSelectProvider() }}>
                  {qrPayState.selectedTransferToGameProvider === 'MAIN_WALLET' ? t('main_wallet') :
                    qrPayState.gameProviders.flatMap(item => item.options)
                      .find(option => option.code === qrPayState.selectedTransferToGameProvider)
                      ?.name || ''
                  }</div>
                {showToProviderSelection && (<ProviderSelector section={'DEPOSIT'} onSelect={onChangeTransferToGameProvider} data={qrPayState.gameProviders} currentProvider={''} otherProvider={''} />)}
              </div>
            </div>
          </Form.Group>
          {((qrPayState.selectedTransferToGameProvider !== "MAIN_WALLET") && !appState.disablePromotion) && (
            <>
              <Form.Group className="form_input_wrap " >
                <div className="input_wrap">
                  <Form.Label>{t('promotion')}
                    <span className="form_required_input">* {t('optional')}</span>
                  </Form.Label>
                  <Form.Select aria-label="transferPromotion" className="input_transferPromotion" value={qrPayState.selectedPromotion?.id} onChange={(evt) => { onChangePromotion(evt.target.value) }} disabled={qrPayState.promotionCode?.length > 0 ? true : false}>
                    {qrPayState.promotions?.filter(item => item.appear_in_website === true)?.length === 0 ? (<option hidden>No Promo Available</option>) : (<option value="">No Promotion</option>)}
                    {qrPayState.promotions?.filter(item => item.appear_in_website === true).map(function (item, index) {
                      return (
                        <option value={item.id} key={item.id}>{item.title ?? item.name} {item.code && `(${item.code})`}</option>
                      )
                    })}
                  </Form.Select>
                </div>
              </Form.Group>

              <Form.Group className="form_input_wrap " >
                <div className="input_wrap">
                  <Form.Label>{t('promotion_code')}
                    <span className="form_required_input">* {t('optional')}</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="input_promotionCode"
                    value={qrPayState.promotionCode ?? ''}
                    onChange={(evt) => { onChangePromotionCode(evt.target.value) }}
                    aria-label="promotionCode"
                    disabled={qrPayState.selectedPromotion?.id !== '' ? true : false}
                  />
                </div>
              </Form.Group></>)}
        </div>
        <input className="font_button color_button wallet_btnSubmit" type='button' disabled={disabledSubmit} value={t('submit')} onClick={handleSubmit} />
      </Form>
      {/* <Alert show={alertShow.show} onHide={onHideAlert} message={alertShow.message} type={alertShow.type} title={alertShow.title} /> */}
      {isLoading && (<Loading />)}
      {<Modal show={showContactImgDialog}
        onHide={() => setShowContactImgDialog(false)}
        id="contactImgDialog"
        centered >
        <Modal.Body closeButton>
          <img src={currentImage} alt={'QR_IMAGE'} />
        </Modal.Body>
      </Modal>
      }
    </>
  )
}


const DepositReminder = (props) => {
  const { depositMethod } = props
  const { t } = useTranslation();

  return (
    <>
      {t(`deposit_${depositMethod}_reminder`) !== "" ? <div className="wallet_reminder_container">
        <div className="wallet_reminder_title">{t('reminder')}</div>
        <div className="wallet_reminder_content">
          {t(`deposit_${depositMethod}_reminder`)}
        </div>
      </div> : <></>}

    </>


  )
}

const Deposit = (props) => {
  // const [selected, setSelected] = useState(0);
  const { queries } = useMiddletier()
  const appDispatch = useStoreDispatch()
  const navigate = useNavigate()
  const appState = useStoreState();
  // let depositMenu = ["quickpay", "qrPay"]
  // const [depositMenu, setDepositMenu] = useState([]);
  // const [selectedDepositMenu, setSelectedDepositMenu] = useState();
  const [depositState, depositDispatch] = useReducer(depositReducer, initialDepositData)
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);

  // const selectedClick = (id) => {
  //   setSelected(id);
  //   depositDispatch({ type: QUICK_PAY_ACTION.SET_DEPOSIT_MENU, payload: depositMenu })
  // }


  const onClickDepositMenu = (item) => {
    depositDispatch({ type: DEPOSIT_ACTION.SET_SELECTED_DEPOSIT_MENU, payload: item })
  }


  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })


  useEffect(() => {
    setLoading(true)
    let depositMenu = []

    queries([
      {
        method: 'bank_types',
        params: [
          { code: 'filter', value: { where: { code: "TELCO", status: "ACTIVE" } }, graphqlType: 'JSON', required: true }
        ],
        attributes: ['code', 'name', 'status']
      }, {
        index: 'getPaymentProviders',
        method: 'getPaymentProviders',
        params: [
          { code: 'type', value: 'DEPOSIT', graphqlType: 'String', required: true }
        ],
        attributes: []
      }, {
        index: 'getBankOption',
        method: 'getBankOption',
        params: [
          { code: 'currency', value: appState.user?.currency ?? appState.currency, graphqlType: 'String', required: true },
          { code: 'appearInWebsite', graphqlType: 'Boolean', required: false, value: true },
          { code: 'type', graphqlType: 'String', required: false, value: 'WALLET' },
        ],
        attributes: []
      },]
    )
      .then(({ data }) => {
        if (data['getPaymentProviders'].length !== 0) {
          depositMenu.push('quick_pay')
        }
        // setDepositMenu((currentMenu) => {
        //   return currentMenu.includes('banktransfer') ? currentMenu : [...currentMenu, 'banktransfer'];
        // });
        depositMenu.push('bank_transfer')

        if (data['bank_types'].length !== 0) {
          depositMenu.push('reload')
        }

        if (data['getBankOption'].length !== 0) {
          depositMenu.push('qrpay')
        }

        const sortedDepositMenu = appState?.deposit_type_sequence 
        ? depositMenu.sort((a, b) => {
            const valueA = appState?.deposit_type_sequence[a] ? parseInt(appState?.deposit_type_sequence[a], 10) : Infinity;
            const valueB = appState?.deposit_type_sequence[b] ? parseInt(appState?.deposit_type_sequence[b], 10) : Infinity;
      
            if (valueA === valueB) {
                return depositMenu.indexOf(a) - depositMenu.indexOf(b);
            }
      
            return valueA - valueB;
          })
        : depositMenu; 

        const formattedDepositMenu = sortedDepositMenu.map(item => item.replace(/_/g, ""));

        depositDispatch({ type: DEPOSIT_ACTION.SET_DEPOSIT_MENU, payload: formattedDepositMenu })
        depositDispatch({ type: DEPOSIT_ACTION.SET_SELECTED_DEPOSIT_MENU, payload: formattedDepositMenu[0] })
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.error(error)
        if (error?.networkError?.statusCode === 401) {
          appDispatch({ type: APP_STORE_ACTION.UNAUTHENTICATED })
        } else {
          appDispatch({
            type: APP_STORE_ACTION.SHOW_ALERT,
            payload: { description: error.message.toString(), typeAlert: 'error' }
          });
        }
      })

    // depositMenu.splice(1, 0, 'banktransfer');

    // depositDispatch({ type: DEPOSIT_ACTION.SET_DEPOSIT_MENU, payload: depositMenu })

  }, [queries, appDispatch, navigate, appState.user?.currency, appState.currency,appState?.deposit_type_sequence])

  return (
    <>
      <div className="deposit_body">
        <>
          {isDesktopOrLaptop && (<div className="font_h2 color_primary wallet_container_title">{t('deposit')}</div>)}
          <div className="wallet_container_wrap">
            <div id="wallet_depositMethod_tab">
              {depositState.depositMenu.length > 0 && (<Row>
                <Col lg={7} >
                  <Nav variant="pills" className="wallet_depositMethod" >
                    {depositState.depositMenu.map(function (item, index) {
                      let _item = DEPOSIT_MENU_DATA[item] ?? ''
                      return (
                        <Nav.Item key={index} className="font_h5 depositMethod_navItem">
                          <Nav.Link eventKey={_item?.name} onClick={() => onClickDepositMenu(item)} className="wallet_depositMethod_menu ">
                            <img src={item === depositState.selectedDepositMenu ? _item?.selectedIcon : _item?.icon} alt={'deposit method icon'} />
                            <div className={`${item === depositState.selectedDepositMenu ? 'color_active active' : ''}`}>{(t(_item?.name))}</div>
                          </Nav.Link>
                        </Nav.Item>)
                    })}
                  </Nav>


                  <div>

                    {/* {depositMenu.map(function (item, index) {
                      item = DEPOSIT_MENU_DATA[item] ?? ''
                      return (
                        <Tab.Pane key={index} eventKey={item.name} >
                          {item?.name === "quickpay" ? <Quickpay /> : (item?.name === "banktransfer" ? <Banktransfer /> : (item?.name === "qrpay" ? <Qrpay /> : <Reload />))}
                        </Tab.Pane>
                      )
                    })} */}
                    {depositState.selectedDepositMenu === "quickpay" ? <Quickpay /> : (depositState.selectedDepositMenu === "banktransfer" ? <Banktransfer /> : (depositState.selectedDepositMenu === "reload" ? <Reload /> : <Qrpay />))}
                  </div>
                </Col>
                <Col lg={5} >
                  <DepositReminder depositMethod={depositState.selectedDepositMenu} />
                </Col>
              </Row>)}


            </div>
          </div>
               {isLoading && (<Loading />)}
        </>

        {/* 
        {!isDesktopOrLaptop && <>

          <Tabs
            defaultActiveKey="quickpay"
            id="wallet_depositMethod_tab_m"
            className="mb-3 wallet_depositMethod_tab_m tab_color" fill
          >
            {depositMenu.map(function (item, index) {
              item = DEPOSIT_MENU_DATA[item]
              return (
                <Tab key={index} eventKey={item.name} title={(t(item.name))} tabClassName={`wallet_depositMethod_tabSelection`}>
                  <Container><div className="wallet_container_wrap">{item.name === "quickpay" ? <Quickpay /> : (item.name === "banktransfer" ? <Banktransfer /> : (item.name === "qrpay" ? <Qrpay /> : <Reload />))}
                    <DepositReminder depositMethod={item.name} /></div>
                  </Container>
                </Tab>)
            })}
          </Tabs>
        </>} */}
        {/* <Alert show={alertShow.show} onHide={onHideAlert} message={alertShow.message} type={alertShow.type} title={alertShow.title} /> */}
        {/* {isLoading && (<Loading />)} */}
      </div>
    </>

  );
};



export default Deposit;
